import React from "react";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";

function ThankyouPage(props) {
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="wrapper">
        <section className="thankyou-wrapper">
          <div className="container">
            <h1 className="h1-heading">Thank You For Your Feedback!</h1>
            <div className="image-block">
              <img src="/thankyou-thumb.svg" alt="thank-you-thumb" />
            </div>
            <button
              onClick={() => history.push("/front/home")}
              className="btn"
              style={{
                backgroundColor: "#955275",
                color: "#fff",
                border: "1px solid #955275",
              }}
            >
              Back To Home <span>Page</span>
            </button>
          </div>
        </section>
      </div>
      <Footer />
    </React.Fragment>
  );
}
export default ThankyouPage;
