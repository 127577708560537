import React, { useEffect, useState } from "react";
import { postData } from "../components/FetchServices";
import "./privacyStyles.css";
function PrivacyPolicyPage() {
  const [ppText, setPPText] = useState("");
  const [ppUrl, setPPUrl] = useState("");
  const getPrivacyPolicy = async () => {
    const body = {
      companyId: "628b9741496e0a093197a756",
      api_version: "v2",
      source: "web",
    };
    const result = await postData("front/privacyPolicy", body);

    if (result) {
      if (result.privacy_text) {
        setPPText(result.privacy_text);
      }
      if (result.privacy_link) {
        console.log(result.privacy_link);
        setPPUrl(result.privacy_link);
      }
    }
  };
  useEffect(() => {
    getPrivacyPolicy();
  }, []);
  return (
    <div style={{ height: "100vh" }}>
      {ppText ? (
        <div style={{ height: "100vh" }}>
          <header className="ppHeader">
            <h2 className="ppTitle">Privacy Policy</h2>
          </header>
          <div
            dangerouslySetInnerHTML={{ __html: ppText }}
            style={{
              height: "100%",
              width: "80%",
              margin: "auto",
              paddingTop: "24px",
            }}
          />
        </div>
      ) : ppUrl ? (
        <iframe title="policy" width="100%" height="100%" src={ppUrl} allowFullScreen />
      ) : (
        <iframe
        title="privacy"
          width="100%"
          height="100%"
          src="https://www.servefirst.co.uk/privacy-policy/"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
}

export default PrivacyPolicyPage;
