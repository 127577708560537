import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./helpers/authHelper";
import Audition from "./pages/Audition";
import ClientConnect from "./pages/ClientConnect";
import ClientConnectDirector from "./pages/ClientConnectDirector";
import Location from "./pages/Location";
import Login from "./pages/Login";
import Question from "./pages/Questions";
import Welcome from "./pages/Welcome";
// import LocationArea from "./pages/LocationArea";

function Survey(props) {
  const { match } = props;

  return (
    <Switch>
      <Route path={`${match.url}/login`} component={Login} />
      {/* locationarea selection refers to tag selection  */}
      {/* <ProtectedRoute
        path={`${match.url}/locationArea`}
        component={LocationArea}
      /> */}
      <ProtectedRoute path={`${match.url}/location`} component={Location} />
      {/* audition selection refers to survey selection */}
      <ProtectedRoute path={`${match.url}/audition`} component={Audition} />
      <Route path={`${match.url}/client_connect`} component={ClientConnect} />
      <Route
        path={`${match.url}/client_connect_director`}
        component={ClientConnectDirector}
      />
      <Route path={`${match.url}/welcome`} component={Welcome} />
      <Route path={`${match.url}/questions`} component={Question} />
      <Redirect path={`${match.url}/`} to={`${match.url}/location`} />
    </Switch>
  );
}

export default Survey;
