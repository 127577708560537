import {
  Box,
  Grid,
  Tooltip,
  Typography,
  useTheme,
  Button,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { ThemeUpdateContext } from "../../../App";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Background from "../../common/components/Background";
import PaperContainer from "../../common/components/PaperContainer";
import { commonPageMakeStyles } from "../../common/styles/commonPageStyles";
import { useStyles } from "./styles";
import {
  clearSurvey,
  setGoogleRatingsDetails,
} from "../../../redux/survey/actions";
import ReactHtmlParser from "react-html-parser";
import { isCompanyLogin } from "../../helpers/Utils";
import { ArrowBack } from "@material-ui/icons";
import { postData } from "../../helpers/fetch_services";

function Audition(props) {
  const classes = useStyles(props);
  const history = useHistory();
  const commonPageCss = commonPageMakeStyles();
  const { changeTheme } = useContext(ThemeUpdateContext);
  const theme = useTheme();
  const { locations, setGoogleRatingsDetails_, clearSurveyWithoutLogin } =
    props;
  // Local state declaration
  const [surveys, setSurveys] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [logo, setLogo] = useState("/images/webRating/serve-first.png");

  const getSurveys = async () => {
    const locationId = localStorage.getItem("location");
    const surveyId = localStorage.getItem("survey");
    if (surveyId && !isCompanyLogin()) {
      history.push("/survey/welcome");
    } else {
      if (locationId) {
        const result = await postData(
          "frontEmployeeFeedback/getSurveyByLocation",
          {
            location_id: locationId,
          }
        );
        const survey = result?.data || [];
        if (result?.success) {
          setSurveys(survey);
          if (survey?.length === 1) {
            localStorage.setItem("survey", survey?.[0]._id);
            setGoogleRatingsDetails_({
              locationGoogleReviewUrl: survey?.locationGoogleReviewUrl,
              locationGoogleQrImage: survey?.locationGoogleQrImage,
            });
            history.push("/survey/welcome");
          }
        } else {
          toast("Please Select Location!");
          history.push("/survey/location");
        }
      } else {
        toast("Please Select Location!");
        history.push("/survey/location");
      }
    }

    setLoading(false);
  };
  // Funtion that redirect user to the welcome page
  const onClickSurvey = (survey) => {
    localStorage.setItem("survey", survey._id);
    history.push("/survey/welcome");
  };

  // use Effect Hooks for getting data from server and setting states
  useEffect(() => {
    clearSurveyWithoutLogin();
    const logoLocal = localStorage.getItem("logo");
    if (logoLocal) setLogo(logoLocal);
    getSurveys();
    changeTheme({
      primaryColor: props.survey_theme.primaryColor,
      secondaryColor: props.survey_theme.secondaryColor,
      backgroundColor: props.survey_theme.backgroundColor,
      highlightColor: props.survey_theme.highlightColor,
      fontColor: props.survey_theme.fontColor,
    });
  }, [locations.length]); // calls when locations array changes its length

  const onBackToLocationList = () => {
    localStorage.removeItem("survey");
    history.push("/survey/location");
  };

  return (
    <>
      <LoadingOverlay active={loading} spinner>
        <Background>
          <div className="mt-3"></div>
          <PaperContainer logo={logo}>
            <Box style={{ paddingLeft: 5, paddingTop: 5 }}>
              <Typography variant="h4" className={commonPageCss.pageTitle}>
                Select Audit
              </Typography>
            </Box>
            <Grid container spacing={2} style={{ padding: 5 }}>
              {surveys.length
                ? surveys.map((survey) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      onClick={() => onClickSurvey(survey)}
                      key={Math.random()}
                    >
                      <Tooltip
                        title={
                          survey?.description
                            ? ReactHtmlParser(survey?.description)
                            : ""
                        }
                      >
                        <Box className={classes.card}>
                          <Box className={classes.cardFlex}>
                            <CheckCircleIcon
                              color={theme.palette.secondary.main}
                              style={{ fontSize: "40px" }}
                            />

                            <Typography className={classes.cardText}>
                              {survey.name}
                            </Typography>
                          </Box>
                          <ArrowForwardIcon
                            style={{
                              fontSize: "40px",
                            }}
                          />
                        </Box>
                      </Tooltip>
                    </Grid>
                  ))
                : null}
            </Grid>
            <div
              className="ml-1"
              style={{ bottom: "40px", position: "absolute" }}
            >
              {isCompanyLogin() ? (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  startIcon={<ArrowBack />}
                  onClick={() => onBackToLocationList()}
                  className="mr-2"
                >
                  Back
                </Button>
              ) : null}
            </div>
          </PaperContainer>
        </Background>
      </LoadingOverlay>
    </>
  );
}
// Function that extracts the necessary state from the Redux store
const mapStateToProps = (state) => {
  return {
    locations: state.survey.locations,
    survey_theme: state.survey.survey_theme,
  };
};
// Function that handle the dispatch of actions
const mapActionsToProps = (dispatch) => {
  return {
    setGoogleRatingsDetails_: (data) => dispatch(setGoogleRatingsDetails(data)),
    clearSurveyWithoutLogin: () => dispatch(clearSurvey()),
  };
};
// Connecting the Component to Redux store
export default connect(mapStateToProps, mapActionsToProps)(Audition);
