import { Box, Grid, RadioGroup } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addAnswer, clearAnsweres } from "../../../../redux/survey/actions";
import CheckBoxCard from "../../../common/components/CheckBoxCard";
import RadioCard from "../../../common/components/RadioCard";
import TextArea from "../TextArea";

// Component for handling multiple selection questions
const MultipleSelect = (props) => {
  // Destructure props for easier access
  const {
    question,
    answeres,
    addAnswer_,
    pageNo,
    surveyType,
    allQuestionsOriginal,
    setAllQuestions,
    setTotalPage,
    isGreyOut,
    clearAnsweres_,
    headTonext,
  } = props;
  const [checked, setChecked] = useState([]);
  const [minError, setMinError] = useState(false);
  const [maxError, setMaxError] = useState(false);
  const [hideIndex, setHideIndex] = useState([]);

  // Function to handle user preference selection
  const handleuserPref = (option, e) => {
    let value = typeof id === "string" ? option?._id : e?.target?.value;
    if (surveyType === "tablet" && pageNo === 1) {
      clearAnsweres_();
    }

    let updatedOptions = [...checked];

    if (option?.exclusiveSelection && e?.target?.checked) {
      // If exclusiveSelection is true, uncheck other checkboxes
      updatedOptions = [value];
    } else {
      if (updatedOptions?.includes(value)) {
        // If the checkbox is already checked, uncheck it
        if (
          !question.min_options ||
          updatedOptions.length > parseInt(question.min_options, 10)
        ) {
          updatedOptions.splice(updatedOptions.indexOf(value), 1);
        } else {
          setMinError(true);
          return;
        }
      } else {
        if (
          !question.max_options ||
          updatedOptions.length < parseInt(question.max_options, 10)
        ) {
          // If exclusiveSelection checkbox is checked, uncheck it
          const exclusiveIndex = updatedOptions.findIndex(
            (id) =>
              question?.options.find((opt) => opt._id === id)
                ?.exclusiveSelection
          );
          if (exclusiveIndex !== -1) {
            updatedOptions.splice(exclusiveIndex, 1);
          }
          updatedOptions.push(value);
        } else {
          setMaxError(true);
          return;
        }
      }
    }
    setChecked(updatedOptions);
    const hidequestions = question?.options
      ?.filter((opt) => updatedOptions?.includes(opt?._id))
      ?.flatMap((opt) => opt?.hide_questions || []);

    setHideIndex(hidequestions);
  };

  // Effect to reset error states when number of checked options changes
  useEffect(() => {
    if (checked.length >= question.min_options) {
      setMinError(false);
    }
    if (checked.length <= question.max_options) {
      setMaxError(false);
    }
  }, [checked]);

  // Function to load data from Redux store when component mounts
  const loadData = () => {
    if (question._id in answeres) {
      let prevAnswer = answeres[question._id] ?? { value: [] };
      setChecked(prevAnswer.value);
    }
  };

  useEffect(() => {
    if (question._id in answeres) {
      let prevAnswer = answeres[question._id] ?? { value: [] };
      setChecked(prevAnswer.value);
    } else {
      setChecked([]);
    }
  }, [question._id in answeres]);

  useEffect(() => {
    loadData();
  }, []);

  const setData = () => {
    if (surveyType === "tablet" && pageNo === 1) {
      let value = 0;
      question.options.forEach((element) => {
        if (checked.includes(element._id)) {
          value += Number(element.value);
        }
      });
      // Calculate percentage based on value and maximum score of the question
      const percentage = (Number(value) / Number(question?.maxScore)) * 100;
      // Filter questions based on percentage and journey type
      if (percentage >= 70) {
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "positive"
          );

        setTotalPage(filteredQuestion.length + 1);

        setAllQuestions([question, ...filteredQuestion]);
      } else {
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "negative"
          );

        setTotalPage(filteredQuestion.length + 1);

        setAllQuestions([question, ...filteredQuestion]);
      }
    }
    let isScoreExclude = question.options
      .filter((item) => checked.includes(item._id))
      .filter((item) => item.score_exclusion_selection);

    addAnswer_({
      id: question._id,
      value: checked,
      type: "id",
      score_exclude: isScoreExclude?.length ? true : false,
    });

    headTonext(undefined, hideIndex, checked);
  };
  useEffect(() => {
    setData();
  }, [checked]);

  // Filter options with icons and options allowing write-in
  let withIcon = question.options.filter((item) => item.iconClass);
  let allowWritein = question.options
    .filter((item) => checked.includes(item._id))
    .filter((item) => item.write_in);
  return (
    <Box style={{ cursor: isGreyOut && "not-allowed" }}>
      {withIcon.length ? (
        <Grid container>
          {question?.options.map((item) => (
            <Grid item xs={6} sm={3} md={4} lg={3} key={item._id}>
              <CheckBoxCard
                checked={checked}
                item={item}
                onClick={(e) => !isGreyOut && handleuserPref(item, e)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <RadioGroup
          className="radioInline"
          style={{ flexDirection: "column" }}
          row
          aria-label="position"
          name="position"
        >
          {question.options.map((option) => (
            <RadioCard
              key={option?._id}
              isGreyOut={isGreyOut}
              answer={checked}
              option={option}
              handleChange={(e) => !isGreyOut && handleuserPref(option, e)}
              multi
              type="multiselect"
            />
          ))}
        </RadioGroup>
      )}
      {allowWritein.length ? (
        <Box>
          <TextArea question={question} type="write_in" />
        </Box>
      ) : null}
      {minError && (
        <p style={{}}>minimum selection is {question.min_options}</p>
      )}
      {maxError && (
        <p style={{}}>maximum selection is {question.max_options}</p>
      )}
    </Box>
  );
};

// Map Redux state to component props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Map Redux dispatch functions to component props
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
  };
};
// Connect component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(MultipleSelect);
