import React from "react";
import { Grid } from "@material-ui/core";

//Load Images
import eliorLogo from '../assets/images/client-survey/logo-elior.jpg';
import tyLogo from '../assets/images/client-survey/ty.png';
import clLogo from '../assets/images/client-survey/cl.png';
import lexirLogo from '../assets/images/client-survey/lexir.png';
import enbrLogo from '../assets/images/client-survey/enbr.png';

export default function Header(props) {
  return (
    <React.Fragment>
      {/* header */}
      <div style={{ flexDirection: "column", display: "flex" }}>
        <div
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          align="center"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} sm={12}>
              <img
                style={{ height: "3rem", width: "auto" }}
                src={eliorLogo}
                alt="header_company_1"
              />
              <img
                style={{ height: "3rem", width: "auto" }}
                src={tyLogo}
                alt="header_company_2"
              />
              <img
                style={{ height: "3rem", width: "auto", paddingLeft: "5px" }}
                src={clLogo}
                alt="header_company_3"
              />
              <img
                style={{ height: "3rem", width: "auto", paddingLeft: "5px" }}
                src={lexirLogo}
                alt="header_company_4"
              />
              <img
                style={{ height: "3rem", width: "auto", paddingLeft: "5px" }}
                src={enbrLogo}
                alt="header_company_5"
              />
            </Grid>
          </Grid>
        </div>
      </div>
      {/* header */}
    </React.Fragment>
  );
}
