import { combineReducers } from "redux";
import {frontWebRatingDataReducer} from "./frontWebRating/reducer"
import { survey } from "./survey/reducer";

const reducers = combineReducers({
  survey,
  frontWebRatingDataReducer
});

export { reducers };
