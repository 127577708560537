import React from "react";

const RatingOptions = ({ options, onClick, answer, error }) => {
  return (
    <div className="d-flex flex-wrap flex-lg-no-wrap align-items-center justify-content-center">
      {options?.length
        ? options.map((option) => (
            <div key={option._id} className="mr-2 mb-2">
              <button
                onClick={() => onClick(option._id, option.finish_survey)}
                className={`option-button rating-button ${
                  answer?.value === option._id ? "selected" : ""
                }`}
              >
                {option.text}
              </button>
            </div>
          ))
        : null}

      {error ? (
        <div className="mt-2 small font-weight-bold text-danger">
          {error.message}
        </div>
      ) : null}
    </div>
  );
};

export default RatingOptions;
