export async function checkFileType(url) {
  try {
    const response = await fetch(url);
    const contentType = response.headers.get("content-type");

    if (contentType && contentType.includes("image")) {
      return "image";
    } else if (contentType && contentType.includes("video")) {
      return "video";
    } else {
      // If content type is neither image nor video, you can handle it accordingly
      console.log("Unsupported content type:", contentType);
      return "unsupported";
    }
  } catch (error) {
    console.error("Error checking file type:", error);
    return "error";
  }
}

export const isCompanyLogin = () => {
  const companyLogin = localStorage.getItem("user_id");
  return companyLogin ? true : false;
};
