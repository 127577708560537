import React from "react";
// import * as reportStyles from "../Reportstyle.module.css";
import reportStyles from "../Reportstyle.module.css";

const ADMIN_URL = process.env.REACT_APP_URL;

function ResponseBreakdown({ data }) {
  const { countDistribution } = data;
  let totalDist = countDistribution?.reduce((acc, curr) => {
    return acc + curr;
  }, 0);
  let detractors = countDistribution.slice(0, 3).reduce((acc, curr) => {
    return acc + curr;
  }, 0);

  let detractorsPercent = (detractors / totalDist) * 100;
  let promoter = countDistribution.slice(-1).length
    ? countDistribution.slice(-1)[0]
    : 0;
  let promoterPercent = (promoter / totalDist) * 100;

  return (
    <div class={reportStyles.divbackground4} style={{ overflow: "unset" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <h3 class={reportStyles.heading1}>Responses Breakdown</h3>
        </div>
        <div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "60%" }}>
              <p class={reportStyles.para3}>
                {detractorsPercent.toFixed(1).replace(/[.,]00$/, "")} %
              </p>
              <button class={reportStyles.button1} style={{ width: "100%" }}>
                Detractors
              </button>
            </div>
            <div style={{ width: "20%", marginLeft: "auto" }}>
              <p class={reportStyles.para3}>
                {promoterPercent.toFixed(1).replace(/[.,]00$/, "")} %
              </p>
              <button class={reportStyles.button2}>Promoter</button>
            </div>
          </div>
          <div class={reportStyles.ratingEmojis}>
            <div class={reportStyles.emojiContainer}>
              <a
                href={`${ADMIN_URL}/app/ratings/list?rating=1`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={"./images/report/rating-1.png"}
                  class={reportStyles.emoji}
                  alt="rating-1"
                />
              </a>
              <div>{countDistribution[0]}</div>
              <div>
                {((Number(countDistribution[0]) / totalDist) * 100).toFixed(1) +
                  "%"}
              </div>
            </div>

            <div class={reportStyles.emojiContainer}>
              <a
                href={`${ADMIN_URL}/app/ratings/list?rating=2`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={"./images/report/rating-2.png"}
                  class={reportStyles.emoji}
                  alt="rating-2"
                />
              </a>
              <div class={reportStyles.emojiRating}>{countDistribution[1]}</div>
              <div>
                {((Number(countDistribution[1]) / totalDist) * 100).toFixed(1) +
                  "%"}
              </div>
            </div>

            <div class={reportStyles.emojiContainer}>
              <a
                href={`${ADMIN_URL}/app/ratings/list?rating=3`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={"./images/report/rating-3.png"}
                  class={reportStyles.emoji}
                  alt="rating-3"
                />
              </a>
              <div class={reportStyles.emojiRating}>{countDistribution[2]}</div>
              <div>
                {((Number(countDistribution[2]) / totalDist) * 100).toFixed(1) +
                  "%"}
              </div>
            </div>

            <div class={reportStyles.emojiContainer}>
              <a
                href={`${ADMIN_URL}/app/ratings/list?rating=4`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={"./images/report/rating-4.png"}
                  class={reportStyles.emoji}
                  alt="rating-4"
                />
              </a>
              <div class={reportStyles.emojiRating}>{countDistribution[3]}</div>
              <div>
                {((Number(countDistribution[3]) / totalDist) * 100).toFixed(1) +
                  "%"}
              </div>
            </div>

            <div class={reportStyles.emojiContainer}>
              <a
                href={`${ADMIN_URL}/app/ratings/list?rating=5`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={"./images/report/rating-5.png"}
                  class={reportStyles.emoji}
                  alt="rating-5"
                />
              </a>
              <div class={reportStyles.emojiRating}>{countDistribution[4]}</div>
              <div>
                {((Number(countDistribution[4]) / totalDist) * 100).toFixed(1) +
                  "%"}
              </div>
            </div>
          </div>
          <div className={reportStyles.bottomPercentsRow}>
            {/* <button class={reportStyles.button3}>
              Net Promoter Score ={" "}
             
              {detractorsPercent.toFixed(1).replace(/[.,]00$/, "")}% -{" "}

              
              {promoterPercent.toFixed(1).replace(/[.,]00$/, "")}% =
              <span class={reportStyles.span1}>
                {(detractorsPercent - promoterPercent)
                  .toFixed(1)
                  .replace(/[.,]00$/, "")}{" "}
                %
              </span>
            </button> */}
            <div className={reportStyles.onePercent}>
              <div className={reportStyles.circlePercent}>
                {promoterPercent.toFixed(1).replace(/[.,]00$/, "")}%
              </div>

              <div>Promoters</div>
            </div>
            <div className={reportStyles.signs}>-</div>
            <div className={reportStyles.onePercent}>
              <div className={reportStyles.dangerCirclePercent}>
                {detractorsPercent.toFixed(1).replace(/[.,]00$/, "")}%
              </div>

              <div>Detractors</div>
            </div>
            <div className={reportStyles.signs}>=</div>
            <div className={reportStyles.onePercent}>
              <div
                style={{
                  borderColor:
                    (promoterPercent - detractorsPercent).toFixed(1) < 0
                      ? "red"
                      : "green",
                }}
                className={reportStyles.dangerCirclePercent}
              >
                {(promoterPercent - detractorsPercent).toFixed(1)}%
              </div>
              <div>NPS</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResponseBreakdown;
