import React from "react";
import GoogleLogo from "../assets/images/google-logo.png";
import { useStyles } from "./style";

function MobileRatingThankYou() {
  const backgroundClasses = useStyles();
  const showGoogle = localStorage.getItem("showGoogle") === "true";
  const googleLink = localStorage.getItem("googleLink") || "";

  return (
    <div style={{ minHeight: "100%" }}>
      <section className={`login-wrapper ${backgroundClasses.backgroundImg}`}>
        <div className={backgroundClasses.container}>
          <img src="/images/webRating/check.png" style={{ width: "20vw" }} alt="checkicon" />
          <h2 className={backgroundClasses.thankyouText} style={{ marginTop: "1rem" }}>
            Thank you for your time and your answers.
          </h2>

          {showGoogle ? (
            <>
              <h2 className={backgroundClasses.thankyouText} style={{ marginTop: "4rem" }}>
                Would also like to rate us on Google?
              </h2>

              <div className={backgroundClasses.googleReviewContainer}>
                <a href={googleLink} target="_blank" rel="noreferrer" className={backgroundClasses.googleReviewButton}>
                  <img src={GoogleLogo} alt="google-logo" width="30px" style={{ marginRight: "5px" }} />
                  Rate us now
                </a>
              </div>
            </>
          ) : null}
        </div>
        <img src="/images/webRating/powerbywhite.png" alt="powerby" style={{ position: "absolute", bottom: 9 }} />
      </section>
    </div>
  );
}

export default MobileRatingThankYou;
