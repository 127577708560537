import { useMediaQuery, useTheme } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { default as RadioInput } from "@material-ui/core/Radio";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import React from "react";

function OptionLabel({ label, image }) {
  return (
    <div
      style={
        image
          ? {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }
          : {}
      }
    >
      {image ? (
        <img
          className="mb-1"
          src={image}
          alt="question"
          width="100px"
          height="100px"
          style={{ objectFit: "contain", borderRadius: "6px" }}
        />
      ) : null}
      <div>{label}</div>
    </div>
  );
}

function RadioCard(props) {
  const theme = useTheme();
  const isMob = useMediaQuery(theme.breakpoints.down("sm"));

  const { answer, option, handleChange, multi, type = null, isGreyOut } = props;
  const isActive = (id) => {
    if (typeof answer === "string") {
      return answer === id;
    } else {
      return answer.includes(id);
    }
  };
  return (
    <FormControlLabel
      checked={isActive(option._id)}
      value={option._id}
      control={
        <RadioInput
          // style={{
          //   display:
          //     type && (type === "multiselect" || type === "sequence")
          //       ? ""
          //       : "none",
          // }}
          onClick={multi ? handleChange : null}
          onChange={!multi ? handleChange : null}
          disableRipple
          icon={
            type === "radio" ? (
              <RadioButtonUncheckedIcon
                htmlColor="dimgrey"
                fontSize={isMob ? "small" : "medium"}
              />
            ) : (
              <CheckBoxOutlineBlankIcon
                htmlColor="dimgrey"
                fontSize={isMob ? "small" : "medium"}
              />
            )
          }
          checkedIcon={
            type === "radio" ? (
              <RadioButtonCheckedIcon
                htmlColor="dimgrey"
                fontSize={isMob ? "small" : "medium"}
              />
            ) : (
              <>
                <CheckBoxIcon
                  htmlColor="dimgrey"
                  fontSize={isMob ? "small" : "medium"}
                />
                {type === "sequence" && isActive(option._id) ? (
                  <div className="seqSelectionIndex">
                    ({answer.indexOf(option._id) + 1})
                  </div>
                ) : null}
              </>
            )
          }
        />
      }
      label={
        <OptionLabel
          label={type === "skills" ? option.name : option.text}
          image={option.imageUrl || null}
        />
      }
      className={`${!isGreyOut && "radioItem"} ${
        isActive(option._id) ? "boldOnSelect" : ""
      }`}
      style={{
        // justifyContent: type && type === "radio" ? "center" : "",
        padding: !isMob ? "1px 0" : "",
        border: !isActive(option._id) ? "" : "1px solid #404040",
        borderRadius: 5,
        color: "#404040",
        background: isActive(option._id) ? "#e8e8e8" : "",
      }}
    />
  );
}

export default RadioCard;
