import React from "react";
import { Paper, useTheme } from "@material-ui/core";
import { commonPageMakeStyles } from "../styles/commonPageStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function PaperContainer(props) {
  const commonPageCss = commonPageMakeStyles();
  const theme = useTheme();
  const isMob = useMediaQuery(theme.breakpoints.down("sm"));

  const customStyle = () => {
    if (props.forQ) {
      return {
        padding: 40,
        borderRadius: isMob ? 0 : 20,
        width: isMob ? "100vw" : "100%",
        minHeight: isMob && "100vh",
        display: "grid",
        // gridTemplateRows: "15% auto",
      };
    } else {
      return;
    }
  };

  return (
    <Paper
      style={{ ...customStyle(), position: "relative", paddingBottom: "100px" }}
      className={!props.forQ && commonPageCss.paperStyle}
      elevation={5}
    >
      {/* <div className={commonPageCss.logoContainer}>
        <img src={props.logo} className={commonPageCss.logo} alt="logo" />
      </div> */}
      {props.children}
    </Paper>
  );
}

export default PaperContainer;
