import React from 'react'
export default function FeedbackOnceAlert(props){
    return (
        <section className="thankyou-wrapper">
        <div className="container">
            <h1>Feedback is once given from this url.</h1>
            
        </div>
        </section>
      
    );
}