import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
// import * as ContactUsStyles from "./ContactUsModalStyle.module.css";
import ContactUsStyles from "./ContactUsModalStyle.module.css";

import "./style.css";
const ContactUsModal = ({ isVisible, closeModal, openThankYouModal }) => {
  return (
    <>
      <Modal
        center
        open={isVisible}
        classNames={{
          overlay: "react-responsive-modal-overlay-a",
          modal: "react-responsive-modal-modal-a",
        }}
        showCloseIcon={false}
        onClose={closeModal}
      >
        <div>
          <div class={ContactUsStyles.container}>
            <img src="images/webRating/black.png" alt="logo" />
          </div>
          <div class={ContactUsStyles.container}>
            <p>Do you want to learn more about Serve First?</p>
          </div>

          <div class={ContactUsStyles.formContainer}>
            <div class={ContactUsStyles.containerWidth}>
              <form
                method="POST"
                className="max-w-3xl mx-auto mt-12"
                autoComplete="off"
              >
                <div>
                  <div>
                    {/* <label className="form-input-label">Full Name</label> */}
                    <div className="mt-4">
                      <input
                        type="text"
                        id="contact_name"
                        name="contact_name"
                        placeholder="Full Name"
                        //   value={contact_name}
                        //   onChange={(e) => set_contact_name(e.target.value)}
                        className="form-control"
                        style={{ height: "50px" }}
                      />
                    </div>
                  </div>
                  <div>
                    {/* <label className="form-input-label">Email</label> */}
                    <div className="mt-4">
                      <input
                        type="text"
                        id="contact_email"
                        name="contact_email"
                        placeholder="Email"
                        className="form-control "
                        //   value={contact_email}
                        //   onChange={(e) => set_contact_email(e.target.value)}
                        autoComplete="off"
                        style={{ height: "50px" }}
                      />
                      {/* <span style={{ color: "red" }}>
                      {emailError !== "" ? emailError : ""}
                    </span> */}
                    </div>
                  </div>
                  <div className="col-span-2">
                    {/* <label className="form-input-label">Phone Number</label> */}
                    <div className="mt-4">
                      <input
                        type="text"
                        id="contact_phone"
                        name="contact_phone"
                        placeholder="Phone Nmuber"
                        className="form-control"
                        //   value={contact_phone}
                        //   onKeyPress={(event) => {
                        //     if (!/[0-9]/.test(event.key)) {
                        //       event.preventDefault();
                        //     }
                        //   }}
                        //   onChange={(e) => set_contact_phone(e.target.value)}
                        autoComplete="off"
                        style={{ height: "50px" }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class={ContactUsStyles.containerWidth}>
              <p class={ContactUsStyles.reachUs}>...or you may reach us on.</p>
              <div class={ContactUsStyles.contactContainer}>
                <div class={ContactUsStyles.imgContainer}>
                  <img
                    style={{ height: "27px", width: "auto" }}
                    src="images/webRating/locationIcon.png"
                    alt="locationicon"
                  />
                </div>

                <p class={ContactUsStyles.address}>
                  167-169 Great Portland Street, 5th Floor, London, England, W1W
                  5PF
                </p>
              </div>
              <div class={ContactUsStyles.websiteImg}>
                <div style={{ height: "27px", width: "27px" }}>
                  <img src="images/webRating/globe.png" alt="globe" />
                </div>

                <p class={ContactUsStyles.address}>www.servefirst.co.uk</p>
              </div>
              <div class={ContactUsStyles.websiteImg}>
                <div style={{ height: "27px", width: "27px" }}>
                  <img
                    src="images/webRating/messageIcon.png"
                    alt="messageicon"
                  />
                </div>

                <p class={ContactUsStyles.address}>info@servefirst.co.uk</p>
              </div>
            </div>
          </div>
          <div class={ContactUsStyles.btnContainer}>
            <button class={ContactUsStyles.cancelBtn} onClick={closeModal}>
              Cancel
            </button>
            <button
              class={ContactUsStyles.contactUsBtn}
              onClick={() => {
                closeModal();
                openThankYouModal();
              }}
            >
              Contact Us
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ContactUsModal;
