import { postData } from "../components/FetchServices"

export const fetchParams = async (params) => {
  const result = await postData('weeklyReport/getReportParams', params); 
  if (result.success) {
    return { ...result.data, location_id: JSON.stringify(result.data.location_id) };
  }
  return null;
};

export const fetchReportDetails = async (params, updateReportData) => {
  const result = await postData('weeklyReport/getReportDetails', params); 
  if (result.success) {
    updateReportData({ key: 'logo', value: result.data.logo });  
    updateReportData({ key: 'color', value: result.data.color });  
    updateReportData({ key: 'startDate', value: result.data.startDate });  
    updateReportData({ key: 'endDate', value: result.data.endDate });  
    updateReportData({ key: 'dateDiff', value: result.data.dateDiff });  
    updateReportData({ key: 'userName', value: result.data.userName });  
  }
};

export const fetchNegativeSkills = async (params, updateReportData) => {
  const result = await postData('weeklyReport/getNegativeSkills', params);
  if (result.success) {
    updateReportData({ key: 'topNegativeSkill', value: result.data });  
  }
};

export const fetchPrimaryStats = async (params, updateReportData) => {
  const result = await postData('weeklyReport/getPrimaryStats', params);
  if (result.success) {
    updateReportData({ key: 'npsScore', value: result.data.npsScore });  
    updateReportData({ key: 'totalResponses', value: result.data.totalResponses });  
    updateReportData({ key: 'responseAverage', value: result.data.responseAverage });  
  }
};

export const fetchRatingChart = async (params, updateReportData) => {
  const result = await postData('weeklyReport/getRatingChart', params);
  if (result.success) {
    updateReportData({ key: 'ratingGraph', value: result.data });
    const totalAverage = result.data.average.reduce((acc, avg) => acc + Number(avg), 0);
    const averageCount = result.data.average.length;
    if (averageCount > 0) {
      const ytd = Math.round((totalAverage / averageCount) * 10) / 10;
      updateReportData({ key: 'ytd', value: ytd });
    }
  }
};

export const fetchLatestReviews = async (params, updateReportData) => {
  const result = await postData('weeklyReport/getLatestReviews', params);
  if (result.success) {
    updateReportData({ key: 'latestReviews', value: result.data });
  }
};

export const fetchNpsChart = async (params, updateReportData) => {
  const result = await postData('weeklyReport/getNpsChart', params);
  if (result.success) {
    updateReportData({ key: 'npsGraph', value: result.data });
  }
};

export const fetchRatingDistribution = async (params, updateReportData) => {
  const result = await postData('weeklyReport/getRatingDistribution', params);
  if (result.success) {
    updateReportData({ key: 'ratingDistribution', value: result.data.ratings[0] });
  }
};

export const fetchLocationData = async (params, updateReportData) => {
  const result = await postData('weeklyReport/getLocationData', params);
  if (result.success) {
    updateReportData({ key: 'allLocations', value: result.data.allLocations });
    updateReportData({ key: 'locationVSallLocation', value: result.data.locationVSallLocation });
    updateReportData({ key: 'locationCategoryData', value: result.data.locationCategoryData });
  }
};

export const fetchAttributeData = async (params, updateReportData) => {
  const result = await postData('weeklyReport/getAttributeData', params);
  if (result.success) {
    updateReportData({ key: 'attributeData', value: result.data });
  }
};

export const fetchActionPlanData = async (params, updateReportData) => {
  const result = await postData('weeklyReport/getActionPLanData', params);
  if (result.success) {
    updateReportData({ key: 'actionPlan', value: result.data });
  }
};

export const fetchAiSuggestions = async (params, updateReportData) => {
  const reqParams = JSON.stringify(Object.assign({}, { ...params, data: null}));
  const aiResponse = localStorage.getItem('aiResponse');
  const aiParams = localStorage.getItem('aiParams');

  if (aiResponse && aiParams && aiParams === reqParams) {
    updateReportData({ key: 'aiSuggestions', value: aiResponse });
  } else {
    const result = await postData('weeklyReport/getAiSuggestions', params);
    if (result.success) {
      updateReportData({ key: 'aiSuggestions', value: result.data });
      localStorage.setItem('aiResponse', result.data);
      localStorage.setItem('aiParams', reqParams);
    }
  }
};

