import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { connect } from "react-redux";
import Button from "../../../../common/components/Button";
import Progress from "../../../../common/components/Progress";

const ClientFooter = ({
  currentStep,
  onClickNext,
  onClickPrev,
  survey,
  totalSurveySteps,
  maxSteps,
}) => {
  const isComplete =
    survey?.survey_without_login?.clientConnect?.userAuditComplete === true;

  return (
    <div className="page-footer w-100">
      <div className="footer-content mx-auto d-flex justify-content-between align-items-center">
        <div style={{ flex: "0 0 auto" }}>
          <Button
            leftIcon={<ArrowBackIcon />}
            onClick={onClickPrev}
            isDisable={currentStep === 1}
          />
        </div>

        <div style={{ flex: "1 1 auto", padding: "0 20px" }}>
          {/* <Progress answered={currentStep} total={2 + totalSurveySteps} /> */}
          <Progress answered={currentStep} total={isComplete ? 2 : maxSteps} />
        </div>

        <div style={{ flex: "0 0 auto" }}>
          <Button
            // title={currentStep === 1 ? "Review content" : "Next"}
            title={
              currentStep === 1
                ? "Review content"
                : currentStep === maxSteps
                ? "Submit"
                : "Next"
            }
            onClick={onClickNext}
            isDisable={isComplete && currentStep > 1}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
  };
};

export default connect(mapStateToProps)(ClientFooter);
