import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    // minHeight: "100vh",
  },
  headingBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px 20px",
  },
  heading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "35px",
    display: "flex",
    textAlign: "center",
  },
  subHeading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    textAlign: "center",
    marginTop: "2rem",
    color: "gray",
  },
  root: {
    flexGrow: 1,
    padding: "10px 100px",
  },
  mainheading: {
    fontFamily: "Inter",
    fontSize: "50px",
    fontWeight: 700,
    display: "flex",
    textAlign: "center",
    lineHeight: "60.51px",
    paddingBottom: "10px",
  },
  para: {
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 400,
    display: "flex",
    lineHeight: "31.31px",
    color: "#B9B8B8",
    textAlign: "center",
    paddingBottom: "39px",
  },
  tc: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    display: "flex",
    lineHeight: "16.94px",
    color: "#000000",
    textAlign: "center",
    justifyContent: "flex-start",
  },
  textfield: {
    display: "flex",
    justifyContent: "center",
    padding: "7px 0px 7px 0px",
  },
  textfieldColor: {
    color: "#000",
  },
  qrContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  googleReviewButton: {
    userSelect: "none",
    fontSize: "1.12rem",
    fontWeight: 500,
    textDecoration: "none",
    border: "1px solid gainsboro",
    borderRadius: "6px",
    padding: ".25rem 1rem .25rem .5rem",
    cursor: "pointer",
    color: "black",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "whitesmoke",
      color: "black",
    },
    "&:focus": {
      backgroundColor: "whitesmoke",
      color: "black",
    },
    "&:active": {
      backgroundColor: "gainsboro",
      color: "black",
    },
  },
  buttonBox: {
    backgroundColor: localStorage.getItem("app_color"),
    border: "1px solid white",
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "3rem",
    borderRadius: "6px",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
      justifyContent: "center",
    },
  },
  buttonArrow: {
    color: "white",
    margin: "0px 5px",
    fontSize: "23px",
  },
  buttonText: {
    margin: "0px 8px",
    color: "white",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "20px",
  },
}));
