import * as React from "react";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import UserAvatar from "../../../assets/images/web_rating/user.png";
import { DatePicker } from "rsuite";

export default function ClientConnectDirector({
  value,
  onChange,
  leadershipUser,
  clientConnect,
  isVisitInformation,
  selectedTimeOut = null,
  setSelectedTimeOut,
}) {
  return (
    <Paper elevation={3} className="mt-2">
      <Card sx={{ maxWidth: 345 }}>
        {leadershipUser ? (
          <>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                textAlign: "center",
              }}
              className="mt-2"
            >
              Message from Leadership
            </Typography>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="recipe"
                  src={leadershipUser?.image}
                  variant="circular"
                  style={{ width: 50, height: 50 }}
                  onError={(e) => {
                    e.target.src = UserAvatar;
                  }}
                />
              }
              title={leadershipUser?.name}
              subheader={
                <>
                  <Typography variant="body2" color="textPrimary">
                    {leadershipUser?.companyName}
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    {leadershipUser?.email}
                  </Typography>
                </>
              }
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Hi,{clientConnect?.username}
              </Typography>
              {leadershipUser?.message ? (
                ReactHtmlParser(leadershipUser.message)
              ) : (
                <>
                  <Typography variant="body2">
                    I am constantly seeking ways to better our offerings for our
                    clientele. Are there aspects we could better in your
                    opinion?
                  </Typography>
                  <Typography variant="body2">
                    On another note, if there's a specific topic you'd like our
                    team to address, don't hesitate to share. I'm not just here
                    to listen, but to engage and act upon your concerns or
                    ideas.
                  </Typography>
                </>
              )}
            </CardContent>
            <CardActions>
              <TextField
                minRows={2}
                multiline
                value={value}
                onChange={onChange}
                size="medium"
                placeholder="Your message here"
                variant="outlined"
              />
            </CardActions>
          </>
        ) : null}
        {isVisitInformation ? (
          <>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                textAlign: "center",
              }}
              className="mt-2"
            >
              Visit Information
            </Typography>
            <CardContent>
              <label>Visit Time Out *:</label>
              <DatePicker
                format="HH:mm"
                ranges={[]}
                value={selectedTimeOut}
                onChange={(time) => setSelectedTimeOut(time)}
                className="form-control p-0"
                block
                style={{
                  color: "darkgray",
                  fontSize: "14px",
                }}
                placement={
                  leadershipUser && isVisitInformation
                    ? "topStart"
                    : "bottomStart"
                }
              />
            </CardContent>
          </>
        ) : null}
      </Card>
    </Paper>
  );
}
