import React, { useState } from "react";
import { useTheme } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useIdleTimer } from "react-idle-timer";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

//load images
import user_image from "../assets/images/mobile_emoji/lady.jpg";
import avatar_image from "../assets/images/mobile_emoji/avatar.png";
import { connect } from "react-redux";
import {
  setFrontratingData,
  setFrontratingAnsweres,
  resetFrontratingAnsweres,
} from "../redux/frontWebRating/actions";
import { saveDetails } from "../webRatingNew/apiHelpers/saveDetails";
import { useHistory } from "react-router-dom";

function NegativeChatReply(props) {
  const skillData = props.skillData ? props.skillData : [];
  const [open, setOpen] = React.useState(false);
  const [cookies, setCookie] = useCookies(["rating"]);

  const { webRatingData, webRatingAnsweres, setFrontratingAnsweres_ } = props;

  const theme = useTheme();

  const [ringColor, setRingColor] = React.useState(false);

  let rating = localStorage.getItem("front-mobile-chatbox-rating");
  const [isShowSelectSkillFooterPortion, setisShowSelectSkillFooterPortion] =
    React.useState(true);
  const [isShowSelectORSkillAfterBlock, setisSelectORSkillAfterBlock] =
    React.useState(false);

  console.log("isShowSelectSkillFooterPortion", isShowSelectSkillFooterPortion);
  console.log("isShowSelectORSkillAfterBlock", isShowSelectORSkillAfterBlock);
  const getDropoutPage = () => {
    if (isShowSelectSkillFooterPortion) {
      return { page: 1, dropout_page: "n1" };
    } else if (isShowSelectORSkillAfterBlock) {
      return { page: 2, dropout_page: "n2" };
    } else {
      return { page: "", dropout_page: "" };
    }
  };
  const getSelectedSkillData = () => {
    let tempSelectedSkill = Array.from(
      document.querySelectorAll(
        "input[type=checkbox][name=selectedSkills]:checked"
      ),
      (e) => e.value
    );
    let selectedSkillIDs = [];
    let selectedSkillILabels = [];
    if (tempSelectedSkill.length > 0) {
      tempSelectedSkill.forEach((str, k) => {
        let tempData = str.split("_");
        selectedSkillIDs.push(tempData[0]);
        selectedSkillILabels.push(tempData[1]);
      });

      setOpen(false);
      setisShowSelectSkillFooterPortion(false);
      setisSelectORSkillAfterBlock(true);
      localStorage.setItem(
        "selectedNegativeSKillValues",
        selectedSkillIDs.join(",")
      );
      setFrontratingAnsweres_({
        key: "skill_id",
        value: selectedSkillIDs,
      });
      localStorage.setItem(
        "selectedNegativeSKillLabel",
        selectedSkillILabels.join(",")
      );
    } else {
      skipSelectSkillStep();
    }
  };

  const skipSelectSkillStep = () => {
    setisShowSelectSkillFooterPortion(false);
    setisSelectORSkillAfterBlock(true);

    localStorage.setItem("selectedNegativeSKillValues", "");
    localStorage.setItem("selectedNegativeSKillLabel", "");
  };

  const [isShowCanWeGenInTouchBtn, setisShowCanWeGenInTouchBtn] = useState(
    !webRatingData.hideContact
  );
  const [
    isShowCanWeGenInTouchAfterYesBlock,
    setisShowCanWeGenInTouchAfterYesBlock,
  ] = useState("");
  const [
    isShowCanWeGenInTouchAfterNoBlock,
    setisShowCanWeGenInTouchAfterNoBlock,
  ] = useState("");

  const [
    isShowNegativeFormNameInputFooter,
    setisShowNegativeFormNameInputFooter,
  ] = React.useState(true);
  const [
    isShowNegativeFormEmailInputFooter,
    setisShowNegativeFormEmailInputFooter,
  ] = React.useState(false);
  const [
    isShowNegativeFormPhoneInputFooter,
    setisShowNegativeFormPhoneInputFooter,
  ] = React.useState(false);
  const [
    isShowNegativeFormFeedbackInputFooter,
    setisShowNegativeFormFeedbackInputFooter,
  ] = React.useState(webRatingData.hideContact);

  React.useEffect(() => {
    document.documentElement.style.setProperty(
      "--my_color",
      theme.palette.primary.main
    );
    props.updateList();
  }, [
    isShowSelectSkillFooterPortion,
    isShowSelectORSkillAfterBlock,
    isShowCanWeGenInTouchBtn,
    isShowCanWeGenInTouchAfterYesBlock,
    isShowCanWeGenInTouchAfterNoBlock,
    isShowNegativeFormNameInputFooter,
    isShowNegativeFormEmailInputFooter,
    isShowNegativeFormPhoneInputFooter,
    isShowNegativeFormFeedbackInputFooter,
  ]);

  const SelectSkillModal = () => {
    return (
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="mobile-view-modal"
          id="mobile-view-modal"
        >
          <DialogTitle id="mobile-view-modal-title">
            <div className="modal-header">
              <h5 className="mr-12 text-lg font-bold truncate">Select Skill</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpen(false)}
                aria-label="Close"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </DialogTitle>

          <DialogContent id="mobile-view-modal-body">
            <div className="divide-y divide-gray-200">
              {skillData.map((row, k) => {
                return (
                  <div
                    key={k}
                    className="flex items-center justify-between px-3 py-3 bg-white"
                  >
                    <label
                      htmlFor={`skill-${k}`}
                      className="flex-1 block text-base font-medium"
                    >
                      {row.name}
                    </label>
                    <input
                      type="checkbox"
                      id={`skill-${k}`}
                      name="selectedSkills"
                      className="w-5 h-5 text-purple-600 border-gray-300 rounded-full focus:ring-purple-600"
                      onFocus={setRingColor(true)}
                      style={{
                        color: ringColor ? theme.palette.primary.main : "",
                      }}
                      defaultValue={row._id + "_" + row.name}
                    />
                  </div>
                );
              })}
            </div>
          </DialogContent>
          <DialogActions id="mobile-view-modal-footer">
            <div className="flex items-center justify-center space-x-4">
              <button
                style={{
                  paddingTop: "0.75rem",
                  paddingBottom: "0.75rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                type="button"
                onClick={() => setOpen(false)}
                className="flex items-center justify-center flex-1 w-full  text-base font-semibold text-gray-600 transition-all duration-200 bg-gray-300 rounded-full shadow-lg hover:text-white focus:text-white hover:bg-gray-600 focus:bg-gray-600"
              >
                Discard
              </button>
              <button
                style={{
                  backgroundColor: theme.palette.primary.main,
                  paddingTop: "0.75rem",
                  paddingBottom: "0.75rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                type="button"
                onClick={(e) => getSelectedSkillData(e)}
                className="flex items-center justify-center flex-1 w-full text-base font-semibold text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
              >
                Save
              </button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const SelectSkillORSkipAfterBlock = (props) => {
    const temp = localStorage.getItem("selectedNegativeSKillLabel");
    const selectedSkills = temp !== "" ? temp.split(",") : [];

    return (
      <div>
        <div className="flex flex-row-reverse items-end justify-start pl-20 transition-all duration-200 m-t-30">
          <div className="text-black bg-white rounded-br-none shadow rounded-2xl">
            <div className="flex flex-wrap items-center gap-3 px-4 py-3">
              {/*in loop  */}
              {selectedSkills.length > 0 ? (
                selectedSkills.map((label, k) => {
                  return (
                    <span
                      key={k}
                      className="text-center inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800"
                    >
                      {label}
                    </span>
                  );
                })
              ) : (
                <span className="text-center inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                  None Selected
                </span>
              )}
              {/*in loop  */}
            </div>
          </div>
        </div>
        {!webRatingData.hideContact ? (
          <div className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30">
            <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
              <img
                className="object-cover w-full h-full"
                src={user_image}
                alt="user_avatar"
              />
            </div>
            <div
              className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
              style={{ backgroundColor: theme.palette.primary.main }}
            >
              <div
                style={{
                  paddingTop: " 0.75rem",
                  paddingBottom: " 0.75rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
              >
                <span className="block leading-tight">
                  Can we get in touch with you about your experience today?
                </span>
              </div>
            </div>
          </div>
        ) : null}
        {isShowCanWeGenInTouchBtn === true ? (
          <div className="ajax-div-question-touch-negative">
            <div className="fixed bottom-0 z-10 w-full max-w-lg mx-auto -translate-x-1/2 left-1/2">
              <div className="px-4 bg-white border-t border-gray-200">
                <div className="flex items-center space-x-3 h-18">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
                    style={{ color: theme.palette.primary.main }}
                    onClick={() => SubmitPage2TouchNegativeType("Yes")}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
                    style={{ color: theme.palette.primary.main }}
                    onClick={() => SubmitPage2TouchNegativeType("No")}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center flex-shrink-0 px-5 py-3 text-sm font-medium leading-4 text-gray-700 transition-all duration-200 bg-white border border-gray-300 rounded-full shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
                    onClick={() => SubmitPage2TouchNegativeType("No")}
                  >
                    Skip
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {isShowCanWeGenInTouchAfterYesBlock === "Yes" ? (
          <div>
            <div className="flex flex-row-reverse items-end justify-start pl-20 transition-all duration-200 m-t-30">
              <div className="text-black bg-white rounded-br-none shadow rounded-2xl">
                <div className="flex flex-wrap items-center gap-3 px-4 py-3">
                  <span className="text-center inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                    Yes
                  </span>
                </div>
              </div>
            </div>
            <div
              className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30"
              style={{ marginBottom: "30px" }}
            >
              <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
                <img
                  className="object-cover w-full h-full"
                  src={user_image}
                  alt="true"
                />
              </div>
              <div
                className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
                style={{ backgroundColor: theme.palette.primary.main }}
              >
                <div
                  style={{
                    paddingTop: " 0.75rem",
                    paddingBottom: " 0.75rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                >
                  <span className="block leading-tight">
                    Please can we get your name
                  </span>
                </div>
              </div>
            </div>
            {isShowNegativeFormNameInputFooter === true ? (
              <div className="ajax-div-question-four-negative">
                <div className="fixed z-10 w-full max-w-lg px-4 mx-auto -translate-x-1/2 left-1/2 bottom-5">
                  <form
                    method="POST"
                    className="flex items-center justify-center space-x-3"
                  >
                    <input
                      type="text"
                      value={negativeName}
                      onChange={(e) => setnegativeName(e.target.value)}
                      id="negative_name"
                      placeholder="Write here..."
                      className="block w-full px-4 py-4 text-base text-black transition-all duration-200 bg-white border border-transparent rounded-full shadow-lg caret-purple-600 focus:border-purple-600 focus:ring-purple-600 focus:outline-none"
                    />
                    <button
                      type="submit"
                      className="flex items-end justify-center flex-shrink-0 p-4 text-white transition-all duration-200  rounded-full shadow-lg hover:bg-black focus:bg-black"
                      style={{ backgroundColor: theme.palette.primary.main }}
                      onClick={(e) => SubmitNegativeName(e)}
                    >
                      <svg
                        className="w-6 h-6 rotate-90"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                      </svg>
                    </button>
                  </form>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex flex-row-reverse items-end justify-start pl-20 transition-all duration-200 m-t-30">
                  <div className="text-black bg-white rounded-br-none shadow rounded-2xl">
                    <div className="flex items-center justify-end px-4 py-3">
                      <span className="ml-3 font-medium">{negativeName}</span>
                    </div>
                  </div>
                </div>
                <div
                  className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
                    <img
                      className="object-cover w-full h-full"
                      src={user_image}
                      alt="true"
                    />
                  </div>
                  <div
                    className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
                    style={{ backgroundColor: theme.palette.primary.main }}
                  >
                    <div
                      style={{
                        paddingTop: " 0.75rem",
                        paddingBottom: " 0.75rem",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                      }}
                    >
                      <span className="block leading-tight">
                        Please enter you email
                      </span>
                    </div>
                  </div>
                </div>
                {isShowNegativeFormEmailInputFooter === true ? (
                  <div className="ajax-div-question-five-negative">
                    <div className="fixed z-10 w-full max-w-lg px-4 mx-auto -translate-x-1/2 left-1/2 bottom-5">
                      <form
                        method="POST"
                        className="flex items-center justify-center space-x-3"
                      >
                        <input
                          type="email"
                          value={negativeEmail}
                          onChange={(e) => setnegativeEmail(e.target.value)}
                          id="negative_email"
                          placeholder="Write here..."
                          className="block w-full px-4 py-4 text-base text-black transition-all duration-200 bg-white border border-transparent rounded-full shadow-lg caret-purple-600 focus:border-purple-600 focus:ring-purple-600 focus:outline-none"
                        />
                        <button
                          onClick={(e) => submitNegativeEmail(e)}
                          type="submit"
                          className="flex items-end justify-center flex-shrink-0 p-4 text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
                          style={{
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          <svg
                            className="w-6 h-6 rotate-90"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                          </svg>
                        </button>
                      </form>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="flex flex-row-reverse items-end justify-start pl-20 transition-all duration-200 m-t-30">
                      <div className="text-black bg-white rounded-br-none shadow rounded-2xl">
                        <div className="flex items-center justify-end px-4 py-3">
                          <span className="ml-3 font-medium">
                            {negativeEmail}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30"
                      style={{ marginBottom: "30px" }}
                    >
                      <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
                        <img
                          className="object-cover w-full h-full"
                          src={user_image}
                          alt="true"
                        />
                      </div>
                      <div
                        className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
                        style={{ backgroundColor: theme.palette.primary.main }}
                      >
                        <div
                          style={{
                            paddingTop: " 0.75rem",
                            paddingBottom: " 0.75rem",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <span className="block leading-tight">
                            Enter Your Phone Number
                          </span>
                        </div>
                      </div>
                    </div>
                    {isShowNegativeFormPhoneInputFooter === true ? (
                      <div className="ajax-div-question-six-negative">
                        <div className="fixed z-10 w-full max-w-lg px-4 mx-auto -translate-x-1/2 left-1/2 bottom-5">
                          <form
                            method="POST"
                            className="flex items-center justify-center space-x-3"
                          >
                            <input
                              type="text"
                              value={negativePhone}
                              onChange={(e) => setnegativePhone(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              id="negative_phone"
                              placeholder="Write here..."
                              className="block w-full px-4 py-4 text-base text-black transition-all duration-200 bg-white border border-transparent rounded-full shadow-lg caret-purple-600 focus:border-purple-600 focus:ring-purple-600 focus:outline-none"
                            />
                            <button
                              onClick={(e) => submitPhoneNumber(e)}
                              type="submit"
                              className="flex items-end justify-center flex-shrink-0 p-4 text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
                              style={{
                                backgroundColor: theme.palette.primary.main,
                              }}
                            >
                              <svg
                                className="w-6 h-6 rotate-90"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                              </svg>
                            </button>
                          </form>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="flex flex-row-reverse items-end justify-start pl-20 transition-all duration-200 m-t-30">
                          <div className="text-black bg-white rounded-br-none shadow rounded-2xl">
                            <div className="flex items-center justify-end px-4 py-3">
                              <span className="ml-3 font-medium">
                                {negativePhone}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30"
                          style={{ marginBottom: "30px" }}
                        >
                          <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
                            <img
                              className="object-cover w-full h-full"
                              src={user_image}
                              alt="true"
                            />
                          </div>
                          <div
                            className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
                            style={{
                              backgroundColor: theme.palette.primary.main,
                            }}
                          >
                            <div
                              style={{
                                paddingTop: " 0.75rem",
                                paddingBottom: " 0.75rem",
                                paddingLeft: "1rem",
                                paddingRight: "1rem",
                              }}
                            >
                              <span className="block leading-tight">
                                Please tell us a bit more about your experience
                              </span>
                            </div>
                          </div>
                        </div>
                        {isShowNegativeFormFeedbackInputFooter === true ? (
                          <div className="ajax-div-question-seven-negative">
                            <div className="fixed z-10 w-full max-w-lg px-4 mx-auto -translate-x-1/2 left-1/2 bottom-5">
                              <form
                                method="POST"
                                className="flex items-center justify-center space-x-3"
                              >
                                <input
                                  type="text"
                                  value={negativeFeedback}
                                  onChange={(e) => {
                                    setnegativeFeedback(e.target.value);
                                    setFrontratingAnsweres_({
                                      key: "feedback",
                                      value: e.target.value,
                                    });
                                  }}
                                  id="negative_feedback"
                                  placeholder="Write here..."
                                  className="block w-full px-4 py-4 text-base text-black transition-all duration-200 bg-white border border-transparent rounded-full shadow-lg caret-purple-600 focus:border-purple-600 focus:ring-purple-600 focus:outline-none"
                                />
                                <button
                                  type="submit"
                                  className="flex items-end justify-center flex-shrink-0 p-4 text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
                                  style={{
                                    backgroundColor: theme.palette.primary.main,
                                  }}
                                  onClick={(e) => submitFeedback(e)}
                                >
                                  <svg
                                    className="w-6 h-6 rotate-90"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                                  </svg>
                                </button>
                              </form>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="flex flex-row-reverse items-end justify-start pl-20 transition-all duration-200 m-t-30">
                              <div className="text-black bg-white rounded-br-none shadow rounded-2xl">
                                <div className="flex items-center justify-end px-4 py-3">
                                  <span className="ml-3 font-medium">
                                    {negativeFeedback}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30">
                              <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
                                <img
                                  className="object-cover w-full h-full"
                                  src={user_image}
                                  alt="true"
                                />
                              </div>
                              <div
                                className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
                                style={{
                                  backgroundColor: theme.palette.primary.main,
                                }}
                              >
                                <div
                                  style={{
                                    paddingTop: " 0.75rem",
                                    paddingBottom: " 0.75rem",
                                    paddingLeft: "1rem",
                                    paddingRight: "1rem",
                                  }}
                                >
                                  <span className="block leading-tight">
                                    Thank you
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        {isShowCanWeGenInTouchAfterNoBlock === "No" ? (
          <div>
            <div className="flex flex-row-reverse items-end justify-start pl-20 transition-all duration-200 m-t-30">
              <div className="text-black bg-white rounded-br-none shadow rounded-2xl">
                <div className="flex flex-wrap items-center gap-3 px-4 py-3">
                  <span className="text-center inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                    No
                  </span>
                </div>
              </div>
            </div>
            <div
              className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30"
              style={{ marginBottom: "30px" }}
            >
              <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
                <img
                  className="object-cover w-full h-full"
                  src={user_image}
                  alt="true"
                />
              </div>
              <div
                className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
                style={{ backgroundColor: theme.palette.primary.main }}
              >
                <div
                  style={{
                    paddingTop: " 0.75rem",
                    paddingBottom: " 0.75rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                >
                  <span className="block leading-tight">
                    Please tell us a bit more about your experience
                  </span>
                </div>
              </div>
            </div>
            {isShowNegativeFormFeedbackInputFooter === true ? (
              <div className="ajax-div-question-seven-negative">
                <div className="fixed z-10 w-full max-w-lg px-4 mx-auto -translate-x-1/2 left-1/2 bottom-5">
                  <form
                    method="POST"
                    className="flex items-center justify-center space-x-3"
                  >
                    <input
                      type="text"
                      value={negativeFeedback}
                      onChange={(e) => setnegativeFeedback(e.target.value)}
                      id="negative_feedback"
                      placeholder="Write here..."
                      className="block w-full px-4 py-4 text-base text-black transition-all duration-200 bg-white border border-transparent rounded-full shadow-lg caret-purple-600 focus:border-purple-600 focus:ring-purple-600 focus:outline-none"
                    />
                    <button
                      type="submit"
                      className="flex items-end justify-center flex-shrink-0 p-4 text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
                      style={{ backgroundColor: theme.palette.primary.main }}
                      onClick={(e) => submitFeedback(e)}
                    >
                      <svg
                        className="w-6 h-6 rotate-90"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                      </svg>
                    </button>
                  </form>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex flex-row-reverse items-end justify-start pl-20 transition-all duration-200 m-t-30">
                  <div className="text-black bg-white rounded-br-none shadow rounded-2xl">
                    <div className="flex items-center justify-end px-4 py-3">
                      <span className="ml-3 font-medium">
                        {negativeFeedback}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30">
                  <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
                    <img
                      className="object-cover w-full h-full"
                      src={user_image}
                      alt="true"
                    />
                  </div>
                  <div
                    className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
                    style={{ backgroundColor: theme.palette.primary.main }}
                  >
                    <div
                      style={{
                        paddingTop: " 0.75rem",
                        paddingBottom: " 0.75rem",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                      }}
                    >
                      <span className="block leading-tight">Thank you</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}

        {webRatingData.hideContact ? (
          <>
            <div
              className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30"
              style={{ marginBottom: "30px" }}
            >
              <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
                <img
                  className="object-cover w-full h-full"
                  src={user_image}
                  alt="true"
                />
              </div>
              <div
                className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
                style={{ backgroundColor: theme.palette.primary.main }}
              >
                <div
                  style={{
                    paddingTop: " 0.75rem",
                    paddingBottom: " 0.75rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                >
                  <span className="block leading-tight">
                    Please tell us a bit more about your experience
                  </span>
                </div>
              </div>
            </div>
            {isShowNegativeFormFeedbackInputFooter === true ? (
              <div className="ajax-div-question-seven-negative">
                <div className="fixed z-10 w-full max-w-lg px-4 mx-auto -translate-x-1/2 left-1/2 bottom-5">
                  <form
                    method="POST"
                    className="flex items-center justify-center space-x-3"
                  >
                    <input
                      type="text"
                      value={negativeFeedback}
                      onChange={(e) => setnegativeFeedback(e.target.value)}
                      id="negative_feedback"
                      placeholder="Write here..."
                      className="block w-full px-4 py-4 text-base text-black transition-all duration-200 bg-white border border-transparent rounded-full shadow-lg caret-purple-600 focus:border-purple-600 focus:ring-purple-600 focus:outline-none"
                    />
                    <button
                      type="submit"
                      className="flex items-end justify-center flex-shrink-0 p-4 text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
                      style={{ backgroundColor: theme.palette.primary.main }}
                      onClick={(e) => submitFeedback(e)}
                    >
                      <svg
                        className="w-6 h-6 rotate-90"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                      </svg>
                    </button>
                  </form>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex flex-row-reverse items-end justify-start pl-20 transition-all duration-200 m-t-30">
                  <div className="text-black bg-white rounded-br-none shadow rounded-2xl">
                    <div className="flex items-center justify-end px-4 py-3">
                      <span className="ml-3 font-medium">
                        {negativeFeedback}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30">
                  <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
                    <img
                      className="object-cover w-full h-full"
                      src={user_image}
                      alt="true"
                    />
                  </div>
                  <div
                    className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
                    style={{ backgroundColor: theme.palette.primary.main }}
                  >
                    <div
                      style={{
                        paddingTop: " 0.75rem",
                        paddingBottom: " 0.75rem",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                      }}
                    >
                      <span className="block leading-tight">Thank you</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>
    );
  };

  const SubmitPage2TouchNegativeType = (temp) => {
    setisShowCanWeGenInTouchBtn(false);
    if (temp === "Yes") {
      setisShowCanWeGenInTouchAfterYesBlock("Yes");
    }
    if (temp === "No") {
      setisShowCanWeGenInTouchAfterNoBlock("No");
      setisShowNegativeFormFeedbackInputFooter(true);
    }
  };

  const [negativeName, setnegativeName] = React.useState("");
  const [negativeEmail, setnegativeEmail] = React.useState("");
  const [negativePhone, setnegativePhone] = React.useState("");
  const [negativeFeedback, setnegativeFeedback] = React.useState("");
  const history = useHistory();
  const SubmitNegativeName = (e) => {
    e.preventDefault();
    setFrontratingAnsweres_({ key: "customer_name", value: negativeName });

    setisShowNegativeFormNameInputFooter(false);
    setisShowNegativeFormEmailInputFooter(true);
  };

  const submitNegativeEmail = (e) => {
    e.preventDefault();
    setFrontratingAnsweres_({ key: "customer_email", value: negativeEmail });

    setisShowNegativeFormEmailInputFooter(false);
    setisShowNegativeFormPhoneInputFooter(true);
  };
  const submitPhoneNumber = (e) => {
    e.preventDefault();
    setFrontratingAnsweres_({ key: "customer_phone", value: negativePhone });

    setisShowNegativeFormPhoneInputFooter(false);
    setisShowNegativeFormFeedbackInputFooter(true);
  };

  const submitFeedback = async (e) => {
    e.preventDefault();
    setisShowNegativeFormFeedbackInputFooter(false);
    let ratingCookie = cookies.rating;
    if (ratingCookie?.location !== webRatingAnsweres.location_id) {
      const response = await saveDetails(webRatingAnsweres);
      if (response && response.data.metaData) {
        setCookie(
          "rating",
          {
            rating: "submited",
            location: response.data.metaData.location_id,
          },
          { path: "/", maxAge: 86400 }
        );
        props.resetFrontratingAnsweres_();
        setTimeout(() => {
          setisShowSelectSkillFooterPortion(true);
          setisSelectORSkillAfterBlock(false);
          setisShowCanWeGenInTouchBtn(false);
          setisShowCanWeGenInTouchAfterYesBlock("");
          setisShowCanWeGenInTouchAfterNoBlock("");
          setisShowNegativeFormNameInputFooter(true);
          setisShowNegativeFormEmailInputFooter(false);
          setisShowNegativeFormPhoneInputFooter(false);
          setisShowNegativeFormFeedbackInputFooter(false);
          props.resetToIntitialChatDisp();
        }, 20000);
        history.push("/front/Ratting/new-thank-you-page");
      } else {
        toast.error(response?.message);
        history.push("/frontRating");
      }
    } else {
      toast.error("You have already submitted rating");
    }
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 20,
    onIdle: async () => {
      getRemainingTime();
      let ratingCookie = cookies.rating;
      if (ratingCookie?.location !== webRatingAnsweres.location_id) {
        const response = await saveDetails({
          ...webRatingAnsweres,
          page: getDropoutPage().page,
          dropout_page: getDropoutPage().dropout_page,
        });
        if (response && response?.data?.metaData) {
          setCookie(
            "rating",
            {
              rating: "submited",
              location: response.data.metaData.location_id,
            },
            { path: "/", maxAge: 86400 }
          );

          props.resetFrontratingAnsweres_();
          setTimeout(() => {
            setisShowSelectSkillFooterPortion(true);
            setisSelectORSkillAfterBlock(false);
            setisShowCanWeGenInTouchBtn(false);
            setisShowCanWeGenInTouchAfterYesBlock("");
            setisShowCanWeGenInTouchAfterNoBlock("");
            setisShowNegativeFormNameInputFooter(true);
            setisShowNegativeFormEmailInputFooter(false);
            setisShowNegativeFormPhoneInputFooter(false);
            setisShowNegativeFormFeedbackInputFooter(false);
            props.resetToIntitialChatDisp();
          }, 1000);
          history.push("/front/Ratting/new-thank-you-page");
        } else {
          toast.error(response?.message);
          history.push("/frontRating");
        }
      } else {
        toast.error("You have already submitted rating");
      }
    },

    debounce: 500,
  });
  //main flow
  if (rating !== null && rating >= 1 && rating <= 3) {
    return (
      <React.Fragment>
        {/* select skill or skip  block  */}

        <div className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30">
          <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
            <img
              className="object-cover w-full h-full"
              src={user_image}
              alt="user"
            />
          </div>
          <div
            className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            <div
              style={{
                paddingTop: " 0.75rem",
                paddingBottom: " 0.75rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              <span className="block leading-tight">
                Sorry to hear you didn't have the best experiance today with us,
                please could you highlight what went wrong?
              </span>
            </div>
          </div>
        </div>
        {isShowSelectSkillFooterPortion === true ? (
          <div className="fixed bottom-0 z-10 w-full max-w-lg mx-auto -translate-x-1/2 left-1/2">
            <div className="px-4 bg-white border-t border-gray-200">
              <div className="flex items-center space-x-3 h-18">
                <button
                  type="button"
                  className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
                  onClick={() => setOpen(true)}
                  style={{ color: theme.palette.primary.main }}
                >
                  <svg
                    className="w-5 h-5 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Select
                </button>
                <button
                  onClick={() => skipSelectSkillStep()}
                  type="button"
                  className="inline-flex items-center flex-shrink-0 px-5 py-3 text-sm font-medium leading-4 text-gray-700 transition-all duration-200 bg-white border border-gray-300 rounded-full shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
                >
                  Skip
                </button>
              </div>
            </div>
            <SelectSkillModal />
          </div>
        ) : (
          <></>
        )}

        {isShowSelectORSkillAfterBlock ? SelectSkillORSkipAfterBlock() : <></>}
      </React.Fragment>
    );
  } else {
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    webRatingData: state.frontWebRatingDataReducer.webRatingData,
    webRatingAnsweres: state.frontWebRatingDataReducer.webRatingAnsweres,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFrontratingData_: (data) => dispatch(setFrontratingData(data)),
    setFrontratingAnsweres_: (data) => dispatch(setFrontratingAnsweres(data)),
    resetFrontratingAnsweres_: () => dispatch(resetFrontratingAnsweres()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NegativeChatReply);
