export const setFrontratingData = (payload) => {
  return {
    type: "SET_WEB_RATING_DATA",
    payload,
  };
};
export const setFrontratingAnsweres = (payload) => {
  return {
    type: "SET_WEB_RATING_ANSWERES",
    payload,
  };
};

export const resetFrontratingAnsweres = () => {
  return {
    type: "RESET_WEB_RATING_ANSWERES",
  };
};

export const toggleAlphegaActiveState = (payload) => {
  return {
    type: "TOGGLE_ALPHEGA_ACTIVE_STATE",
    payload,
  };
};
