import React from "react";
function EmojiImageHeaderFeedback(props) {
  return (
    <em>
      <img
        src={
          localStorage.getItem("emojiImagePath")
            ? localStorage.getItem("emojiImagePath")
            : ""
        }
        alt="emoji pic"
      />
    </em>
  );
}

export default EmojiImageHeaderFeedback;
