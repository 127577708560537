export const allowedMimeTypes = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "video/mp4", // MP4 format
  "video/webm", // WebM format
  "video/quicktime", // MOV format
  "video/x-matroska", // MKV format
  "video/ogg", // OGV format
];
