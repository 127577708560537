import axios from "axios";

const BaseURL = process.env.REACT_APP_BASE_URL;
const postData = async (url, body) => {
  const jwsToken = localStorage.getItem("jws_token");
  const response = await fetch(`${BaseURL}/${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: jwsToken,
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    },
    //  'content-type': 'multipart/form-data'},
    body: JSON.stringify(body),
  });

  if (response.status === 401) {
    localStorage.clear();
  }

  const result1 = await response.json();
  return result1;
};

const getData = async (url) => {
  const jwsToken = localStorage.getItem("jws_token");

  const response = await fetch(`${BaseURL}/${url}`, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: jwsToken,
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  if (response.status === 401) {
    localStorage.clear();
    window.location.reload(false);
  }

  const result2 = await response.json();
  return result2;
};

const axiosPostData = async (url, formData) => {
  return axios
    .post(`${BaseURL}/${url}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        // Authorization: localStorage.getItem("jws_token"),
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.reload(false);
      }
      return err;
    });
};
export { postData, getData, axiosPostData };
