import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import EmojiImageHeaderFeedback from "./EmojiImageHeaderFeedback";
import Footer from "./Footer";
import "../assets/css/FeedbackRatingCustomStyles.css";

function EmployeeFeedbackStep2(props) {
  const history = useHistory();
  const [feedback, setfeedback] = useState("");

  const redirectPreviousTab = () => {
    history.push("/employee-feedback-step-1");
  };

  const gotoNextPage = () => {
    localStorage.setItem("employeeFeedback", feedback);
    history.push("/employee-feedback-step-3");
  };

  return (
    <React.Fragment>
      <div className="wrapper has-fix-bar">
        <form method="post" autoComplete="off">
          <section className="feedback-stepper-wrapper has-left-content">
            <div className="container">
              <div className="top-header-wrapper">
                <div className="experience-ans-tip">
                  {/* fetching rating from session  */}
                  <EmojiImageHeaderFeedback />
                </div>
                <div className="pagination-wrapper">
                  <span>3</span>/<span>3</span>
                </div>
              </div>
              <h1 className="h1-heading">
                  Is there anything else you'd like to share with us today?
              </h1>
              <div className="white-box about-instructor two-col-block">
              <h2 className="emp-feedback-h2-heading">
                  Is there anything else you'd like to share with us today?
              </h2>
                <div >
                  <p>
                  Do you have any comments or feedback about your experience with us today?
                  </p>
                  <div className="form-group">
                    <textarea
                      className="form-control form-control-web-ratting"
                      placeholder="Share us your experience with us today..."
                      name="feedback"
                      id="feedback"
                      rows={4}
                      defaultValue={feedback}
                      onChange={(e) => setfeedback(e.target.value)}
                    />
                  </div>
                  
                </div>
              </div>
              <div className="bottom-fix-btnbar">
                <button
                  className="btn border-btn icon-btn sm rating-feedback-prev-btn"
                  title="Prev"
                  onClick={() => redirectPreviousTab()}
                  style={{ border: "1px solid #955275", padding: "0px" }}
                >
                  <em>
                    <svg
                      id="_32213"
                      data-name={32213}
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.519"
                      height="13.19"
                      viewBox="0 0 7.519 13.19"
                    >
                      <path
                        id="Path_71"
                        data-name="Path 71"
                        d="M97.409,7.248l5.672,5.671a.924.924,0,0,0,1.306-1.306L99.369,6.595l5.019-5.018A.924.924,0,0,0,103.081.27L97.409,5.942a.924.924,0,0,0,0,1.306Z"
                        transform="translate(-97.139 0)"
                        fill="#955275"
                      />
                    </svg>
                  </em>
                  Prev
                </button>
                <div className="pagination-wrapper">
                  <span>2</span>/<span>3</span>
                </div>
                <button
                onClick={(e) => gotoNextPage()}
                className="btn icon-btn icon-right sm"
                title="Next"
                style={{
                  backgroundColor: "#955275",
                  color: "#fff",
                  border: "1px solid #955275",
                  padding: "0px",
                }}
              >
                Next
                <em>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.519"
                    height="13.191"
                    viewBox="0 0 7.519 13.191"
                  >
                    <g
                      id="_32213"
                      data-name={32213}
                      transform="translate(-97.138 0)"
                    >
                      <path
                        id="Path_71"
                        data-name="Path 71"
                        d="M104.387,7.248,98.715,12.92a.924.924,0,1,1-1.306-1.306L102.428,6.6,97.409,1.577A.924.924,0,0,1,98.716.27l5.672,5.672a.924.924,0,0,1,0,1.306Z"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </em>
              </button>
              </div>
            </div>
          </section>
        </form>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default EmployeeFeedbackStep2;
