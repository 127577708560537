import React, { useState } from "react";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const CheckboxOptions = ({
  options,
  sequence = false,
  onClick,
  answer,
  error,
}) => {
  const [selections, setSelections] = useState(answer?.value || []);

  const handleOnClick = (optionId) => {
    let newSelections = [];
    const existing = selections.find((id) => id === optionId);
    if (existing) {
      newSelections = selections.filter((id) => id !== optionId);
    } else {
      newSelections = [...selections, optionId];
    }
    setSelections(newSelections);
    onClick(newSelections);
  };

  const isActive = (optionId) => {
    return answer?.value?.includes(optionId);
  };

  return (
    <>
      {options?.length
        ? options.map((option) => (
            <div key={option._id} className="mb-1">
              <button
                className={`option-button checkbox-button d-flex align-items-center ${
                  isActive(option._id) ? "selected" : ""
                }`}
                onClick={() => handleOnClick(option._id)}
              >
                <div>
                  {isActive(option._id) ? (
                    <CheckBoxIcon color="action" />
                  ) : (
                    <CheckBoxOutlineBlankIcon color="action" />
                  )}
                </div>

                {sequence ? (
                  <div className="text-muted small font-weight-bold pl-2 pr-1">
                    {isActive(option._id) &&
                    answer?.value?.indexOf(option._id) >= 0 ? (
                      <>({answer?.value?.indexOf(option._id) + 1})</>
                    ) : null}
                  </div>
                ) : null}

                <div className="px-2">{option.text}</div>
              </button>
            </div>
          ))
        : null}

      {error ? (
        <div className="mt-2 small font-weight-bold text-danger">
          {error.message}
        </div>
      ) : null}
    </>
  );
};

export default CheckboxOptions;
