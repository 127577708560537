import React from "react";

import {
  setFrontratingAnsweres,
  resetFrontratingAnsweres,
} from "../../../../redux/frontWebRating/actions";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { Typography, Box } from "@material-ui/core";
import { useStyles } from "./styles";
import { saveDetails } from "../../../apiHelpers/saveDetails";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

const EmployeeSelection = (props) => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(["rating"]);

  const employees = props.webRatingData?.employee;

  const history = useHistory();

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 20,
    onIdle: async () => {
      getRemainingTime();

      let ratingCookie = cookies.rating;
      if (
        ratingCookie?.location !== props.webRatingAnsweres.location_id ||
        localStorage.getItem("is_frequent_rating")
      ) {
        const response = await saveDetails({
          ...props.webRatingAnsweres,
          page: 1,
          dropout_page: "p1",
        });
        if (response && response?.data?.metaData) {
          setCookie(
            "rating",
            {
              rating: "submited",
              location: response.data.metaData.location_id,
            },
            { path: "/", maxAge: 86400 }
          );
          props.resetFrontratingAnsweres_();
          history.push("/thankYou");
        } else {
          toast.error(response?.message);
          history.push("/frontRating");
        }
      } else {
        toast.error("You have already submitted rating");
      }
    },

    debounce: 500,
  });

  const handleImageClick = (id) => {
    props?.setFrontratingAnsweres_({
      key: "employee_id",
      value: id,
    });
    props.onClick && props.onClick();
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.mainheading}>
        We’d love to know who looked after you today?
      </Typography>
      <Box style={{ padding: "3%" }}>
        <Grid container spacing={3} style={{ justifyContent: "center" }}>
          {employees.map((item) => (
            <Grid
              item
              xs={5}
              md={4}
              lg={3}
              style={{ cursor: "pointer", textAlign: "center" }}
              key={Math.random()}
            >
              {item?.image === "" ? (
                <div
                  style={{
                    backgroundColor: "#66779a4d",
                    height: "187px",
                    width: "187px",
                    fontSize: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#ffffff",
                    border: "2px solid #66779A",
                    borderRadius: "5%",
                    objectFit: "fit",
                    margin: "auto",
                  }}
                  objectFit="contain"
                >
                  {item?.name.slice(0, 1)}
                </div>
              ) : (
                <img
                  objectFit="contain"
                  style={{
                    width: "187px",
                    height: "187px",
                    borderRadius: "5%",
                    objectFit: "cover ",
                  }}
                  src={item?.image}
                  onClick={() => handleImageClick(item?.id)}
                  alt="epmloyee"
                />
              )}
              <div className={classes.nameBox}>
                <p className={classes.nameText}>{item?.name}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    webRatingData: state.frontWebRatingDataReducer.webRatingData,
    webRatingAnsweres: state.frontWebRatingDataReducer.webRatingAnsweres,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFrontratingAnsweres_: (data) => dispatch(setFrontratingAnsweres(data)),
    resetFrontratingAnsweres_: () => dispatch(resetFrontratingAnsweres()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSelection);
