import React from "react";
import emoji1 from "../../../../assets/images/web_rating/emoji_1.gif";
import emoji2 from "../../../../assets/images/web_rating/emoji_2.gif";
import emoji3 from "../../../../assets/images/web_rating/emoji_3.gif";
import emoji4 from "../../../../assets/images/web_rating/emoji_4.gif";
import emoji5 from "../../../../assets/images/web_rating/emoji_5.gif";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./question.css";
import { Rate } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import { connect } from "react-redux";

// Array to store emoji images
const emojiImage = [emoji1, emoji2, emoji3, emoji4, emoji5];

const useStyles = makeStyles((theme) => ({
  chipRoot: {
    height: "100%",
    marginRight: "2px",
  },
  chipLabel: {
    overflowWrap: "break-word",
    whiteSpace: "normal",
    textOverflow: "clip",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    textAlign: "left",
  },
}));

function SingleAnswer({ answer, answeres }) {
  const classes = useStyles();

  return (
    <>
      {/* Render answer based on its type */}
      {answer ? (
        <div className="flex flex-column items-end justify-start pl-0 transition-all duration-200 mt-3 text-right">
          {/* Render emoji answer */}
          {answer?.type === "emoji" ? (
            <div className="answerArea">
              {answer?.text === "" ? null : (
                <img
                  className="w-10 h-10"
                  src={emojiImage[answer?.text]}
                  alt="empoji-path"
                />
              )}
            </div>
          ) : answer?.type === "file" ? (
            // Render file answer
            <div className="answerArea">
              <div
                className={`${
                  answer?.text.split(",")?.length > 3
                    ? "answerImage"
                    : "d-flex gap-2"
                }`}
              >
                {/* Map over file images */}
                {answeres[answer?.question?._id]?.value?.map((image, index) => {
                  return (
                    <>
                      {/* Render video or image based on file type */}
                      {image instanceof File ? (
                        image.type.startsWith("video/") ? (
                          <video
                            controls
                            style={{
                              maxWidth: "100px",
                              width: "100px",
                              height: "100px",
                            }}
                          >
                            <source
                              src={URL.createObjectURL(image)}
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <div
                            key={index}
                            style={{ width: "100px", height: "100px" }}
                          >
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`file-${index}`}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        )
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          ) : answer?.type === "sequence" ? (
            <div className="answerArea">
              {/* Render sequence chips */}
              {answer?.text.split("$#&")?.map((text, i) => (
                <Chip
                  key={i}
                  label={text}
                  variant="outlined"
                  className="chip"
                  classes={{
                    root: classes.chipRoot,
                    label: classes.chipLabel,
                  }}
                />
              ))}
            </div>
          ) : answer?.type === "star_rating" ? (
            <div className="text-black bg-white rounded-br-none shadow rounded-2xl">
              <div className="answerWhite answerArea">
                <Rate
                  value={parseFloat(answer?.text)}
                  allowHalf
                  max={
                    answer?.question?.options.length > 0
                      ? Math.max(
                          ...answer?.question?.options.map(
                            (option) => option.value
                          )
                        )
                      : 5
                  }
                  size="sm"
                  readOnly
                />
              </div>
            </div>
          ) : ["multiselect", "skills"]?.includes(answer?.type) ? (
            <div className="answerArea">
              {/* Render multi-select or skills chips */}
              {answer?.text.split(",")?.map((text, i) => (
                <Chip
                  key={i}
                  label={text}
                  variant="outlined"
                  className="chip"
                  classes={{
                    root: classes.chipRoot,
                    label: classes.chipLabel,
                  }}
                />
              ))}
            </div>
          ) : ["radio", "employees"]?.includes(answer?.type) ? (
            <Chip
              label={answer?.text}
              variant="outlined"
              className="mb-1"
              classes={{
                root: classes.chipRoot,
                label: classes.chipLabel,
              }}
            />
          ) : answer?.type === "handwrittenInput" ? (
            <div className="answerArea">
              {answer?.text === "" ? null : (
                <div style={{ height: 70, width: 150 }}>
                  <img
                    src={answer?.text}
                    alt="empoji-path"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="text-black bg-white rounded-br-none shadow rounded-2xl ">
              <div className="px-3 py-2 answerArea text-left">
                {answer?.text}
              </div>
            </div>
          )}
          {answer?.comment ? (
            <p className="comment answerArea">
              <span className="mr-1">Comment:</span>
              {answer?.comment}
            </p>
          ) : (
            ""
          )}
          {answer?.question?._id in answeres ? (
            answeres[answer?.question?._id]?.note ? (
              <p className="comment answerArea">
                <span className="mr-1">Comments:</span>
                {answeres[answer?.question?._id]?.note}
              </p>
            ) : (
              ""
            )
          ) : null}
          {answer?.question?._id in answeres ? (
            answeres[answer?.question?._id]?.image?.length ? (
              <div
                className={`mt-2 ${
                  answeres[answer?.question?._id]?.image?.length > 3
                    ? "answerImage"
                    : "d-flex gap-2"
                }`}
              >
                {answeres[answer?.question?._id]?.image?.map((image, index) => {
                  return (
                    <>
                      {image instanceof File ? (
                        image.type.startsWith("video/") ? (
                          <video
                            controls
                            style={{
                              maxWidth: "100px",
                              width: "100px",
                              height: "100px",
                            }}
                          >
                            <source
                              src={URL.createObjectURL(image)}
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <div
                            key={index}
                            style={{ width: "100px", height: "100px" }}
                          >
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`file-${index}`}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        )
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </div>
            ) : (
              ""
            )
          ) : null}
        </div>
      ) : null}
    </>
  );
}
// mapStateToProps function to map Redux state to component props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Connect SingleAnswer component to Redux store
export default connect(mapStateToProps)(SingleAnswer);
