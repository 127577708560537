import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: ({ backgroundImg }) => {
    return {
      // backgroundColor: "rgba(0, 0, 0,)",
      backgroundImage: `url(${
        backgroundImg ? backgroundImg : "/images/survey/bg.png"
      })`,

      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 'calc("10px" + " 2vmin")',
      color: "white",
      textAlign: "center",
    };
  },
  logo: {
    height: "auto",
  },
  loginContainer: {
    backgroundColor: "rgba(255, 255, 255)",
    width: "80%",
    height: "450px",
    borderRadius: "20px",
    marginTop: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4%",
    [theme.breakpoints.up("md")]: {
      width: "38%",
    },
  },
  loginInput: {
    fontFamily: "Inter",
    fontSize: "19px",
    color: "#949494",
    paddingLeft: "26px",

    height: "50px",
    width: "100%",
    borderRadius: "10px",
    border: "0.5px solid #949494",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    borderWidth: "2px",
  },
  button: {
    fontSize: "20px",
    fontFamily: "Inter",
    width: "100%",
    height: "50px",
    marginTop: "34px",
    borderRadius: "10px",
    color: "#FFF",
    backgroundColor: theme.palette.primary.main,
    border: "none",
  },
  rememberStyle: {
    color: theme.palette.secondary.dark,
    fontSize: " 14px",
    fontFamily: "Inter",
  },
  checkbox: {
    width: "16px",
    height: "16px",
  },
}));
