import React from "react";
import { Typography, Box, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { ratingStyles } from "../styles/ratingStyle";
import { connect } from "react-redux";
import { addAnswer, clearAnsweres } from "../../../redux/survey/actions";
// Rating component
function Rating(props) {
  // Destructure props for easier access
  const {
    finishSurvey,
    headTonext,
    question,
    answeres,
    addAnswer_,
    pageNo,
    surveyType,
    allQuestionsOriginal,
    setAllQuestions,
    setTotalPage,
    isGreyOut,
    clearAnsweres_,
  } = props;
  // Generate styles using the ratingStyles function
  const classes = ratingStyles(props);
  // Access theme
  const theme = useTheme();

  // Function to handle rating selection
  const handleRating = (
    ratingId,
    ratingValue,
    routeToIndex,
    score_exclude,
    hideIndex,
    finishSurvey
  ) => {
    if (surveyType === "tablet" && pageNo === 1) {
      clearAnsweres_(); // Clear answers if survey is on tablet and it's the first page

      // Calculate percentage based on rating value and maximum score of the question
      const percentage =
        (Number(ratingValue) / Number(question?.maxScore)) * 100;
      // Filter questions based on percentage and journey type
      if (percentage >= 70) {
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "positive"
          );

        setTotalPage(filteredQuestion.length + 1);

        setAllQuestions([question, ...filteredQuestion]);
      } else {
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "negative"
          );

        setTotalPage(filteredQuestion.length + 1);

        setAllQuestions([question, ...filteredQuestion]);
      }
    }
    // Add answer to Redux state
    addAnswer_({
      id: question._id,
      value: ratingId,
      type: "id",
      score_exclude: score_exclude,
    });
    // Navigate to next question if survey is not finished
    if (!finishSurvey) {
      headTonext(routeToIndex, hideIndex, ratingId);
    }
  };

  // Retrieve answer for current question
  let answer = answeres[question._id] ?? "";
  // Check if screen size is mobile
  const isMob = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <div>
        <Box className={classes.container}>
          <div
            style={{
              display: "flex",
              flexDirection: isMob && "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            {question.options.map((item, index) => {
              return (
                <div className="ratingItemContainer">
                  <Box
                    key={item?._id}
                    onClick={() => {
                      finishSurvey(item?.finish_survey === true, question);
                      !isGreyOut &&
                        handleRating(
                          item._id,
                          item.value,
                          item?.route_to_index,
                          item?.score_exclusion_selection,
                          item?.hide_questions,
                          item?.finish_survey === true
                        );
                    }}
                    className={`ratingItem ${isMob ? "mt-2" : "mr-2 mt-2"}`}
                    style={{
                      border: "1px solid #404040",
                      width: isMob ? "100% !important" : "",
                      minWidth: isMob ? "" : "35px",
                      minHeight: "35px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "7px",
                      backgroundColor:
                        answer.value === item._id ? "#e8e8e8" : "white",
                      cursor: isGreyOut && "not-allowed",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: isMob ? "14px" : "",
                        fontFamily: "Inter",
                        color: "#404040",
                        fontWeight: answer.value === item._id ? 600 : "",
                      }}
                    >
                      {item.text}
                    </Typography>
                  </Box>
                  {item?.optionText && (
                    <p
                      className="optionText"
                      style={{ textAlign: "center", fontSize: "13px" }}
                    >
                      {item?.optionText}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        </Box>
      </div>
    </>
  );
}
// Map Redux state to component props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Map Redux dispatch functions to component props
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
  };
};
// Connect component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Rating);
