import { Box, Grid } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import React from "react";
import { connect } from "react-redux";
import { addAnswer, clearAnsweres } from "../../../../redux/survey/actions";
import CheckBoxCard from "../../../common/components/CheckBoxCard";
import RadioCard from "../../../common/components/RadioCard";
import "../NewRadioButton.css";
import TextArea from "../TextArea";

const Radio = (props) => {
  // Destructure props for easier access
  const {
    finishSurvey,
    headTonext,
    question,
    answeres,
    addAnswer_,
    pageNo,
    surveyType,
    allQuestionsOriginal,
    setAllQuestions,
    setTotalPage,
    isGreyOut,
    clearAnsweres_,
  } = props;

  // Function to handle radio selection
  const handleChange = async (
    ratingId,
    value,
    routeToIndex,
    score_exclude,
    hideIndex,
    finishSurvey
  ) => {
    if (surveyType === "tablet" && pageNo === 1) {
      clearAnsweres_();
      // Calculate percentage based on value and maximum score of the question
      const percentage = (Number(value) / Number(question?.maxScore)) * 100;

      // Filter questions based on percentage and journey type
      if (percentage >= 70) {
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "positive"
          );

        setTotalPage(filteredQuestion.length + 1);

        setAllQuestions([question, ...filteredQuestion]);
      } else {
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "negative"
          );

        setTotalPage(filteredQuestion.length + 1);

        setAllQuestions([question, ...filteredQuestion]);
      }
    }
    // Add answer to Redux state
    await addAnswer_({
      id: question._id,
      value: ratingId,
      type: "id",
      score_exclude: score_exclude,
    });
    // Navigate to next question if survey is not finished
    if (!finishSurvey) {
      headTonext(routeToIndex, hideIndex, ratingId);
    }
  };

  // Function to retrieve answer for the current question
  let answer = () => {
    // Check if question ID exists in answers
    if (!(question._id in answeres)) {
      return { value: "" }; // If not, return empty value
    } else {
      if (!("value" in answeres[question._id])) {
        return { value: "" }; //  If answer doesn't have a value property, return empty value
      } else {
        return answeres[question._id]; // Otherwise, return the answer
      }
    }
  };

  // Filter options to find those with iconClass defined
  let withIcon = question.options.filter((item) => item.iconClass);
  // Filter options to find those allowing write-in and matching the current answer value
  let allowWritein = question.options.filter(
    (item) => item.write_in && item._id === answer().value
  );

  return (
    <Box style={{ cursor: isGreyOut && "not-allowed" }}>
      {withIcon.length ? (
        <Grid container>
          {question.options.map((option) => (
            <Grid item xs={6} md={4} sm={3} lg={3} key={option._id}>
              <CheckBoxCard
                checked={answer().value}
                item={option}
                onClick={() =>
                  handleChange(
                    option._id,
                    option.value,
                    option?.score_exclusion_selection,
                    option?.hide_questions
                  )
                }
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <FormControl component="fieldset" style={{ marginLeft: 13 }}>
          <RadioGroup
            className="radioInline"
            style={
              question?.options?.length > 2
                ? { flexDirection: "column" }
                : { flexWrap: "wrap" }
            }
            row
            aria-label="position"
            name="position"
          >
            {question.options.map((option) => (
              <div
                key={option._id}
                style={
                  question?.options?.length === 2
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "20px",
                      }
                    : { marginBottom: "0px" }
                }
              >
                <RadioCard
                  isGreyOut={isGreyOut}
                  key={option._id}
                  answer={answer().value}
                  option={option}
                  handleChange={() => {
                    finishSurvey(option?.finish_survey === true, question);
                    !isGreyOut &&
                      handleChange(
                        option._id,
                        option.value,
                        option?.route_to_index,
                        option?.score_exclusion_selection,
                        option?.hide_questions,
                        option?.finish_survey === true
                      );
                  }}
                  type="radio"
                />
                {option?.optionText && (
                  <p
                    className="ml-2 "
                    style={{ width: "50%", fontSize: "13px" }}
                  >
                    {option?.optionText}
                  </p>
                )}
              </div>
            ))}
          </RadioGroup>
        </FormControl>
      )}
      {allowWritein.length ? (
        <Box>
          <TextArea question={question} type="write_in" />
        </Box>
      ) : null}
    </Box>
  );
};
// Map Redux state to component props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Map Redux dispatch functions to component props
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
  };
};
// Connect component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Radio);
