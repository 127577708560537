import React  from "react";
import MdCard from "../common/MdCard";
import Line from "../../components/charts/Line";

function TrendResults(props) {
  const { count = [], date = [] } = props.data;

  const data = {
    datasets: [
      {
        data: count,
      },
    ],
    labels: date,
  };
 
  return (
    
    <MdCard title="Trend Results">
      <div
        style={{
          height: "90%",
          width: "90%",
          margin: "auto",
        }}
      >
        {count.length && date.length ? <Line data={data} /> : null}
      </div>
    </MdCard>
  );
}

export default TrendResults;
