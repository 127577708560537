import React from "react";
// import * as reportStyles from "../../../ReportPage/Reportstyle.module.css";
import reportStyles from "../../../ReportPage/Reportstyle.module.css";

const PerformanceFocusCard = (props) => {
  const { mainTitle = "", data } = props;

  return (
    <>
      <div class={reportStyles.divbackground6}>
        {/* Table heading */}
        <h3 class={reportStyles.heading1}>{mainTitle}</h3>

        {/* Table */}
        <div className="table-responsive-sm">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th style={{ width: "10%" }}>#</th>
                <th style={{ width: "30%" }}>Name</th>
                <th style={{ width: "20%" }} className="text-center">
                  Focus
                </th>
                <th style={{ width: "20%" }} className="text-center">
                  Average
                </th>
                <th style={{ width: "20%" }} className="text-center">
                  Count
                </th>
              </tr>
            </thead>

            <tbody>
              {data.length
                ? data.map((item, i) => (
                    <tr
                      style={{
                        background: i % 2 !== 0 ? "#f4f4f4" : "",
                      }}
                    >
                      <td className="font-weight-bold">{i + 1}</td>
                      <td>{item.name}</td>
                      <td className="text-center">{item.focus}</td>
                      <td className="text-center">
                        {parseFloat(item.average).toFixed(1)} %
                      </td>
                      <td className="text-center">{item.count}</td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
        </div>
      </div>

      <div class={reportStyles.divbackground5}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              paddingRight: "10px",
            }}
          >
            <i
              class={`fa-regular fa-lightbulb ${reportStyles.iconGrid}`}
              style={{ color: props.color }}
            ></i>
          </div>
          <div>
            <h3 class={reportStyles.heading2}>Did you know....</h3>

            <p class={reportStyles.para2}>
              <span>
                Net Promoter Score is based around % of promoters (Score a 5/5)
                - % of Detractors (Score a 1, 2 or 3), you can find out more
                about Net Promoter Score by clicking on the following
              </span>{" "}
              {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" style={{ color: props.color }}>
                  link
                </a>
              }
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerformanceFocusCard;
