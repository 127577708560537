import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    // minHeight: "100vh",
  },
  headingBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px 20px",
  },
  heading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "35px",
    display: "flex",
    textAlign: "center",
  },
  subHeading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    color: "#B9B8B8",
  },
  tagsContainer: {
    width: "100%",
    padding: "20px",
    textAlign: "center",
  },
  tagText: {
    padding: "7px",
    border: "1px solid #B9B8B8",
    display: "inline-block",
    borderRadius: "50px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    margin: 5,
    backgroundColor: "rgb(229, 229, 229,0.2)",
    cursor: "pointer",
    color:"#3E3E3E"
  },
  tagTextActive: {
    padding: "7px",
    border: "1px solid #B9B8B8",
    display: "inline-block",
    borderRadius: "50px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    margin: 5,
    backgroundColor:  "rgb(255, 14, 0,0.1)!important",
    // color: "#fff",

    cursor: "pointer",
  },
}));
