import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { connect } from "react-redux";
import { addAnswer, clearAnsweres } from "../../../../redux/survey/actions";
import { ratingStyles } from "../../../common/styles/ratingStyle";
import { Rate } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";

function StarRating(props) {
  // Destructure props for easier access
  const { question, answeres, addAnswer_, isGreyOut } = props;
  // css styling hooks
  const classes = ratingStyles(props);
  const defaultOption = question?.options[0];
  const theme = useTheme(); // Accessing current theme using useTheme hook
  // stage to hold the selected value of the rating
  const [selectedOption, setSelectedOption] = useState({
    value: 0,
    _id: defaultOption?._id,
  });
  // Function  to handle change in rating selection
  const handleRatingChange = (event) => {
    // Find the corresponding option based on the newValue
    const selected = question?.options.find((option) => option.value === event);
    // Update the state with the selected value and _id
    setSelectedOption({ value: event, _id: selected ? selected._id : null });
    if (selected) {
      // Add answer to Redux state
      addAnswer_({
        id: question._id,
        value: selected._id,
        type: "id",
      });
    } else {
      // Add answer to Redux state
      addAnswer_({
        id: question._id,
        value: defaultOption?._id,
        type: "id",
      });
    }
  };

  const isMob = useMediaQuery(theme.breakpoints.down("xs")); // Checking if the screen size is mobile using useMediaQuery hook

  useEffect(() => {
    let answer = answeres[question._id] ?? ""; // Extracting answer from Redux store
    if (answer?.value) {
      const option = question?.options?.find(
        (opt) => opt?._id === answer?.value
      );
      if (option) {
        setSelectedOption({ value: option?.value, _id: option?._id });
      }
    }
  }, []);

  return (
    <>
      <div>
        <Box className={classes.container}>
          <div
            style={{
              display: "flex",
              flexDirection: isMob && "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <Rate
              defaultValue={0}
              allowHalf
              max={
                question?.options.length > 0
                  ? Math.max(...question?.options.map((option) => option.value))
                  : 5
              } // Set max value based on options
              readOnly={isGreyOut}
              onChange={!isGreyOut && handleRatingChange}
              value={selectedOption?.value}
              disabled={isGreyOut}
            />
          </div>
        </Box>
      </div>
    </>
  );
}
// This is the mapStateToProps function that extracts the necessary state from the Redux store
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// This is the mapDispatchToProps function that binds the dispatch function to action creators
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
  };
};
// Connect component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(StarRating);
