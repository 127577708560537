import { postData } from "../../components/FetchServices";
import { isMobile } from "react-device-detect";
const loginHandler = async (
  values,
  props,
  history,
  resetFrontratingAnsweres_
) => {
  const body = {
    location: values?.username,
    password: values?.password,
    api_version: "v2",
    source: "web",
  };
  const result = await postData("api/locationLogin", body);
  if (result?.status === 200) {
    resetFrontratingAnsweres_();
    props.setFrontratingAnsweres_({
      key: "location_id",
      value: result.data._id,
    });
    localStorage.setItem("location_id", result.data._id);
    if (isMobile) {
      history.push("/front/mobile-home");
    } else {
      history.push("/frontRating");
    }
  }
};

const loadCustomSettings = (
  query,
  history,
  setFrontratingAnsweres_,
  resetFrontratingAnsweres_
) => {
  let company = query.get("company_id") ?? "";
  let location = query.get("location_id") ?? "";
  let tag = query.get("tag_id") ?? "";
  let rating = query.get("ratings") ?? "";
  let source = query.get("source") ?? "";

  rating && resetFrontratingAnsweres_();

  let custom_values = [];
  for (var key of query.keys()) {
    custom_values.push({ key: key, value: query.get(key) });
  }
  // setFrontratingAnsweres_({ key: "custom_values", value: custom_values });
  if (company) {
    localStorage.setItem("company_id", company);
    history.push("/ratingLocations");
  } else if (tag) {
    localStorage.setItem("tag_id", tag);
    history.push("/ratingLocations");
  } else {
    if (location) {
      localStorage.setItem("location_id", location);
      setFrontratingAnsweres_({ key: "location_id", value: location });

      if (rating) {
        setFrontratingAnsweres_({ key: "rating", value: rating });
        rating > 3 && setFrontratingAnsweres_({ key: "journey", value: "pos" });
        rating <= 3 &&
          setFrontratingAnsweres_({ key: "journey", value: "neg" });
      }
      if (source) {
        setFrontratingAnsweres_({ key: "source", value: source });
      }

      if (isMobile) {
        history.push("/front/mobile-home");
      } else {
        history.push("/frontRating");
      }
    }
  }
};

const validatePassword = (value) => {
  let error;
  if (!value) {
    error = "Please enter password";
  } else if (value.length < 4) {
    error = "Value must be longer than 3 characters";
  }
  return error;
};

const validateUsername = (value) => {
  let error;
  if (!value) {
    error = "Please enter user name";
  }
  return error;
};

export { loginHandler, loadCustomSettings, validatePassword, validateUsername };
