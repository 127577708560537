import { makeStyles } from "@material-ui/core/styles";
const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
};
export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    width: "100%",
    backgroundColor: theme.palette.primary.light,
    alignItems: "center",
    display: "grid",
  },
  innerContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    display: "none",
    margin: "2% 0",
    [theme.breakpoints.up("md")]: {
      width: "25%",
      display: "block",
    },
  },
  questionNumber: {
    fontFamily: "Inter",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    fontSize: "20px",
  },
  questionTitle: {
    fontFamily: "Inter",
    fontWeight: "Regular",
    color: theme.palette.primary.main,
    fontSize: "20px",
    paddingLeft: "5px",
    wrap: "auto",
  },
  dragTitle: {
    fontFamily: "Inter",
    fontWeight: "Regular",
    color: "#404040",
    fontSize: "16px",
    paddingLeft: "5px",
    wrap: "auto",
    width: "270px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      width: "250px",
    },
  },
  uploadBox: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",

    borderWidth: 2,
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "dashed",
    backgroundColor: "#F8F8F8",

    borderColor: "#000000",
    borderRadius: "5px",

    marginTop: "15px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      borderWidth: 1.5,
      marginTop: "10px",
    },
  },
  uploadBoxIcon: {
    fontSize: 60,
    color: "#404040",
    [theme.breakpoints.down("xs")]: {
      fontSize: 40,
      marginTop: 5,
      marginBottom: 5,
    },
  },
  helpText: {
    fontFamily: "Inter",
    fontWeight: "Regular",
    color: theme.palette.secondary.dark,
    fontSize: "10px",
  },
  Button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    fontWeight: "Regular",
    fontFamily: "Inter",
    marginTop: "12px",
    marginBottom: "12px",
  },
  ProgressContainer: {
    minHeight: "30px",
    backgroundColor: "rgba(143, 162, 128, 0.5)",

    borderRadius: "5px",
    padding: "5px 10px 10px 20px",
    margin: "10px 0 0 0",
    display: "flex",
    alignItems: "center",
  },
  progressDesc: {
    display: "flex",
    justifyContent: "space-between",
  },
  progressDescText: {
    fontSize: "15px",
    color: theme.palette.primary.dark,
  },
}));
