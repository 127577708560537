import React from "react";
// import * as reportStyles from "../Reportstyle.module.css";
import reportStyles from "../Reportstyle.module.css";

const MdCard = (props) => {
  const { title = "" } = props;

  return (
    <div class={reportStyles.divbackground4}>
      <h3 class={reportStyles.heading1}>{title}</h3>
      {props.children}
    </div>
  );
};

export default MdCard;
