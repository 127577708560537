import React, { useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useIdleTimer } from "react-idle-timer";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  setFrontratingData,
  setFrontratingAnsweres,
  resetFrontratingAnsweres,
} from "../redux/frontWebRating/actions";
import "../assets/css/MobileRatingMainStyles.css";
import "../assets/css/MobileRatingCustomStyles.css";
import { useTheme } from "@material-ui/core";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

//load images
import user_image from "../assets/images/mobile_emoji/lady.jpg";
import avatar_image from "../assets/images/mobile_emoji/avatar.png";
import { saveDetails } from "../webRatingNew/apiHelpers/saveDetails";

function PositiveChatReply(props) {
  const [open, setOpen] = React.useState(false);
  const [cookies, setCookie] = useCookies(["rating"]);
  const history = useHistory();
  const theme = useTheme();
  const [ringColor, setRingColor] = React.useState(false);
  const {
    webRatingData,
    webRatingAnsweres,

    setFrontratingAnsweres_,
  } = props;
  const skillData = props.skillData ? props.skillData : [];
  const employeeData = props.employeeData ? props.employeeData : [];

  let rating = localStorage.getItem("front-mobile-chatbox-rating");

  const [isShowStep1Footer, setisShowStep1Footer] = useState(
    !webRatingData.hide_team
  );

  const [isShowStep2Footer, setisShowStep2Footer] = useState(
    webRatingData.hide_team
  );
  const [isShowStep3Footer, setisShowStep3Footer] = useState(false);
  const [isShowStep4Footer, setisShowStep4Footer] = useState(false);

  const getDropoutPage = () => {
    if (isShowStep1Footer) {
      return { page: 1, dropout_page: "p1" };
    } else if (isShowStep2Footer) {
      return { page: 2, dropout_page: "p2" };
    } else if (isShowStep3Footer || isShowStep4Footer) {
      return { page: 2, dropout_page: "p2" };
    } else {
      return { page: "", dropout_page: "" };
    }
  };

  const [selectedEmployeeID, setselectedEmployeeID] = useState(null);
  const [selectedEmployeeName, setselectedEmployeeName] = useState(null);
  const [selectedEmployeeImagePath, setselectedEmployeeImagePath] =
    useState(null);
  const [demoEmpImagePath, setdemoEmpImagePath] = useState(avatar_image);

  const [selectedSkillIDs, setselectedSkillIDs] = useState(null);
  const [selectedSkillLabels, setselectedSkillLabels] = useState(null);

  const [is_standout, setstandout] = useState(0);
  const [feedback, setfeedback] = useState("");

  React.useEffect(() => {
    document.documentElement.style.setProperty(
      "--my_color",
      theme.palette.primary.main
    );
    props.updateList();
  }, [
    isShowStep1Footer,
    isShowStep2Footer,
    isShowStep3Footer,
    isShowStep4Footer,
  ]);

  const SkipEmployeeSelection = () => {
    setisShowStep1Footer(false);
    setisShowStep2Footer(true);

    setselectedEmployeeID(null);
    setselectedEmployeeName(null);
    setselectedEmployeeImagePath(null);
  };

  const getSelectedEmpDetail = (e) => {
    e.preventDefault();
    let employeeID = document.querySelector(
      `input[type="radio"][name=employee]:checked`
    )
      ? document.querySelector(`input[type="radio"][name=employee]:checked`)
          .value
      : "";
    let tempData = employeeData.filter((obj) => obj.id === employeeID);
    setOpen(false);
    if (tempData.length > 0) {
      setisShowStep1Footer(false);
      setisShowStep2Footer(true);
      setselectedEmployeeID(tempData[0]["id"]);
      setselectedEmployeeName(tempData[0]["name"]);
      setselectedEmployeeImagePath(tempData[0]["image"]);
      setFrontratingAnsweres_({
        key: "employee_id",
        value: tempData[0]["id"],
      });
    } else {
      SkipEmployeeSelection();
    }
  };

  const getSelectedSkillData = () => {
    let tempSelectedSkill = Array.from(
      document.querySelectorAll(
        "input[type=checkbox][name=selectedSkills]:checked"
      ),
      (e) => e.value
    );
    let selectedSkillIDs = [];
    let selectedSkillILabels = [];
    if (tempSelectedSkill.length > 0) {
      tempSelectedSkill.forEach((str, k) => {
        let tempData = str.split("_");
        selectedSkillIDs.push(tempData[0]);
        selectedSkillILabels.push(tempData[1]);
      });

      setOpen(false);
      setisShowStep2Footer(false);
      setisShowStep3Footer(true);
      setselectedSkillIDs(selectedSkillIDs.join(","));
      setFrontratingAnsweres_({
        key: "skill_id",
        value: selectedSkillIDs,
      });
      setselectedSkillLabels(selectedSkillILabels.join(","));
    } else {
      skipSelectSkill();
    }
  };

  const skipSelectSkill = () => {
    setisShowStep2Footer(false);
    setisShowStep3Footer(true);
    setselectedSkillIDs(null);
    setselectedSkillLabels(null);
  };

  const SubmitStandout = (temp) => {
    setisShowStep3Footer(false);
    setisShowStep4Footer(true);
    if (temp === 1) {
      setstandout(1);
      setFrontratingAnsweres_({
        key: "is_standout",
        value: true,
      });
    } else {
      setstandout(0);
      setFrontratingAnsweres_({
        key: "is_standout",
        value: false,
      });
    }
  };

  const submitFeedback = async (e) => {
    e.preventDefault();
    setisShowStep4Footer(false);
    let ratingCookie = cookies.rating;
    if (ratingCookie?.location !== webRatingAnsweres.location_id) {
      const response = await saveDetails(webRatingAnsweres);
      if (response && response?.data?.metaData) {
        setCookie(
          "rating",
          {
            rating: "submited",
            location: response.data.metaData.location_id,
          },
          { path: "/", maxAge: 86400 }
        );
        props.resetFrontratingAnsweres_();
        setTimeout(() => {
          setisShowStep1Footer(true);
          setisShowStep2Footer(false);
          setisShowStep3Footer(false);
          setisShowStep4Footer(false);
          setselectedEmployeeID(null);
          setselectedEmployeeName(null);
          setselectedEmployeeImagePath(null);
          setdemoEmpImagePath(avatar_image);
          setselectedSkillIDs(null);
          setselectedSkillLabels(null);
          setstandout(0);
          setfeedback("");

          props.resetToIntitialChatDisp();
        }, 20000);
        history.push("/front/Ratting/new-thank-you-page");
      } else {
        toast.error(response?.message);
        history.push("/frontRating");
      }
    } else {
      toast.error("You have already submitted rating");
    }
  };

  const selectedSkillArr =
    selectedSkillLabels !== null ? selectedSkillLabels.split(",") : [];

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 20,
    onIdle: async () => {
      getRemainingTime();
      let ratingCookie = cookies.rating;
      if (ratingCookie?.location !== webRatingAnsweres.location_id) {
        const response = await saveDetails({
          ...webRatingAnsweres,
          page: getDropoutPage().page,
          dropout_page: getDropoutPage().dropout_page,
        });
        if (response && response?.data?.metaData) {
          setCookie(
            "rating",
            {
              rating: "submited",
              location: response.data.metaData.location_id,
            },
            { path: "/", maxAge: 86400 }
          );
          props.resetFrontratingAnsweres_();
          setTimeout(() => {
            setisShowStep1Footer(true);
            setisShowStep2Footer(false);
            setisShowStep3Footer(false);
            setisShowStep4Footer(false);
            setselectedEmployeeID(null);
            setselectedEmployeeName(null);
            setselectedEmployeeImagePath(null);
            setdemoEmpImagePath(avatar_image);
            setselectedSkillIDs(null);
            setselectedSkillLabels(null);
            setstandout(0);
            setfeedback("");

            props.resetToIntitialChatDisp();
          }, 1000);
          history.push("/front/Ratting/new-thank-you-page");
        } else {
          toast.error(response?.message);
          history.push("/frontRating");
        }
      } else {
        toast.error("You have already submitted rating");
      }
    },

    debounce: 500,
  });

  const SelectEmployeeModal = () => {
    return (
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="mobile-view-modal"
          id="mobile-view-modal"
        >
          <DialogTitle id="mobile-view-modal-title">
            <div className="modal-header">
              <h5 className="mr-12 text-lg font-bold truncate">
                Select Employee
              </h5>
              {/* close button */}
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpen(false)}
                aria-label="Close"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </DialogTitle>

          <DialogContent id="mobile-view-modal-body">
            <div className="divide-y divide-gray-200">
              {employeeData.map((row, k) => {
                return (
                  <div
                    key={k}
                    className="flex items-center justify-between px-3 py-3 bg-white "
                  >
                    <label
                      htmlFor={`emp-${k}`}
                      className="flex items-center flex-1 text-base font-medium"
                    >
                      <img
                        className="object-cover w-8 h-8 rounded-full"
                        // src={user_image}
                        src={row.image}
                        alt="emp_image"
                      />
                      <span className="ml-3 font-medium">{row.name}</span>
                    </label>
                    <input
                      defaultValue={row.id}
                      type="radio"
                      id={`emp-${k}`}
                      name="employee"
                      onFocus={setRingColor(true)}
                      style={{
                        color: ringColor ? theme.palette.primary.main : "",
                      }}
                      className="w-5 h-5 border-gray-300 rounded-full"
                    />
                  </div>
                );
              })}
            </div>
          </DialogContent>
          <DialogActions id="mobile-view-modal-footer">
            <div className="flex items-center justify-center space-x-4">
              <button
                style={{
                  paddingTop: "0.75rem",
                  paddingBottom: "0.75rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                type="button"
                onClick={() => setOpen(false)}
                className="flex items-center justify-center flex-1 w-full text-base font-semibold text-gray-600 transition-all duration-200 bg-gray-300 rounded-full shadow-lg hover:text-white focus:text-white hover:bg-gray-600 focus:bg-gray-600"
              >
                Discard
              </button>
              <button
                style={{
                  backgroundColor: theme.palette.primary.main,
                  paddingTop: "0.75rem",
                  paddingBottom: "0.75rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                type="button"
                onClick={(e) => getSelectedEmpDetail(e)}
                className="flex items-center justify-center flex-1 w-full text-base font-semibold text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
              >
                Save
              </button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const SelectSkillModal = () => {
    return (
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="mobile-view-modal"
          id="mobile-view-modal"
        >
          <DialogTitle id="mobile-view-modal-title">
            <div className="modal-header">
              <h5 className="mr-12 text-lg font-bold truncate">Select Skill</h5>
              {/* close button */}
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpen(false)}
                aria-label="Close"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </DialogTitle>

          <DialogContent id="mobile-view-modal-body">
            <div className="divide-y divide-gray-200">
              {skillData.map((row, k) => {
                return (
                  <div
                    key={k}
                    className="flex items-center justify-between px-3 py-3 bg-white"
                  >
                    <label
                      htmlFor={`skill-${k}`}
                      className="flex-1 block text-base font-medium"
                    >
                      {row.name}
                    </label>
                    <input
                      type="checkbox"
                      id={`skill-${k}`}
                      name="selectedSkills"
                      className="w-5 h-5 border-gray-300 rounded-full"
                      onFocus={setRingColor(true)}
                      style={{
                        color: ringColor ? theme.palette.primary.main : "",
                      }}
                      defaultValue={row._id + "_" + row.name}
                    />
                  </div>
                );
              })}
            </div>
          </DialogContent>
          <DialogActions id="mobile-view-modal-footer">
            <div className="flex items-center justify-center space-x-4">
              <button
                style={{
                  paddingTop: "0.75rem",
                  paddingBottom: "0.75rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                type="button"
                onClick={() => setOpen(false)}
                className="flex items-center justify-center flex-1 w-full  text-base font-semibold text-gray-600 transition-all duration-200 bg-gray-300 rounded-full shadow-lg hover:text-white focus:text-white hover:bg-gray-600 focus:bg-gray-600"
              >
                Discard
              </button>
              <button
                style={{
                  backgroundColor: theme.palette.primary.main,
                  paddingTop: "0.75rem",
                  paddingBottom: "0.75rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                type="button"
                onClick={(e) => getSelectedSkillData(e)}
                className="flex items-center justify-center flex-1 w-full text-base font-semibold text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
              >
                Save
              </button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  if (rating !== null && rating > 3) {
    return (
      <React.Fragment>
        {!webRatingData.hide_team ? (
          <div className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30">
            <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
              <img
                className="object-cover w-full h-full"
                src={user_image}
                alt="user_demo"
              />
            </div>
            <div
              className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
              style={{ backgroundColor: theme.palette.primary.main }}
            >
              <div
                style={{
                  paddingTop: " 0.75rem",
                  paddingBottom: " 0.75rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
              >
                <span className="block leading-tight">
                  We'd love to know who looked after you today?
                </span>
              </div>
            </div>
          </div>
        ) : null}
        {isShowStep1Footer ? (
          <div className="ajax-div-question-two-positive">
            <div className="fixed bottom-0 z-10 w-full max-w-lg mx-auto -translate-x-1/2 left-1/2">
              <div className="px-4 bg-white border-t border-gray-200">
                <div className="flex items-center space-x-3 h-18">
                  <button
                    onClick={(e) => setOpen(true)}
                    type="button"
                    className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
                    style={{ color: theme.palette.primary.main }}
                    data-toggle="modal"
                    data-target="#selectEmployee"
                  >
                    <svg
                      className="w-5 h-5 mr-3"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Select Employee
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center flex-shrink-0 px-5 py-3 text-sm font-medium leading-4 text-gray-700 transition-all duration-200 bg-white border border-gray-300 rounded-full shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
                    onClick={(e) => SkipEmployeeSelection()}
                  >
                    Skip
                  </button>
                </div>
              </div>
            </div>
            <SelectEmployeeModal />
          </div>
        ) : (
          <div>
            {!webRatingData.hide_team ? (
              <div className="flex flex-row-reverse items-end justify-start pl-20 transition-all duration-200 m-t-30">
                <div className="text-black bg-white rounded-br-none shadow rounded-2xl">
                  <div className="flex items-center justify-end px-4 py-3">
                    {selectedEmployeeID !== null ? (
                      <>
                        <img
                          className="object-cover w-8 h-8 rounded-full"
                          src={
                            selectedEmployeeImagePath !== null
                              ? selectedEmployeeImagePath
                              : demoEmpImagePath
                          }
                          alt="true"
                        />
                        <span className="ml-3 font-medium">
                          {selectedEmployeeName}
                        </span>
                      </>
                    ) : (
                      <>
                        <img
                          className="object-cover w-8 h-8 rounded-full"
                          src={avatar_image}
                          alt="true"
                        />
                        <span className="ml-3 font-medium">None Selected</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30">
              <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
                <img
                  className="object-cover w-full h-full"
                  src={user_image}
                  alt="true"
                />
              </div>
              <div
                className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
                style={{ backgroundColor: theme.palette.primary.main }}
              >
                <div
                  style={{
                    paddingTop: " 0.75rem",
                    paddingBottom: "0.75rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                >
                  <span className="block leading-tight">
                    What stood out to you today?
                  </span>
                </div>
              </div>
            </div>
            {isShowStep2Footer === true ? (
              <div className="ajax-div-question-three-positive">
                <div className="fixed bottom-0 z-10 w-full max-w-lg mx-auto -translate-x-1/2 left-1/2">
                  <div className="px-4 bg-white border-t border-gray-200">
                    <div className="flex items-center space-x-3 h-18">
                      <button
                        onClick={() => setOpen(true)}
                        type="button"
                        className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
                        style={{ color: theme.palette.primary.main }}
                        data-toggle="modal"
                        data-target="#selectSkills"
                      >
                        <svg
                          className="w-5 h-5 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                          <path
                            fillRule="evenodd"
                            d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Select
                      </button>
                      <button
                        onClick={() => skipSelectSkill()}
                        type="button"
                        className="inline-flex items-center flex-shrink-0 px-5 py-3 text-sm font-medium leading-4 text-gray-700 transition-all duration-200 bg-white border border-gray-300 rounded-full shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
                      >
                        Skip
                      </button>
                    </div>
                  </div>
                </div>
                <SelectSkillModal />
              </div>
            ) : (
              <div>
                <div className="flex flex-row-reverse items-end justify-start pl-20 transition-all duration-200 m-t-30">
                  <div className="text-black bg-white rounded-br-none shadow rounded-2xl">
                    <div className="flex flex-wrap items-center gap-3 px-4 py-3">
                      {selectedSkillArr.length > 0 ? (
                        selectedSkillArr.map((label, k) => {
                          return (
                            <span
                              key={k}
                              className="text-center inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800"
                            >
                              {label}
                            </span>
                          );
                        })
                      ) : (
                        <span className="text-center inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                          None Selected
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30 "
                  style={{ marginBottom: "30px" }}
                >
                  <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
                    <img
                      className="object-cover w-full h-full"
                      src={user_image}
                      alt="true"
                    />
                  </div>
                  <div
                    className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
                    style={{ backgroundColor: theme.palette.primary.main }}
                  >
                    <div
                      style={{
                        paddingTop: " 0.75rem",
                        paddingBottom: " 0.75rem",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                      }}
                    >
                      <span className="block leading-tight">
                        Did we provide exceptional service today?
                      </span>
                    </div>
                  </div>
                </div>

                {isShowStep3Footer === true ? (
                  <div className="ajax-div-question-four-positive ">
                    <div className="fixed bottom-0 z-10 w-full max-w-lg mx-auto -translate-x-1/2 left-1/2">
                      <div className="px-4 bg-white border-t border-gray-200">
                        <div className="flex items-center space-x-3 h-18">
                          <button
                            type="button"
                            className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
                            style={{ color: theme.palette.primary.main }}
                            onClick={() => SubmitStandout(1)}
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
                            style={{ color: theme.palette.primary.main }}
                            onClick={() => SubmitStandout(0)}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="inline-flex items-center flex-shrink-0 px-5 py-3 text-sm font-medium leading-4 text-gray-700 transition-all duration-200 bg-white border border-gray-300 rounded-full shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
                            onClick={() => SubmitStandout(0)}
                          >
                            Skip
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="flex flex-row-reverse items-end justify-start pl-20 transition-all duration-200 m-t-30">
                      <div className="text-black bg-white rounded-br-none shadow rounded-2xl">
                        <div className="flex items-center justify-end px-4 py-3">
                          <span className="ml-3 font-medium">
                            {is_standout === 1 ? "Yes" : "No"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30"
                      style={{ marginBottom: "30px" }}
                    >
                      <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
                        <img
                          className="object-cover w-full h-full"
                          src={user_image}
                          alt="true"
                        />
                      </div>
                      <div
                        className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
                        style={{ backgroundColor: theme.palette.primary.main }}
                      >
                        <div
                          style={{
                            paddingTop: " 0.75rem",
                            paddingBottom: " 0.75rem",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <span className="block leading-tight">
                            Do you have any comments or feedback about your
                            experience with us today?
                          </span>
                        </div>
                      </div>
                    </div>
                    {isShowStep4Footer === true ? (
                      <div className="thank-you">
                        <div className="fixed z-10 w-full max-w-lg px-4 mx-auto -translate-x-1/2 left-1/2 bottom-5">
                          <form
                            method="POST"
                            className="flex items-center justify-center space-x-3"
                          >
                            <input
                              type="text"
                              value={feedback}
                              onChange={(e) => {
                                setfeedback(e.target.value);
                                setFrontratingAnsweres_({
                                  key: "feedback",
                                  value: e.target.value,
                                });
                              }}
                              id="feedback"
                              placeholder="Write here..."
                              className="block w-full px-4 py-4 text-base text-black transition-all duration-200 bg-white border border-transparent rounded-full shadow-lg caret-purple-600 focus:border-purple-600 focus:ring-purple-600 focus:outline-none"
                            />
                            <button
                              onClick={(e) => submitFeedback(e)}
                              type="submit"
                              className="flex items-end justify-center flex-shrink-0 p-4 text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
                              style={{
                                backgroundColor: theme.palette.primary.main,
                              }}
                            >
                              <svg
                                className="w-6 h-6 rotate-90"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                              </svg>
                            </button>
                          </form>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="flex flex-row-reverse items-end justify-start pl-20 transition-all duration-200 m-t-30">
                          <div className="text-black bg-white rounded-br-none shadow rounded-2xl">
                            <div className="flex items-center justify-end px-4 py-3">
                              <span className="ml-3 font-medium">
                                {feedback !== null ? feedback : "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30">
                          <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
                            <img
                              className="object-cover w-full h-full"
                              src={user_image}
                              alt="true"
                            />
                          </div>
                          <div
                            className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
                            style={{
                              backgroundColor: theme.palette.primary.main,
                            }}
                          >
                            <div
                              style={{
                                paddingTop: " 0.75rem",
                                paddingBottom: " 0.75rem",
                                paddingLeft: "1rem",
                                paddingRight: "1rem",
                              }}
                            >
                              <span className="block leading-tight">
                                Thank you
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  } else {
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    webRatingData: state.frontWebRatingDataReducer.webRatingData,
    webRatingAnsweres: state.frontWebRatingDataReducer.webRatingAnsweres,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFrontratingData_: (data) => dispatch(setFrontratingData(data)),
    setFrontratingAnsweres_: (data) => dispatch(setFrontratingAnsweres(data)),
    resetFrontratingAnsweres_: () => dispatch(resetFrontratingAnsweres()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PositiveChatReply);
