import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import EmojiImageHeaderFeedback from "./EmojiImageHeaderFeedback";
import CheckFrontLogin from "./CheckFrontLogin";
import axios from "axios";
import Footer from "./Footer";
import "../assets/css/FeedbackRatingCustomStyles.css";

function PositivefeedbackForm(props) {
  const history = useHistory();
  CheckFrontLogin();
  const employeeName = localStorage.getItem("employeeName");
  const employeeImagePath =
    localStorage.getItem("employeeImagePath") !== ""
      ? localStorage.getItem("employeeImagePath")
      : "/images/user.png";

  const [is_standout, setis_standout] = useState(0);
  const [feedback, setfeedback] = useState("");

  const [requireEmployeeID, setrequireEmployeeID] = useState(false);

  const saveFeedback = (e) => {
    e.preventDefault();
    setrequireEmployeeID(false);
    let form_data = new FormData();
    form_data.append("company_id", localStorage.getItem("front_company_id"));
    form_data.append("location_id", localStorage.getItem("front_location_id"));
    form_data.append("skills", localStorage.getItem("selectedPositiveSkill"));
    form_data.append("rating", localStorage.getItem("rating"));
    form_data.append("employees", localStorage.getItem("employeeID"));
    form_data.append("is_standout", is_standout);
    form_data.append("feedback", feedback);

   // if (localStorage.getItem("employeeID") !== null && localStorage.getItem("employeeID") !== "" ) {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      let url = "rating/create";
      axios
        .post(`${baseUrl}/${url}`, form_data,{headers: {'Content-Type'  : 'multipart/form-data'}})
        .then((res) => {
          if (res.status === 201) {            
            history.push("/front/Ratting/thank-you-for-feedback");
            localStorage.removeItem("selectedPositiveSkill");
            localStorage.removeItem("rating");
            localStorage.removeItem("employeeID");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    
    //  } else {
    //   setrequireEmployeeID(true);
    // }
  };

  const redirectPreviousTab = () => {
    history.push("/front/Ratting/positive-feedback-step-2");
  };

  return (
    <React.Fragment>
      <div className="wrapper has-fix-bar">
        <form method="post" onSubmit={saveFeedback} autoComplete="off">
          <section className="feedback-stepper-wrapper has-left-content">
            <div className="container">
              <div className="top-header-wrapper">
                <div className="experience-ans-tip">
                  {/* fetching rating from session  */}
                  <EmojiImageHeaderFeedback />
                </div>
                <div className="pagination-wrapper">
                  <span>3</span>/<span>3</span>
                </div>
              </div>
              <h1 className="h1-heading">
                Did {employeeName !== "" ? employeeName : "team"} stand out for
                delivering exceptional service?
              </h1>
              <div className="white-box about-instructor two-col-block">
                <div className="left-block">
                  <em
                    style={{
                      backgroundImage: `url(${employeeImagePath})`,
                      borderRadius: "50%",
                    }}
                  ></em>
                  <p>{employeeName !== "" ? employeeName : "Team"}</p>
                </div>
                <div className="right-block">
                  <p>
                    Did {employeeName !== "" ? employeeName : "team"} provide
                    exceptional service?{" "}
                  </p>
                  <ul className="radio-list">
                    <li>
                      <div className="custom-radio-outer secondary-radio web-rating-secondary-radio">
                        <input
                          type="radio"
                          name="is_standout"
                          defaultValue={1}
                          onChange={(e) => setis_standout(1)}
                        />
                        <label>Yes</label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-radio-outer secondary-radio web-rating-secondary-radio">
                        <input
                          type="radio"
                          defaultChecked="checked"
                          name="is_standout"
                          defaultValue={0}
                          onChange={(e) => setis_standout(0)}
                        />
                        <label>No</label>
                      </div>
                    </li>
                  </ul>
                  <p>
                    Do you have any comments or feedback about your experience
                    with us today?
                  </p>
                  <div className="form-group">
                    <textarea
                      className="form-control form-control-web-ratting"
                      placeholder="Share us your experience with us today..."
                      name="feedback"
                      id="feedback"
                      rows={4}
                      defaultValue={feedback}
                      onChange={(e) => setfeedback(e.target.value)}
                    />
                  </div>
                  {requireEmployeeID ? (
                    <span style={{ color: "red" }}>
                      Please go to previous step and select employee, without
                      employee selection not able to submit feedback form
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottom-fix-btnbar">
                <button
                  className="btn border-btn icon-btn sm rating-feedback-prev-btn"
                  title="Prev"
                  onClick={() => redirectPreviousTab()}
                  style={{ border: "1px solid #955275", padding: "0px" }}
                >
                  <em>
                    <svg
                      id="_32213"
                      data-name={32213}
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.519"
                      height="13.19"
                      viewBox="0 0 7.519 13.19"
                    >
                      <path
                        id="Path_71"
                        data-name="Path 71"
                        d="M97.409,7.248l5.672,5.671a.924.924,0,0,0,1.306-1.306L99.369,6.595l5.019-5.018A.924.924,0,0,0,103.081.27L97.409,5.942a.924.924,0,0,0,0,1.306Z"
                        transform="translate(-97.139 0)"
                        fill="#955275"
                      />
                    </svg>
                  </em>
                  Prev
                </button>
                <div className="pagination-wrapper">
                  <span>3</span>/<span>3</span>
                </div>
                <button
                  className="btn icon-btn icon-right sm"
                  type="submit"
                  name="submit"
                  style={{
                    backgroundColor: "#955275",
                    color: "#fff",
                    border: "1px solid #955275",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    lineHeight: "12px",
                  }}
                >
                  Complete
                  <em>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.519"
                      height="13.191"
                      viewBox="0 0 7.519 13.191"
                    >
                      <g
                        id="_32213"
                        data-name={32213}
                        transform="translate(-97.138 0)"
                      >
                        <path
                          id="Path_71"
                          data-name="Path 71"
                          d="M104.387,7.248,98.715,12.92a.924.924,0,1,1-1.306-1.306L102.428,6.6,97.409,1.577A.924.924,0,0,1,98.716.27l5.672,5.672a.924.924,0,0,1,0,1.306Z"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </em>
                </button>
              </div>
            </div>
          </section>
        </form>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default PositivefeedbackForm;
