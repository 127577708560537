import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import EmojiImageHeaderFeedback from "./EmojiImageHeaderFeedback";
import CheckFrontLogin from "./CheckFrontLogin";
import { postData } from "../components/FetchServicesWithoutJwtToken";
import Footer from "./Footer";
function Positivefeedback2(props) {
  const history = useHistory();
  CheckFrontLogin();

  const [skillData, setskillData] = useState([]);
  const readSkillRecords = async () => {
    const body = {
      companyId: localStorage.getItem("front_company_id"),
      locationId: localStorage.getItem("front_location_id"),
      type: "positive",
    };
    let result = await postData("front/getSkills", body);
    if (result) {
      setskillData(result);
    }
  };

  React.useEffect(() => {
    readSkillRecords();
  }, []);

  const employeeName = localStorage.getItem("employeeName");
  const employeeImagePath =
    localStorage.getItem("employeeImagePath") !== ""
      ? localStorage.getItem("employeeImagePath")
      : "/images/user.png";

  const [selectedSkill, setselectedSkill] = useState(
    localStorage.getItem("selectedPositiveSkill")
      ? localStorage.getItem("selectedPositiveSkill").split(",")
      : []
  );
  const getSelectedSkill = (e) => {
    if (e.target.checked) {
      setselectedSkill([...selectedSkill, e.target.value]);
    } else {
      setselectedSkill(selectedSkill.filter((id) => id !== e.target.value));
    }
  };
  let localStorageSelectedSkill = localStorage.getItem("selectedPositiveSkill")
    ? localStorage.getItem("selectedPositiveSkill")
    : "";
  let localSelectedSkill =
    localStorageSelectedSkill !== ""
      ? localStorageSelectedSkill.split(",")
      : [];
      
  const gotoNextPage = () => {
    localStorage.setItem("selectedPositiveSkill", selectedSkill);
    history.push("/front/Ratting/positive-feedback-step-3");
  };

  const redirectPreviousTab = () => {
    history.push("/front/Ratting/positive-feedback-step-1");
  };

  return (
    <React.Fragment>
      <div className="wrapper has-fix-bar">
        <section className="feedback-stepper-wrapper has-left-content">
          <div className="container">
            <div className="top-header-wrapper">
              <div className="experience-ans-tip">
                {/* fetching rating from session  */}
                <EmojiImageHeaderFeedback />
              </div>
              <div className="pagination-wrapper">
                <span>2</span>/<span>3</span>
              </div>
            </div>
            <h1 className="h1-heading">
              What made the {employeeName !== "" ? employeeName : "team"} stand
              out to you?
            </h1>
            <div className="white-box instructor-description two-col-block">
              <div className="left-block">
                <em
                  style={{
                    backgroundImage: `url(${employeeImagePath})`,
                    borderRadius: "50%",
                  }}
                ></em>
                <p>{employeeName !== "" ? employeeName : "Team"}</p>
              </div>
              <h2>
                What made the {employeeName !== "" ? employeeName : "team"}{" "}
                stand out to you?
              </h2>
              {/* <h2>What made our instructor stand out to you?</h2> */}
              <form method="POST" id="form_skills">
                <div className="right-block">
                  <ul>
                    {skillData.map((rowdata, key) => {
                      return (
                        <li key={key}>
                          <div className="custom-radio-outer custom-checkbox">
                            <input
                              id={`skill-id-${key}`}
                              type="checkbox"
                              defaultValue={rowdata._id}
                              onChange={getSelectedSkill}
                              defaultChecked={
                                localSelectedSkill.filter(
                                  (id) => id === rowdata._id
                                ).length > 0
                                  ? true
                                  : false
                              }
                            />
                            <label className="web-rating-custom-checkbox-label">
                              {rowdata.name}
                            </label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </form>
            </div>
            <div className="bottom-fix-btnbar">
              <button
                className="btn border-btn icon-btn sm rating-feedback-prev-btn"
                title="Prev"
                onClick={() => redirectPreviousTab()}
                style={{ border: "1px solid #955275", padding: "0px" }}
              >
                <em>
                  <svg
                    id="_32213"
                    data-name={32213}
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.519"
                    height="13.19"
                    viewBox="0 0 7.519 13.19"
                  >
                    <path
                      id="Path_71"
                      data-name="Path 71"
                      d="M97.409,7.248l5.672,5.671a.924.924,0,0,0,1.306-1.306L99.369,6.595l5.019-5.018A.924.924,0,0,0,103.081.27L97.409,5.942a.924.924,0,0,0,0,1.306Z"
                      transform="translate(-97.139 0)"
                      fill="#955275"
                    />
                  </svg>
                </em>
                Prev
              </button>
              <div className="pagination-wrapper">
                <span>2</span>/<span>3</span>
              </div>
              <button
                onClick={() => gotoNextPage()}
                id="submit"
                className="btn icon-btn icon-right sm"
                title="Next"
                style={{
                  backgroundColor: "#955275",
                  color: "#fff",
                  border: "1px solid #955275",
                  padding: "0px",
                }}
              >
                Next
                <em>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.519"
                    height="13.191"
                    viewBox="0 0 7.519 13.191"
                  >
                    <g
                      id="_32213"
                      data-name={32213}
                      transform="translate(-97.138 0)"
                    >
                      <path
                        id="Path_71"
                        data-name="Path 71"
                        d="M104.387,7.248,98.715,12.92a.924.924,0,1,1-1.306-1.306L102.428,6.6,97.409,1.577A.924.924,0,0,1,98.716.27l5.672,5.672a.924.924,0,0,1,0,1.306Z"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </em>
              </button>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </React.Fragment>
  );
}
export default Positivefeedback2;
