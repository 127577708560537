import React from "react";
import Progress from "../../../common/components/Progress";
import Button from "../../../common/components/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import BottomProgress from "../../../common/components/BottomProgress";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    marginTop: "auto",
  },
}));

function ButtonProgress(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div style={{ flex: "0 0 auto" }}>
        <Button
          leftIcon={<ArrowBackIcon />}
          onClick={props.handlePrev}
          isDisable={props.PrevisDisable}
        />
      </div>

      <div style={{ flex: "1 1 auto", padding: "0 20px" }}>
        {/* <Progress {...props} /> */}
        <BottomProgress {...props} />
      </div>
      <div style={{ flex: "0 0 auto" }}>
        <Button
          title={props.nextTitle}
          onClick={props.handleNext}
          isDisable={props.submitDisabled}
        />
      </div>
    </div>
  );
}

export default ButtonProgress;
