import React from "react";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { addAnswer, clearAnsweres } from "../../../../../redux/survey/actions";
import Upload from "../../../Questions/Upload";
import "./clientSurvey.css";
import CheckboxOptions from "./options/CheckboxOptions";
import EmojiOptions from "./options/EmojiOptions";
import RadioOptions from "./options/RadioOptions";
import RatingOptions from "./options/RatingOptions";
import TextArea from "./options/TextArea";

export const QUESTION_TYPES = {
  MULTI: "multiselect",
  RADIO: "radio",
  TEXT: "text",
  RATING: "rating",
  EMPLOYEES: "employees",
  SEQUENCE: "sequence",
  FILE: "file",
  EMOJI: "emoji",
  SKILLS: "skills",
};

const ClientSurvey = ({ survey, addAnswer_, answers, surveyErrors, onSurveyFinish }) => {
  const handleInput = (value, id, type, finish = false) => {
    addAnswer_({ id, value, type });
    if (finish) {
      onSurveyFinish();
    }
  };

  return (
    <div className="question-card px-2 px-md-4 py-4 overflow-auto">
      <div className="text-center" style={{ fontSize: "2rem", fontWeight: "bold" }}>
        Please give us your feedback
      </div>

      {survey.description ? (
        <div className="text-center" style={{ all: "initial", fontFamily: "inherit" }}>
          {ReactHtmlParser(survey.description)}
        </div>
      ) : null}

      <div className="mt-4 pt-2 d-flex flex-column flex-lg-row flex-wrap justify-content-start align-items-start">
        {survey?.questions?.length
          ? survey.questions
              ?.filter((que) => que.questionType !== QUESTION_TYPES.EMPLOYEES)
              ?.map((question) => (
                <div key={question._id} className="px-4 py-3 mb-2 single-question text-align-center">
                  <div style={{ fontSize: "1.25rem", fontWeight: "bold" }}>{question.category}</div>
                  <div>{question.text}</div>

                  {/* Options */}
                  <div className={`${question.questionType !== QUESTION_TYPES.FILE ? "mt-3" : ""}`}>
                    {/* Rating Question */}
                    {question.questionType === QUESTION_TYPES.RATING ? (
                      <RatingOptions
                        error={surveyErrors[question._id]}
                        options={question.options}
                        answer={answers[question._id]}
                        onClick={(value, finish) => handleInput(value, question._id, "id", finish)}
                      />
                    ) : null}

                    {/* Radio Question */}
                    {question.questionType === QUESTION_TYPES.RADIO ? (
                      <RadioOptions
                        error={surveyErrors[question._id]}
                        options={question.options}
                        answer={answers[question._id]}
                        onClick={(value, finish) => handleInput(value, question._id, "id", finish)}
                      />
                    ) : null}

                    {/* Text Question */}
                    {question.questionType === QUESTION_TYPES.TEXT ? (
                      <TextArea
                        error={surveyErrors[question._id]}
                        answer={answers[question._id]}
                        onChange={(value) => handleInput(value, question._id, "text")}
                      />
                    ) : null}

                    {/* Checkbox Question */}
                    {[QUESTION_TYPES.MULTI, QUESTION_TYPES.SEQUENCE, QUESTION_TYPES.SKILLS].includes(
                      question.questionType
                    ) ? (
                      <CheckboxOptions
                        error={surveyErrors[question._id]}
                        options={question.options}
                        sequence={question.questionType === QUESTION_TYPES.SEQUENCE}
                        answer={answers[question._id]}
                        onClick={(value) => handleInput(value, question._id, "id")}
                      />
                    ) : null}

                    {/* Emoji Question */}
                    {question.questionType === QUESTION_TYPES.EMOJI ? (
                      <EmojiOptions
                        error={surveyErrors[question._id]}
                        options={question.options}
                        answer={answers[question._id]}
                        onClick={(value, finish) => handleInput(value, question._id, "id", finish)}
                      />
                    ) : null}

                    {/* File Question */}
                    {question.questionType === QUESTION_TYPES.FILE ? (
                      <>
                        <Upload question={question} />
                        {surveyErrors[question._id] ? (
                          <div className="mt-2 small font-weight-bold text-danger">
                            {surveyErrors[question._id].message}
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </div>
              ))
          : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    answers: state.survey.answeres,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientSurvey);
