import React, { useEffect, useState } from "react";
import {
  addAnswer,
  addNote,
  addWriteIn,
} from "../../../../redux/survey/actions";
import { makeStyles } from "@material-ui/core/styles";
import { Box, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import { connect } from "react-redux";
import { DatePicker } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import moment from "moment";
import { countries, generateCountryOptions } from "../../../helpers/country";
import { SelectPicker, InputGroup, Input } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import "./textArea.css";
// Custom styles for Material-UI components
const useStyles = makeStyles((theme) => ({
  textfield: {
    width: "100%",
    borderBlockColor: theme.palette.primary.light,
  },
  input1: {
    borderColor: theme.palette.primary.light,
    color: "black",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));

function TextArea(props) {
  // Destructure props for easier access
  const {
    question,
    answeres,
    addAnswer_,
    addNote_,
    addWriteIn_,
    type,
    isGreyOut,
    styles,
  } = props;
  const theme = useTheme(); // Accessing current theme using useTheme hook
  const isMob = useMediaQuery(theme.breakpoints.down("sm")); // Checking if the screen size is mobile using useMediaQuery hook
  const classes = useStyles();
  // Function to handle text input
  const handleText = (text) => {
    if (type === "note") {
      // Add note to Redux state
      addNote_({ id: question._id, note: text.target.value, type: "text" });
    } else if (type === "write_in") {
      // Add comments to Redux state
      addWriteIn_({
        id: question._id,
        write_in: text.target.value,
        type: "text",
      });
    } else {
      if (question?.textType === "phone") {
        const selectedCode =
          countries.find((c) => c?.code === selectedCountry)?.phone || "";
        // Add answer to Redux state
        addAnswer_({
          id: question._id,
          value: text,
          type: "text",
          code: selectedCode,
        });
      } else {
        // Add answer to Redux state
        addAnswer_({
          id: question._id,
          value: text.target.value,
          type: "text",
        });
      }
    }
  };

  let answer = answeres[question._id] ?? { value: "" }; // Extracting answer from Redux store
  const label =
    type === "write_in" ? "Enter your comment*" : "Enter your comment"; // Label for text input

  // State for selected date and time
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  // Function to load data from Redux store when component mounts
  const loadData = () => {
    if (question._id in answeres) {
      if (question?.textType === "date" && answer?.value) {
        let prevAnswer = answeres[question._id] ?? { value: null };
        // Split the date string into day, month, and year
        const dateParts = prevAnswer.value.split("/");

        // Create a new Date object using the correct format
        const selectedDate = new Date(
          parseInt(dateParts[2], 10), // year
          parseInt(dateParts[1], 10) - 1, // month (0-based index)
          parseInt(dateParts[0], 10) // day
        );

        setSelectedDate(selectedDate);
      }
      if (question?.textType === "time" && answer?.value) {
        let prevAnswer = answeres[question._id] ?? { value: null };

        // Split the time string into hours, minutes, and seconds
        const timeParts = prevAnswer.value.split(":");

        // Create a new Date object and set only the time part
        const selectedTime = new Date();
        selectedTime.setHours(parseInt(timeParts[0], 10) || 0); // hours
        selectedTime.setMinutes(parseInt(timeParts[1], 10) || 0); // minutes
        selectedTime.setSeconds(parseInt(timeParts[2], 10) || 0); // seconds

        setSelectedTime(selectedTime);
      }
      if (answer?.code) {
        const selectedCode =
          countries.find((c) => c?.phone === answer?.code)?.code || "";
        setSelectedCountry(selectedCode);
      }
    }
  };
  useEffect(() => {
    loadData(); // Load data from Redux store when component mounts
  }, []);

  // Function to handle date selection
  const handleDateSelect = (date) => {
    setSelectedDate(date);
    addAnswer_({
      id: question._id,
      value: moment(date).format("DD/MM/YYYY"),
      type: "text",
    });
  };

  // Function to handle time selection
  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    addAnswer_({
      id: question._id,
      value: moment(time).format("HH:mm"),
      type: "text",
    });
  };
  // State for selected country
  const [selectedCountry, setSelectedCountry] = useState("GB");

  // Function to handle country selection
  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    const selectedCode = countries.find((c) => c?.code === value)?.phone || "";
    addAnswer_({
      id: question._id,
      value: answer.value,
      type: "text",
      code: selectedCode,
    });
  };

  return (
    <Box style={{ cursor: isGreyOut && "not-allowed" }}>
      {/* Render different input components based on question type */}
      {question?.textType === "date" && type !== "note" ? (
        <DatePicker
          format="DD/MM/YYYY"
          ranges={[]}
          value={selectedDate}
          onChange={handleDateSelect}
          className="p-0 border-0"
          block
          style={{ color: "darkgray", fontSize: isMob && "14px" }}
          disabledDate={(date) => date && date > new Date()}
        />
      ) : question?.textType === "time" && type !== "note" ? (
        <DatePicker
          format="HH:mm"
          ranges={[]}
          value={selectedTime}
          onChange={handleTimeSelect}
          className="form-control p-0 border-0"
          block
          style={{ color: "darkgray", fontSize: isMob && "14px" }}
        />
      ) : question?.textType === "email" && type !== "note" ? (
        <TextField
          disabled={isGreyOut}
          style={styles}
          className={classes.textfield}
          id="outlined-basic"
          value={
            type === "note"
              ? answer?.note
              : type === "write_in"
              ? answer?.write_in
              : answer?.value
          }
          onChange={handleText}
          size={isMob ? "small" : "medium"}
          label={label}
          placeholder={type === "note" ? "" : label}
          variant="outlined"
          InputProps={{ classes: { input: classes.input1 } }}
          InputLabelProps={{
            style: {
              color: "darkgray",
              fontSize: isMob && "14px",
            },
          }}
        />
      ) : question?.textType === "phone" && type !== "note" ? (
        <InputGroup>
          <SelectPicker
            data={generateCountryOptions(countries)}
            placeholder="Select Country"
            value={selectedCountry}
            onChange={handleCountryChange}
            cleanable={false}
          />
          <Input
            disabled={isGreyOut}
            placeholder="Phone Number"
            value={answer?.value}
            onChange={handleText}
            type="number"
          />
        </InputGroup>
      ) : (
        <TextField
          disabled={isGreyOut}
          style={styles}
          minRows={3}
          multiline
          className={classes.textfield}
          id="outlined-basic"
          value={
            type === "note"
              ? answer?.note
              : type === "write_in"
              ? answer?.write_in
              : answer?.value
          }
          onChange={handleText}
          size={isMob ? "small" : "medium"}
          label={label}
          placeholder={type === "note" ? "" : label}
          variant="outlined"
          InputProps={{ classes: { input: classes.input1 } }}
          InputLabelProps={{
            style: {
              color: "darkgray",
              fontSize: isMob && "14px",
            },
          }}
        />
      )}
    </Box>
  );
}
// Map Redux state to component props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Map Redux dispatch functions to component props
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    addNote_: (data) => dispatch(addNote(data)),
    addWriteIn_: (data) => dispatch(addWriteIn(data)),
  };
};
// Connect component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(TextArea);
