import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  loginImage: {
    display: "flex",
    justifyContent: "center",
  },
  backgroundImg: {
    background: `linear-gradient(0deg, rgba(48, 48, 56, 0.7), rgba(48, 48, 56, 0.7)), url("images/webRating/bg.jpg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  checkimage: {
    width: "12vw",
  },
  thankyouText: {
    fontFamily: "Inter",
    fontSize: "2vw",
    fontWeight: 400,
    lineHeight: "4.5vw",
    color: "white",
    textAlign: "center",
    // width: "60%",
    margin: "auto",
    marginTop: "1%",
  },
  container: {
    margin: "auto",
    textAlign: "center",
  },
  googleText: {
    fontFamily: "Inter",
    fontSize: "1.5rem",
    fontWeight: 400,
    color: "white",
    textAlign: "center",
    marginTop: "3rem",
  },
  googleReviewContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  googleReviewButton: {
    userSelect: "none",
    fontSize: "1.65rem",
    fontWeight: 500,
    textDecoration: "none",
    width: "max-content",
    marginTop: "1rem",
    border: "1px solid gainsboro",
    borderRadius: "40px",
    padding: "0.60rem 2.5rem 0.65rem 1.5rem",
    cursor: "pointer",
    backgroundColor: "white",
    color: "black",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "whitesmoke",
      color: "black",
    },
    "&:focus": {
      backgroundColor: "whitesmoke",
      color: "black",
    },
    "&:active": {
      backgroundColor: "gainsboro",
      color: "black",
    },
  },
}));
