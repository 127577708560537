import React from "react";
import ClientConnectDirector from "../../../ClientConnectDirector";
import { addDirectorMessage } from "../../../../../redux/survey/actions";
import { connect } from "react-redux";

const ClientDirector = ({ survey, directorMessage, addDirectorMessage_ }) => {
  return (
    <div className="question-card px-4 px-md-5 py-4 overflow-auto">
      <ClientConnectDirector
        value={directorMessage}
        onChange={(e) => addDirectorMessage_(e.target.value)}
        clientConnect={survey.clientConnect}
        leadershipUser={survey.leadershipUser}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    directorMessage: state.survey.directorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDirectorMessage_: (data) => dispatch(addDirectorMessage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientDirector);
