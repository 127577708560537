import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import EmojiImageHeaderFeedback from "./EmojiImageHeaderFeedback";
import CheckFrontLogin from "./CheckFrontLogin";
import { postData } from "../components/FetchServicesWithoutJwtToken";
import Footer from "./Footer";
import "../assets/css/FeedbackRatingCustomStyles.css";
import user_image from '../assets/images/web_rating/user.png';

function Positivefeedback1(props) {
  const history = useHistory();
  CheckFrontLogin();

  const [employeeData, setemployeeData] = useState([]);
  const readEmployeeRecords = async () => {
    const body = {
      companyId: localStorage.getItem("front_company_id"),
      locationId: localStorage.getItem("front_location_id"),
      type: "employee,location_manager",
    };
    let result = await postData("front/getEmployee", body);
    if (result) {
      setemployeeData(result);
    }
  };

  React.useEffect(() => {
    readEmployeeRecords();
  },[])
  // check if to show the employee page or to skip it
  
  if (localStorage.getItem("hide_team") === '1')
  {
    history.push('/front/Ratting/positive-feedback-step-2')
  }

  const getEmployeeDetail = (row) => {
    if (row !== undefined) {
      let demoImagePath = row.demoImagePath;

      localStorage.setItem("employeeImagePath", demoImagePath);
      localStorage.setItem("employeeID", row.employeeData._id);
      localStorage.setItem("employeeName", row.employeeData.name);
    } else {
      localStorage.setItem("employeeImagePath", "");
      localStorage.setItem("employeeID", "");
      localStorage.setItem("employeeName", "");
    }

    history.push("/front/Ratting/positive-feedback-step-2");
  };

  const redirectPreviousTab = () => {
    history.push("/front/home");
  };
  return (
    <React.Fragment>
      <div className="wrapper has-fix-bar">
        <section className="feedback-stepper-wrapper">
          <div className="container">
            <div className="top-header-wrapper">
              <div className="experience-ans-tip">
                {/* fetching rating from session  */}
                <EmojiImageHeaderFeedback />
              </div>
              <div className="pagination-wrapper">
                <span>1</span>/<span>3</span>
              </div>
            </div>
            <h1 className="h1-heading">
              We'd love to know who looked after you today?
            </h1>
            <div className="person-slider-wrapper slick-initialized slick-slider">
              <div className="slick-list draggable">
                <div
                  className="slick-track"
                  style={{
                    opacity: 1,
                    width: 1140,
                    transform: "translate3d(0px, 0px, 0px)",
                  }}
                >
                  {employeeData.map((rowdata, key) => {
                    let imagepath =
                      "https://sf-ratings-profile-image.s3.ap-south-1.amazonaws.com/employee/" +
                      rowdata.image;
                    let demoImagePath = user_image;
                    let tempData = {
                      imagepath: imagepath,
                      demoImagePath: demoImagePath,
                      employeeData: rowdata,
                    };

                    return (
                      <div
                        key={key}
                        className="slick-slide slick-current slick-active"
                        data-slick-index={0}
                        aria-hidden="false"
                        tabIndex={0}
                        style={{ width: 228 }}
                      >
                        <div>
                          <div
                            className="slide-item"
                            onClick={() => getEmployeeDetail(tempData)}
                            style={{ width: "100%", display: "inline-block" }}
                          >
                            <div className="custom-radio-outer">
                              <em>
                                <img src={demoImagePath} alt="image1" />
                              </em>                              
                              <input type="radio" name="person" tabIndex={0} />
                              <label style={{ height: 20 }}>
                                {rowdata.name}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}                  
                </div>
              </div>
            </div>
            <div className="bottom-fix-btnbar">
              <button
                className="btn border-btn icon-btn sm rating-feedback-prev-btn"
                title="Prev"
                onClick={() => redirectPreviousTab()}
                style={{ border: "1px solid #955275", padding: "0px" }}
              >
                <em>
                  <svg
                    id="_32213"
                    data-name={32213}
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.519"
                    height="13.19"
                    viewBox="0 0 7.519 13.19"
                  >
                    <path
                      id="Path_71"
                      data-name="Path 71"
                      d="M97.409,7.248l5.672,5.671a.924.924,0,0,0,1.306-1.306L99.369,6.595l5.019-5.018A.924.924,0,0,0,103.081.27L97.409,5.942a.924.924,0,0,0,0,1.306Z"
                      transform="translate(-97.139 0)"
                      fill="#955275"
                    />
                  </svg>
                </em>
                Prev
              </button>
              <div className="pagination-wrapper">
                <span>1</span>/<span>3</span>
              </div>
              <button
                onClick={() => getEmployeeDetail()}
                className="btn icon-btn icon-right sm"
                title="Next"
                style={{
                  backgroundColor: "#955275",
                  color: "#fff",
                  border: "1px solid #955275",
                  padding: "0px",
                }}
              >
                Next
                <em>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.519"
                    height="13.191"
                    viewBox="0 0 7.519 13.191"
                  >
                    <g
                      id="_32213"
                      data-name={32213}
                      transform="translate(-97.138 0)"
                    >
                      <path
                        id="Path_71"
                        data-name="Path 71"
                        d="M104.387,7.248,98.715,12.92a.924.924,0,1,1-1.306-1.306L102.428,6.6,97.409,1.577A.924.924,0,0,1,98.716.27l5.672,5.672a.924.924,0,0,1,0,1.306Z"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </em>
              </button>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </React.Fragment>
  );
}
export default Positivefeedback1;
