import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  box: {
    position: "relative", // Position relative to allow absolute positioning of the progress bar
    width: "100%",
    height: "5px",
    backgroundColor: "silver",
    borderRadius: "5px",
    overflow: "hidden",
  },
  progress: {
    position: "absolute", // Absolute positioning to overlap the full grey background
    top: 0,
    left: 0,
    height: "100%",
    backgroundColor: "#404040",
    borderRadius: "5px 0 0 5px", // Rounded corners for the progress bar
    transition: "width 0.3s ease", // Smooth transition for width change
  },
}));
function BottomProgress(props) {
  const { answered = 0, total = 0 } = props;
  const classes = useStyles(props);

  const progressWidth =
    answered > 0 && total > 0 ? (answered / total) * 100 : 0;

  return (
    <div className={classes.box}>
      <div
        className={classes.progress}
        style={{ width: `${progressWidth}%` }}
      ></div>
    </div>
  );
}

export default BottomProgress;
