import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    // minHeight: "100vh",
  },
  headingBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px 20px",
  },
  heading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "35px",
    display: "flex",
    textAlign: "center",
  },
  subHeading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    textAlign: "center",
    color: "#B9B8B8",
  },
  root: {
    flexGrow: 1,
    padding: "10px 100px",
  },
  mainheading: {
    fontFamily: "Inter",
    fontSize: "50px",
    fontWeight: 700,
    display: "flex",
    textAlign: "center",
    lineHeight: "60.51px",
    paddingBottom: "10px",
  },
  para: {
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 400,
    display: "flex",
    lineHeight: "31.31px",
    color: "#B9B8B8",
    textAlign: "center",
    paddingBottom: "39px",
  },
  tc: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    display: "flex",
    lineHeight: "16.94px",
    color: "#000000",
    textAlign: "center",

    justifyContent: "flex-start",
  },
  textfield: {
    display: "flex",
    justifyContent: "center",
    padding: "7px 0px 7px 0px",
  },
  textfieldColor: {
    color: "#000",
  },
}));
