import React, { useState } from "react";
import { connect } from "react-redux";
import { addAnswer, clearAnsweres } from "../../../../redux/survey/actions";
import { toast } from "react-toastify";
import { Rate } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";

const StarRatingType = (props) => {
  const { question, addAnswer_, survey_theme, onSend } = props;

  const defaultOption = question?.options[0];

  const [checked, setChecked] = useState();
  const [checkedText, setCheckedText] = useState(defaultOption?.value);
  const [selectedOption, setSelectedOption] = useState({
    value: 0,
    _id: defaultOption?._id,
  });

  const handleRatingChange = (event) => {
    // Find the corresponding option based on the newValue
    const selected = question?.options.find((option) => option.value === event);

    // Update the state with the selected value and _id
    setSelectedOption({ value: event, _id: selected ? selected._id : null });
    if (selected) {
      setChecked(selected._id);
      setCheckedText(event);
    } else {
      setChecked(defaultOption?._id);
      setCheckedText(defaultOption?.value);
    }
  };

  const onSubmitAnswer = () => {
    if (question?.required && checkedText === 0) {
      toast.error("This Question is Required!");
      return;
    } else {
      addAnswer_({
        id: question._id,
        value: checked,
        type: "id",
      });
      onSend(checkedText);
    }
  };

  return (
    <>
      <div className="py-2 bg-white border-t border-gray-200">
        <div className="flex items-center justify-end space-x-3 px-2">
          <Rate
            defaultValue={selectedOption?.value}
            allowHalf
            max={
              question?.options.length > 0
                ? Math.max(...question?.options.map((option) => option.value))
                : 5
            } // Set max value based on options
            onChange={handleRatingChange}
            size="sm"
          />

          <button
            onClick={onSubmitAnswer}
            type="submit"
            className="flex items-end justify-center flex-shrink-0 p-3 text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
            style={{
              backgroundColor: survey_theme?.primaryColor,
            }}
          >
            <svg
              className="w-6 h-6 rotate-90"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};
// This is the mapStateToProps function that extracts the necessary state from the Redux store
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// This is the mapDispatchToProps function that binds the dispatch function to action creators
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
  };
};
// Connects the StarRatingType component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(StarRatingType);
