import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React, { useState } from "react";

import { useCookies } from "react-cookie";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { resetFrontratingAnsweres } from "../../../redux/frontWebRating/actions";
import { saveDetails } from "../../apiHelpers/saveDetails";
import { useStyles } from "./styles";

const BottomBar = (props) => {
  const [cookies, setCookie] = useCookies(["rating"]);
  const { page, isLastPage, isFirstPage, webRatingData } = props;
  const classes = useStyles();
  const history = useHistory();
  const activePageIndex = props?.pageOptions?.indexOf(page);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    let ratingCookie = cookies.rating;
    if (
      ratingCookie?.location !== props.webRatingAnsweres.location_id ||
      localStorage.getItem("is_frequent_rating")
    ) {
      setLoading(true);
      const response = await saveDetails(props.webRatingAnsweres);
      if (response && response?.data?.metaData) {
        setCookie(
          "rating",
          { rating: "submited", location: response.data.metaData.location_id },
          { path: "/", maxAge: 86400 }
        );
        //Set Last Journy In Localstorage
        localStorage.setItem(
          "lastRatingJourny",
          props.webRatingAnsweres.journey
        );

        //Reset Redux
        props.resetFrontratingAnsweres_();
        history.push("/thankYou");
      } else {
        toast.error(response?.message);
        history.push("/frontRating");
      }
      setLoading(false);
    } else {
      toast.error("You have already submitted rating ");
    }
  };
  const handleBack = () => {
    history.push("/frontRating");
  };
  return (
    <div className={classes.container}>
      <div className={classes.buttonContainer}>
        <div className={classes.buttonBox} onClick={props?.onPrev}>
          <ArrowBackIcon className={classes.buttonArrow} />
          <p
            className={classes.buttonText}
            onClick={isFirstPage ? handleBack : props?.onPrev}
          >
            Back
          </p>
        </div>
        <div className={classes.pageNo}>
          <span>{activePageIndex + 1}</span>&nbsp;of&nbsp;
          <span>{props.pageOptions.length}</span>
        </div>
        {!loading ? (
          <div
            className={classes.buttonBox}
            onClick={isLastPage ? handleSubmit : props?.onNext}
          >
            <p className={classes.buttonText}>
              {isLastPage ? "Submit" : "Next"}
            </p>
            <ArrowForwardIcon className={classes.buttonArrow} />
          </div>
        ) : (
          <div className={classes.buttonBox} style={{ cursor: "wait" }}>
            <p className={classes.buttonText}>Saving...</p>
          </div>
        )}
      </div>
      <div className={classes.copyrightBox}>
        {/* <p className={classes.copyrightText}>Powered by</p> */}
        <img
          src={
            webRatingData.company_logo
              ? webRatingData.company_logo
              : "images/webRating/black.png"
          }
          alt="serve_first_logo"
          style={{ marginLeft: "1%", height: "50px" }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    webRatingAnsweres: state.frontWebRatingDataReducer.webRatingAnsweres,
    webRatingData: state.frontWebRatingDataReducer.webRatingData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetFrontratingAnsweres_: (data) =>
      dispatch(resetFrontratingAnsweres(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomBar);
