import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("xs")]: {
      fontSize: "0.3em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.5em",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "0.6em",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "0.7em",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "0.8em",
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1em",
    },
  },
  topContainer: {
    // height: "20%",
  },
  bottomContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: "0.625em",
    paddingTop: "1.25em",
  },
  qrText: { width: "25%" },
  midContainer: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    justifyContent: "center",
  },
  midEmoji: {
    display: "flex",
    marginTop: "5%",
    justifyContent: "center",
    gap: "2%",
  },
  emoji: {
    height: "13em",
  },
  topDropdown: {
    marginLeft: "auto",
    width: "max-content",
  },
  midTitle: {
    letterSpacing: "0em",
    textAlign: "center",
    fontFamily: "Inter",
  },
  midTitleHeading: {
    fontSize: "3.2em",
    fontWeight: 700,
  },
  midTitleDesc: {
    fontSize: "1.8em",
    fontWeight: 400,
    marginTop: "2%",
    width: "70%",
    margin: "auto",
  },
}));
