import React, { useEffect, useState } from "react";
import SingleQuestion from "./SingleQuestion";
import { connect } from "react-redux";
import {
  setFrontratingAnsweres,
  toggleAlphegaActiveState,
} from "../../redux/frontWebRating/actions";
import SingleAnswer from "./SingleAnswer";

const ANSWER = {
  YES: "Yes",
  NO: "No",
};

function AlphegaQuestions(props) {
  const [showAllQuestions, setShowAllQuestions] = useState(false);
  const [currentQueIndex, setCurrentQueIndex] = useState(1);
  const [triggerAnswer, setTriggerAnswer] = useState(null);
  const [displayAnswer, setDisplayAnswer] = useState({
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
  });

  const { setFrontratingAnsweres_, toggleAlphegaActiveState_ } = props;

  const answerQuestion = ({ key = null, value = null, queIndex }) => {
    if (
      !key ||
      !value ||
      ![ANSWER.YES, ANSWER.NO].includes(value) ||
      queIndex < 1 ||
      queIndex > 5
    ) {
      return;
    }

    setFrontratingAnsweres_({ key, value });
    setDisplayAnswer((prev) => ({ ...prev, [queIndex]: value }));

    if (queIndex < 5) {
      setCurrentQueIndex(Number(queIndex) + 1);
    } else {
      toggleAlphegaActiveState_(false);
    }
  };

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }, [displayAnswer]);

  return (
    <>
      <SingleQuestion
        text="Did you buy medicine in the pharmacy today?"
        onClickYes={() => {
          setShowAllQuestions(true);
          setTriggerAnswer(ANSWER.YES);
        }}
        onClickNo={() => {
          toggleAlphegaActiveState_(false);
          setTriggerAnswer(ANSWER.YES);
        }}
        onClickSkip={() => toggleAlphegaActiveState_(false)}
      />
      <SingleAnswer answer={triggerAnswer} />

      {showAllQuestions ? (
        <SingleQuestion
          canAnswer={false}
          text="Were you asked any of the following:"
        />
      ) : null}

      {/* 1 */}
      {showAllQuestions && currentQueIndex >= 1 ? (
        <>
          <SingleQuestion
            id="scroll-medicine_for"
            text="Who is the medicine for?"
            onClickYes={() =>
              answerQuestion({
                key: "medicine_for",
                value: ANSWER.YES,
                queIndex: 1,
              })
            }
            onClickNo={() =>
              answerQuestion({
                key: "medicine_for",
                value: ANSWER.NO,
                queIndex: 1,
              })
            }
            onClickSkip={() => setCurrentQueIndex(2)}
          />

          <SingleAnswer answer={displayAnswer[1]} />
        </>
      ) : null}

      {/* 2 */}
      {showAllQuestions && currentQueIndex >= 2 ? (
        <>
          <SingleQuestion
            id="scroll-symptoms"
            text="What are your symptoms?"
            onClickYes={() =>
              answerQuestion({
                key: "symptoms",
                value: ANSWER.YES,
                queIndex: 2,
              })
            }
            onClickNo={() =>
              answerQuestion({ key: "symptoms", value: ANSWER.NO, queIndex: 2 })
            }
            onClickSkip={() => setCurrentQueIndex(3)}
          />
          <SingleAnswer answer={displayAnswer[2]} />
        </>
      ) : null}

      {showAllQuestions && currentQueIndex >= 3 ? (
        <>
          <SingleQuestion
            id="scroll-symptoms_duration"
            text="How long you had the symptoms?"
            onClickYes={() =>
              answerQuestion({
                key: "symptoms_duration",
                value: ANSWER.YES,
                queIndex: 3,
              })
            }
            onClickNo={() =>
              answerQuestion({
                key: "symptoms_duration",
                value: ANSWER.NO,
                queIndex: 3,
              })
            }
            onClickSkip={() => setCurrentQueIndex(4)}
          />
          <SingleAnswer answer={displayAnswer[3]} />
        </>
      ) : null}

      {showAllQuestions && currentQueIndex >= 4 ? (
        <>
          <SingleQuestion
            id="scroll-action_taken"
            text="What action you have already taken?"
            onClickYes={() =>
              answerQuestion({
                key: "action_taken",
                value: ANSWER.YES,
                queIndex: 4,
              })
            }
            onClickNo={() =>
              answerQuestion({
                key: "action_taken",
                value: ANSWER.NO,
                queIndex: 4,
              })
            }
            onClickSkip={() => setCurrentQueIndex(5)}
          />
          <SingleAnswer answer={displayAnswer[4]} />
        </>
      ) : null}

      {showAllQuestions && currentQueIndex >= 5 ? (
        <>
          <SingleQuestion
            id="scroll-other_medication"
            text="Are you taking any other medication?"
            onClickYes={() =>
              answerQuestion({
                key: "other_medication",
                value: ANSWER.YES,
                queIndex: 5,
              })
            }
            onClickNo={() =>
              answerQuestion({
                key: "other_medication",
                value: ANSWER.NO,
                queIndex: 5,
              })
            }
            onClickSkip={() => toggleAlphegaActiveState_(false)}
          />
          <SingleAnswer answer={displayAnswer[5]} />
        </>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    alphegaActive: state.frontWebRatingDataReducer.alphegaActive,
    webRatingData: state.frontWebRatingDataReducer.webRatingData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFrontratingAnsweres_: (data) => dispatch(setFrontratingAnsweres(data)),
    toggleAlphegaActiveState_: (data) =>
      dispatch(toggleAlphegaActiveState(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlphegaQuestions);
