import React from "react";
import emoji1 from "../../../../../../assets/images/web_rating/emoji_1.gif";
import emoji2 from "../../../../../../assets/images/web_rating/emoji_2.gif";
import emoji3 from "../../../../../../assets/images/web_rating/emoji_3.gif";
import emoji4 from "../../../../../../assets/images/web_rating/emoji_4.gif";
import emoji5 from "../../../../../../assets/images/web_rating/emoji_5.gif";

const EMOJI = {
  1: emoji1,
  2: emoji2,
  3: emoji3,
  4: emoji4,
  5: emoji5,
};

const EmojiOptions = ({ options, onClick, answer, error }) => {
  const isActive = (optionId) => {
    return answer?.value === optionId;
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        {options?.length
          ? options.map((option) => (
              <button
                className={`option-button emoji-button mr-2 ${isActive(option._id) ? "selected" : ""}`}
                onClick={() => onClick(option._id, option.finish_survey)}
              >
                <img width="60px" src={EMOJI[option.value]} alt={`emoji-${option.value}`} />
              </button>
            ))
          : null}
      </div>

      {error ? <div className="mt-2 small font-weight-bold text-danger">{error.message}</div> : null}
    </>
  );
};

export default EmojiOptions;
