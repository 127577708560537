import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";

import image_emoji_1 from '../assets/images/web_rating/emoji_1.gif';
import image_emoji_2 from '../assets/images/web_rating/emoji_2.gif';
import image_emoji_3 from '../assets/images/web_rating/emoji_3.gif';
import image_emoji_4 from '../assets/images/web_rating/emoji_4.gif';
import image_emoji_5 from '../assets/images/web_rating/emoji_5.gif';
import Switch from '@material-ui/core/Switch';
import { postData } from "../components/FetchServices";


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import '../assets/css/EmployeeWebRatingStyles.css';
import DemoUserLogo from '../assets/images/general/user.png';

export default function Home(props) {
  const history = useHistory();

  const redirectNextPage = (path, rating, emojiImagePath) => {
    localStorage.setItem("rating", rating);
    localStorage.setItem("emojiImagePath", emojiImagePath);
    localStorage.setItem("selectedSkill", '');
    history.push(path);
  };

  // check if to show the employee page or to skip it
  const [anonymousFeedback,setanonymousFeedback]=useState(0);
  const handleChangeSwitch=(e)=>{
    setanonymousFeedback((e.target.checked)?1:0)
    localStorage.setItem('is_anonymous',(e.target.checked)?1:0);
  }


  
  const [openLocationDialog, setOpenLocationDialog] = React.useState(true);
  
  // const handleClickOpen = (scrollType) => () => {
  //   //console.log('Test')
  //   setOpenLocationDialog(true);
  //   setScroll(scrollType);
  // };

  const handleCloseLocationDialog = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    if (reason === "escapeKeyDown") {
      return false;
    }

    if (reason === undefined) {
      setOpenLocationDialog(false);
    }
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openLocationDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openLocationDialog]);

  const [searchLocation,setsearchLocation]=useState('');
  
  const getSelectedLocation=(e)=>{
    localStorage.setItem('employeeFeedbackSelectedLocation',e.target.value)
    handleCloseLocationDialog()
  }
  
  const [nearLocationList, setnearLocationList] = useState([]);
  
  const [crntLocationLatitude,setcrntLocationLatitude]= useState('');
  const [crntLocationLongitude,setcrntLocationLongitude]= useState('');

  const getMyLocation =()=> {
    const location = window.navigator && window.navigator.geolocation
    
    if (location) {
      location.getCurrentPosition((position) => {
        setcrntLocationLatitude(position.coords.latitude)
        setcrntLocationLongitude(position.coords.longitude)
      }, (error) => {
        setcrntLocationLatitude('')
        setcrntLocationLongitude('')
    
      })
    }

  }
  const readNearestLocations = React.useCallback (async ()=>{
    const body = {
          lat: crntLocationLatitude,
          long:crntLocationLongitude,
          company_id: localStorage.getItem("company_id"),
          user_id: localStorage.getItem("userId"),
        };
      if(crntLocationLatitude!=='' && crntLocationLongitude!=='') {
          let result = await postData("frontEmployeeFeedback/nearestLocation", body);
          if (result) {
            setnearLocationList(result.data);
          }
      } 
  })
  
  React.useEffect(() => {
    getMyLocation()
  }, []);
  
  React.useEffect(() => {
    readNearestLocations();
  }, [crntLocationLatitude,crntLocationLongitude]);
  
  
  return (
    <React.Fragment>
     {/* location dialog */}
      <Dialog
        open={openLocationDialog}
        onClose={handleCloseLocationDialog}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={'md'}
        fullWidth
      >
           <h2 id="scroll-dialog-title" align="center" style={{marginTop:'20px'}}>
                Choose Location
              </h2>
        <div className="container">
          <div style={{padding:'10px'}}>
            <input className="form-control form-control-web-ratting" placeholder="Search Location" value={searchLocation} onChange={(e)=>setsearchLocation(e.target.value)}/>
          </div>
        </div>

        <DialogContent dividers>
        <div className="location-wrapper" id="myTable">
          {
           (nearLocationList.length>0)?
           nearLocationList
            .filter(locationData => {
              if (!searchLocation) return true
              if (locationData.name.includes(searchLocation)) {
                return true
              }
              return false
            })
            .map(locationData => 
                  (
                    <div className="location-outer" key={locationData._id}>
                    <div className="custom-radio-outer secondary default-cursor">
                      <input type="radio" name="location" className="location" defaultValue={locationData._id}  onChange={(e)=>getSelectedLocation(e)} />
                      <label>
                        <em className="thumb">
                          <img src={DemoUserLogo} alt={`location-logo-${locationData._id}`} />
                        </em>
                        <div className="info info-word-break">
                          <h3> {locationData.name}</h3>
                          <p>{locationData.address1}{(locationData.address2)?', '+locationData.address2:''}</p>
                        </div>
                      </label>
                    </div>
                  </div>
                )
            )
        
           :null
          }
         
        </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLocationDialog} hidden>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* location dialog */}
      <div className="wrapper">
            <div align="right">
              <p>Do you want to keep your feedback anonymous?</p>
              <Switch
                checked={(anonymousFeedback===1)?true:false}
                onChange={handleChangeSwitch}
                color="secondary"
                inputProps={{ 'aria-label': 'feedback anonymous' }}
              />
            </div>
        <section className="feedback-experience-wrapper">
          <div className="container">
            
            <h1 className="h1-heading-home">
              How are you feeling today?
            </h1>
            <ul className="reverse-rating-emoji">
              <li>
                <div
                  className="ratings"
                  rating={1}
                  id="rating1"
                  onClick={() =>
                    redirectNextPage(
                      "/employee-feedback-step-1",
                      1,
                      image_emoji_1
                    )
                  }
                >
                  <div className="custom-radio-outer">
                    <em>
                      <img
                        className="emoji-left"
                        src={image_emoji_1}
                        alt="emoji"
                      />
                    </em>
                    <input type="radio" name="experience" />
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="ratings"
                  rating={2}
                  id="rating2"
                  onClick={() =>
                    redirectNextPage(
                      "/employee-feedback-step-1",
                      2,
                      image_emoji_2
                    )
                  }
                >
                  <div className="custom-radio-outer">
                    <em>
                      <img
                        className="emoji-left"
                        src={image_emoji_2}
                        alt="emoji"
                      />
                    </em>
                    <input type="radio" name="experience" />
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="ratings"
                  rating={3}
                  id="rating3"
                  onClick={() =>
                    redirectNextPage(
                      "/employee-feedback-step-1",
                      3,
                      image_emoji_3
                    )
                  }
                >
                  <div className="custom-radio-outer">
                    <em>
                      <img
                        className="emoji-left"
                        src={image_emoji_3}
                        alt="emoji"
                      />
                    </em>
                    <input type="radio" name="experience" />
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="ratings"
                  rating={4}
                  id="rating4"
                  onClick={() =>
                    redirectNextPage(
                      "/employee-feedback-step-1",
                      4,
                      image_emoji_4
                    )
                  }
                >
                  <div className="custom-radio-outer">
                    <em>
                      <img
                        className="emoji-left"
                        src={image_emoji_4}
                        alt="emoji"
                      />
                    </em>
                    <input type="radio" name="experience" />
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="ratings"
                  rating={5}
                  id="rating5"
                  onClick={() =>
                    redirectNextPage(
                      "/employee-feedback-step-1",
                      5,
                      image_emoji_5
                    )
                  }
                >
                  <div className="custom-radio-outer">
                    <em>
                      <img
                        className="emoji-left"
                        src={image_emoji_5}
                        alt="emoji"
                      />
                    </em>
                    <input type="radio" name="experience" defaultChecked />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
      <Footer />
    </React.Fragment>
  );
}
