import { createTheme } from "@material-ui/core/styles";

export const getTheme = (
  primary,
  secondary,
  background,
  highlight,
  fontColor
) => {
  let themeData = {
    palette: {
      primary: {
        main: primary ? primary : "#36473F",

        light: secondary ? secondary : "#8FA280",
      },
      secondary: {
        main: "#fff",
        dark: "#949494",
      },
      text: {
        primary: secondary ? secondary : "#8FA280",
        secondary: "#fff",
      },
      background: {
        main: background ? background : "#36473F",

        light: secondary ? secondary : "#8FA280",
      },
      highlight: {
        main: highlight ? highlight : "#36473F",

        light: secondary ? secondary : "#8FA280",
      },
    },
    overrides: {
      MuiDialog: {
        paper: {
          color: fontColor ? fontColor : "black", // Replace with your desired font color
        },
      },
    },
  };
  return createTheme(themeData);
};
// const defaultTheme = {
//   palette: {
//     primary: {
//       main: "#36473F",

//       light: "#8FA280",
//     },
//     secondary: {
//       main: "#fff",
//       dark: "#949494",
//     },
//     text: {
//       primary: "#8FA280",
//       secondary: "#fff",
//     },
//   },
// };
// export const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#de5d83",

//       light: "#ffa6c9",
//     },
//     secondary: {
//       main: "#fff",
//       dark: "#949494",
//     },
//     text: {
//       primary: "#de5d83",
//       secondary: "#fff",
//     },
//   },
// });
