import {
  ADD_ANSWER,
  ADD_NOTE,
  ADD_IMAGE,
  SET_TAGS,
  SET_LOCATIONS,
  ADD_WRITEIN,
  CLEAR_ANSWERES,
  CLEAR_ANSWERESANDTAGS,
  SET_SUREY_THEME,
  SET_SURVEY,
  CLEAR_SURVEY,
  SET_GLOBAL_SURVEY,
  CLEAR_ANSWERES_AND_LOCATIONS,
  SET_IS_SURVEY_WITHOUT_LOGIN,
  ADD_CONTACT_DETAILS,
  CLEAR_CONTACT_DETAILS,
  REMOVE_ANSWER,
  ADD_DIRECTOR_MESSAGE,
  SET_GOOGLE_RATINGS_DETAILS,
  CLEAR_GOOGLE_RATINGS_DETAILS,
} from "./constants";

const defaultContactDetails = {
  name: null,
  phone: null,
  email: null,
};

const INIT_STATE = {
  locations: [],
  answeres: {},
  tags: [],
  survey_theme: { primaryColor: "", secondaryColor: "" },
  survey_without_login: {},
  isSurveyWithoutLogin: false,
  globalSurveys: [],
  contactDetails: defaultContactDetails,
  directorMessage: "",
};

export const survey = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_DIRECTOR_MESSAGE:
      return {
        ...state,
        directorMessage: action.payload,
      };
    case ADD_CONTACT_DETAILS:
      return {
        ...state,
        contactDetails: action.payload,
      };
    case REMOVE_ANSWER:
      const answers = Object.assign({}, state.answeres);
      delete answers[action.payload.id];
      return {
        ...state,
        answeres: answers,
      };
    case ADD_ANSWER:
      if (action.payload.isFile) {
        return {
          ...state,
          answeres: {
            ...state.answeres,
            [action.payload.id]: {
              ...state.answeres[action.payload.id],
              value: action.payload.value,
              isFile: true,
              type: action.payload.type,
            },
          },
        };
      } else if (action.payload.score_exclude !== undefined) {
        return {
          ...state,
          answeres: {
            ...state.answeres,
            [action.payload.id]: {
              ...state.answeres[action.payload.id],
              value: action.payload.value,
              type: action.payload.type,
              score_exclude: action.payload.score_exclude,
            },
          },
        };
      } else if (action.payload.code !== undefined) {
        return {
          ...state,
          answeres: {
            ...state.answeres,
            [action.payload.id]: {
              ...state.answeres[action.payload.id],
              value: action.payload.value,
              type: action.payload.type,
              code: action.payload.code,
            },
          },
        };
      } else {
        return {
          ...state,
          answeres: {
            ...state.answeres,
            [action.payload.id]: {
              ...state.answeres[action.payload.id],
              value: action.payload.value,
              type: action.payload.type,
            },
          },
        };
      }

    case ADD_NOTE:
      return {
        ...state,
        answeres: {
          ...state.answeres,
          [action.payload.id]: {
            ...state.answeres[action.payload.id],
            note: action.payload.note,
            type: action.payload.type,
          },
        },
      };
    case ADD_WRITEIN:
      return {
        ...state,
        answeres: {
          ...state.answeres,
          [action.payload.id]: {
            ...state.answeres[action.payload.id],
            write_in: action.payload.write_in,
            type: action.payload.type,
          },
        },
      };
    case ADD_IMAGE:
      return {
        ...state,
        answeres: {
          ...state.answeres,
          [action.payload.id]: {
            ...state.answeres[action.payload.id],
            image: action.payload.image,
            isFile: true,
          },
        },
      };
    case SET_TAGS:
      return {
        ...state,
        tags: action.payload.tags,
      };

    case SET_LOCATIONS:
      return {
        ...state,
        locations: action.payload.locations,
      };

    case CLEAR_ANSWERES:
      return {
        ...state,
        answeres: {},
      };
    case CLEAR_ANSWERESANDTAGS:
      return {
        ...state,
        answeres: {},
        tags: [],
      };
    case CLEAR_ANSWERES_AND_LOCATIONS:
      return {
        ...state,
        answeres: {},
        locations: [],
      };
    case CLEAR_CONTACT_DETAILS:
      return {
        ...state,
        contactDetails: defaultContactDetails,
      };
    case SET_SUREY_THEME:
      return {
        ...state,
        survey_theme: action.payload,
      };
    case SET_SURVEY:
      return {
        ...state,
        survey_without_login: action.payload,
      };
    case CLEAR_SURVEY:
      return {
        ...state,
        survey_without_login: {},
      };
    case SET_GLOBAL_SURVEY:
      return {
        ...state,
        globalSurveys: action.payload,
      };
    case SET_IS_SURVEY_WITHOUT_LOGIN:
      return {
        ...state,
        isSurveyWithoutLogin: action.payload,
      };
    case SET_GOOGLE_RATINGS_DETAILS:
      return {
        ...state,
        google_rating_details: action.payload,
      };
    case CLEAR_GOOGLE_RATINGS_DETAILS:
      return {
        ...state,
        google_rating_details: {},
      };
    default:
      return state;
  }
};
