import React from "react";
export default function SelectedEmoji(props) {
  return (
    <React.Fragment>
      <div className="flex flex-row-reverse items-end justify-start pl-20 transition-all duration-200 ">
        <div className="text-black bg-white rounded-br-none shadow rounded-2xl ">
          <div className="px-4 py-3">
            <img
              className="w-10 h-10"
              src={
                localStorage.getItem("front-mobile-chatbox-emoji-path")
                  ? localStorage.getItem("front-mobile-chatbox-emoji-path")
                  : ""
              }
              alt="empoji-path"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
