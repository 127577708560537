import React from "react";
import { Box } from "@material-ui/core";
import { commonPageMakeStyles } from "../styles/commonPageStyles";
function BackgroundSecond(props) {
  const commonPageCss = commonPageMakeStyles();

  return (
    <Box className={commonPageCss.BgSecondcontainer}>
      <Box className={commonPageCss.BgSecondinnerContainer}>{props.children}</Box>
    </Box>
  );
}

export default BackgroundSecond;
