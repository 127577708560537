import React,{useState} from 'react'
import { postData } from '../components/FetchServicesWithoutJwtToken';
import axios from 'axios';
import {DropzoneArea} from 'material-ui-dropzone'
import { useHistory} from "react-router-dom";
import '../assets/css/FrontCustomerAuditStyles.css'; 

//load images
import ServeImage from '../assets/images/general/serve-first.png';

export default function FrontCustomerAudit(props){
    const history=useHistory();
    const customerauditpid=(props.match.params.id)?props.match.params.id:0;
    const [page,setpage]=useState(1)
    const [perPage]=useState(5)
    const [qdata,setqdata]=useState([]);
    const [totalCount,settotalCount]=useState(0);
    const [locationName,setlocationName]=useState('');
    const readCustomerAuditQNAData=async()=>{
         let body={
            '_id':customerauditpid,
            'perPage':perPage,
            'page':(page),
         }   
        let fetchData=await postData('customerAudit/fetchFrontCustomerAudit',body);
        if(fetchData){
            setqdata(fetchData.data)
            settotalCount(fetchData.totalCount)
            setlocationName(fetchData['locationName'])
            if(fetchData.expire===true){
                history.push('/front/customer-audit-form-already-submited');
            }
        }
    }
    let avgTotalCnt=0;
    avgTotalCnt=(totalCount/perPage);
  
    React.useEffect(() => {
        Promise.all([readCustomerAuditQNAData()])
    }, [customerauditpid,page,perPage])        

    const handleNextPage=(e)=>{
        e.preventDefault();
        setpage(page+1);
    }
    
    
    const handlePrevPage=(e)=>{
        e.preventDefault();
        setpage(page-1);
    }

    const [formFiles,setformFiles]=useState([]);
    const updateformFIles=(tempindex,selectedFIle)=>{
            let filteredItems=[];
            if(formFiles.length>0){
                formFiles.forEach((data,k)=>{
                    if(data['item']['fileIndex']!==tempindex){
                        filteredItems.push(data)     
                    }
                })
            }
            let tempdata={fileIndex:tempindex,filedata:selectedFIle};    
            setformFiles([...filteredItems,{item:tempdata}] );
           
        }
    //    console.log(formFiles)
    const [notesUploadFiles,setnotesUploadFiles]=useState([]);
    const handleUploadCommentImage=(tempindex,selectedFIle)=>{
            let tempItems=[];
            if(notesUploadFiles.length>0){
                notesUploadFiles.forEach((data,k)=>{
                    if(data['item']['fileIndex']!==tempindex){
                        tempItems.push(data)   
                    }
                })
            }
            let tempdata={fileIndex:tempindex,filedata:selectedFIle};    
            setnotesUploadFiles([...tempItems,{item:tempdata}] );
        }

    //console.log(notesUploadFiles)

    const submitCustomerAuditForm=(e)=>{
        e.preventDefault();

        let form_data = new FormData();
        form_data.append(`customer_aduit_id`,customerauditpid)
        for(let i=1;i<=totalCount;i++){
            let question=(localStorage.getItem(`front-customer-audit-form-question-${i}`))?localStorage.getItem(`front-customer-audit-form-question-${i}`):'';
            let answer=(localStorage.getItem(`front-customer-audit-form-q-answer-${i}`))?localStorage.getItem(`front-customer-audit-form-q-answer-${i}`):'';
            let score=(localStorage.getItem(`front-customer-audit-form-q-score-${i}`))?localStorage.getItem(`front-customer-audit-form-q-score-${i}`):0;
            let notes=(localStorage.getItem(`front-customer-audit-form-q-notes-${i}`))?localStorage.getItem(`front-customer-audit-form-q-notes-${i}`):'';
            let qtype=(localStorage.getItem(`front-customer-audit-form-q-type-${i}`))?localStorage.getItem(`front-customer-audit-form-q-type-${i}`):'';
            form_data.append(`question_${i}`,question)
            if(qtype==='File'){
                form_data.append(`answer_${i}`,'')
                if(formFiles.length>0){
                    formFiles.forEach((data,k)=>{
                        if(data['item']['fileIndex']===i){
                            form_data.append(`answer_${i}`,data['item']['filedata'])
                        }
                    })
                }                
            }else{
                form_data.append(`answer_${i}`,answer)
            }
            form_data.append(`score_${i}`,score)
            form_data.append(`note_${i}`,notes)
        }
        form_data.append(`totalCount`,totalCount)
        const baseUrl =process.env.REACT_APP_BASE_URL;
        let url='customerAudit/answerCustomerAudit';
        axios.post(`${baseUrl}/${url}`, form_data,{headers: {'Content-Type'  : 'multipart/form-data'}})
                .then(res => {         
                    if(res.data.message){
                        history.push('/front/customer-audit-form-already-submited');
                    }
                    localStorage.clear();
                    setpage(1)
                    })
                .catch(err => {
                        console.log(err)
                })      
       
    }
    return (
        <React.Fragment>
        <div className="wrapper has-fix-bar">
        <section className="feedback-stepper-wrapper has-left-content">
            <div className="container ajax-div">
            <div className="top-header-wrapper">
                <div className="d-f customer-audit-row">
                <div className="float-left col-sm-6" >
                    <img src={ServeImage} className="rounded" alt="Serve First" width={200} height={60} />
                </div>
                <div className="float-right col-sm-6" style={{'textAlign':'right'}}>
                    <span className="test-location">{locationName}</span>
                </div>
                </div>
            
            </div>
            <form method="post" id="front-cust-audit-form" encType="multipart/form-data">
                <div className="white-box  two-col-block customer-audit-row">
                        {
                           (qdata.length>0)?
                            qdata.map((qdata,qindex)=>{
                                    let newIndex=qindex+1+((page>1)?((page-1)*perPage):0)
                                    return (
                                        <QuestionBlock qdata={qdata} qindex={newIndex} key={qindex} id={`q_block_${qindex}`} updateformFIles={updateformFIles} handleUploadCommentImage={handleUploadCommentImage}/>
                                    )
                            })
                           :''
                        }    
                        
                        {/* footer */}
                        {
                            (totalCount>5)?
                                <div className="bottom-fix-btnbar">
                                        {/*prev btn */}
                                        {
                                            (page>avgTotalCnt)?
                                                <button  className="btn border-btn icon-btn sm" title="Prev" 
                                                        onClick={(e)=>handlePrevPage(e)}
                                                        style={{border:'1px solid #384ed9',lineHeight:'1px'}}    
                                                    >
                                                    <span><i className="fa fa-arrow-left" aria-hidden="true"></i></span>
                                                    <em>
                                                        <svg id="_32213" data-name="32213" xmlns="http://www.w3.org/2000/svg" width="7.519" height="13.19" viewBox="0 0 7.519 13.19">
                                                            <path id="Path_71" data-name="Path 71" d="M97.409,7.248l5.672,5.671a.924.924,0,0,0,1.306-1.306L99.369,6.595l5.019-5.018A.924.924,0,0,0,103.081.27L97.409,5.942a.924.924,0,0,0,0,1.306Z" transform="translate(-97.139 0)" fill="#384ed9"></path>
                                                        </svg>
                                                    </em>
                                                    Prev
                                                </button>
                                            :""
                                        }   
                                        {/*prev btn */}

                                       {/* next btn*/}
                                        {
                                            (page<avgTotalCnt)?
                                            <>
                                            <div className="pagination-wrapper" style={{marginLeft: '45%'}}></div>
                                                <button id="gotonext" onClick={(e)=>handleNextPage(e)} className="btn icon-btn icon-right sm" title="Next" style={{backgroundColor:'#384ed9',border:'1px solid #384ed9','lineHeight':'1px','color':'#fff'}}>
                                                    Next
                                                    <em>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.519" height="13.191" viewBox="0 0 7.519 13.191">
                                                            <g id="_32213" data-name={32213} transform="translate(-97.138 0)">
                                                                <path id="Path_71" data-name="Path 71" d="M104.387,7.248,98.715,12.92a.924.924,0,1,1-1.306-1.306L102.428,6.6,97.409,1.577A.924.924,0,0,1,98.716.27l5.672,5.672a.924.924,0,0,1,0,1.306Z" fill="#fff" />
                                                            </g>
                                                        </svg>
                                                    </em>
                                                </button>
                                            </>   
                                            :''
                                        }
                                       {/* next btn*/}

                                       {
                                            (page>=avgTotalCnt)?
                                            <button id="submitForm" onClick={(e)=>submitCustomerAuditForm(e)} className="btn icon-btn icon-right sm" title="Submit" style={{backgroundColor:'#384ed9',border:'1px solid #384ed9','lineHeight':'1px','color':'#fff'}}>
                                                Submit
                                                <em>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="7.519" height="13.191" viewBox="0 0 7.519 13.191">
                                                        <g id="_32213" data-name="32213" transform="translate(-97.138 0)">
                                                        <path id="Path_71" data-name="Path 71" d="M104.387,7.248,98.715,12.92a.924.924,0,1,1-1.306-1.306L102.428,6.6,97.409,1.577A.924.924,0,0,1,98.716.27l5.672,5.672a.924.924,0,0,1,0,1.306Z" fill="#fff"></path>
                                                        </g>
                                                    </svg>
                                                    
                                                </em>
                                            </button>
                                            :""
                                        }    
                                </div>
                        :''
                        }
                        {/* footer */}

                    </div>
            </form>
        </div>
    </section>
</div>

        </React.Fragment>
    );
}



const QuestionBlock=(props)=>{
     const qdata=props.qdata
     const qindex=props.qindex
     const qoption=(qdata.option)?qdata.option:[];
    
     
     const qtype=(qdata.type)?qdata.type:'';
     const qrequired=(qdata.requried)?qdata.requried:0;
     const qminimum_characters=(qdata.minimum_characters)?qdata.minimum_characters:0;

     localStorage.setItem(`front-customer-audit-form-question-${qindex}`,(qdata.question)?qdata.question:'')
     localStorage.setItem(`front-customer-audit-form-q-type-${qindex}`,qtype)
     
     const [answer,setanswer]=useState((localStorage.getItem(`front-customer-audit-form-q-answer-${qindex}`) && qtype==='Textbox')?localStorage.getItem(`front-customer-audit-form-q-answer-${qindex}`):'');
     const handleAnswer=(e)=>{
        setanswer(e.target.value)
        localStorage.setItem(`front-customer-audit-form-q-answer-${qindex}`,e.target.value)
     }

     const handleRadioScore=(answer,score)=>{
         localStorage.setItem(`front-customer-audit-form-q-answer-${qindex}`,answer)
         localStorage.setItem(`front-customer-audit-form-q-score-${qindex}`,score)
     }
    
     const removeItemOnce=(arr, value)=> {
        var index = arr.indexOf(value);
        if (index > -1) {
          arr.splice(index, 1);
        }
        return arr;
      }
      
     let checkboxAnswerArr=[]; 
     let checkboxScoreArr=[]; 

     const handleCheckboxScore=(e,opindex,answer,score)=>{
        let isChecked = e.target.checked;
        if(isChecked===true){
                checkboxAnswerArr.push(answer)
                checkboxScoreArr.push(score)
                localStorage.setItem(`front-customer-audit-form-q-checkbox-${qindex}-score-${opindex}`,score) //for default selection
            }else{
                removeItemOnce(checkboxAnswerArr,answer)
                removeItemOnce(checkboxScoreArr,score)
                localStorage.setItem(`front-customer-audit-form-q-checkbox-${qindex}-score-${opindex}`,'')  //for default selection

            }   

            let scoreTotal=checkboxScoreArr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0); 
           
            localStorage.setItem(`front-customer-audit-form-q-answer-${qindex}`,(checkboxAnswerArr.length>0)?checkboxAnswerArr.join(','):'')
            localStorage.setItem(`front-customer-audit-form-q-score-${qindex}`,scoreTotal)
        }
     const handleUploadImage=(files)=>{
        let selectedFiles=[];
        if(files.length>0){
            for(let i=0;i<files.length;i++){
                selectedFiles.push(files[i])
            } //loop close
            props.updateformFIles(qindex,selectedFiles)
        }else{
            props.updateformFIles(qindex,[])
        }
        localStorage.setItem(`front-customer-audit-form-q-answer-${qindex}`,'')

     }
     
     const handleNotesUploadFiles=(files)=>{
        let selectedFiles=[];
        if(files.length>0){
            for(let i=0;i<files.length;i++){
                selectedFiles.push(files[i])
            } //loop close
            props.handleUploadCommentImage(qindex,selectedFiles)
        }else{
            props.handleUploadCommentImage(qindex,[])
        }
        localStorage.setItem(`front-customer-audit-form-q-notes-upload-files-${qindex}`,'')
     }
     
     

     const [isShowCommentField,setisShowCommentField]=useState(false);
     const [isShowCommentFieldFileUpload,setisShowCommentFieldFileUpload]=useState(false);
     const [notes,setnotes]=useState((localStorage.getItem(`front-customer-audit-form-q-notes-${qindex}`))?localStorage.getItem(`front-customer-audit-form-q-notes-${qindex}`):'');
     
     const showhideCommentFileUpload=(e)=>{
        e.preventDefault()
        setisShowCommentFieldFileUpload((isShowCommentFieldFileUpload)?false:true)
        localStorage.setItem(`isShowCommentFieldFileUpload-${qindex}`,(isShowCommentFieldFileUpload)?false:true)
     }

     const showhideCommentBox=(e)=>{
        e.preventDefault()
        setisShowCommentField((isShowCommentField)?false:true)
        localStorage.setItem(`isShowCommentField-${qindex}`,(isShowCommentField)?false:true)
     }

    const handleNotes=(e)=>{
        setnotes(e.target.value)
        localStorage.setItem(`front-customer-audit-form-q-notes-${qindex}`,e.target.value)
     }
    
    
     if(qdata.question){
        return (
        
            <div className="col-sm-12 m-t-20" key={props.qindex}>
                                        <p className="question-color" style={{'fontWeight':'bold',paddingTop:'5px',paddingBottom:'5px'}}>{qdata.question}</p>
                                        <div>
                                           {
                                            (qtype==='Radio' && qoption.length>0)?
                                            qoption.map((option,opindex)=>{
                                                return(
                                                   <div className="custom-radio-outer secondary-radio" key={`option_${qindex}_${opindex}`}>
                                                       <input type="radio" name={`score_${qindex}`} 
                                                           value={option.score}
                                                           onChange={(e) =>handleRadioScore(option.name,option.score)}
                                                           //defaultValue={option.score} 
                                                           id={`option-radio-${qindex}_${opindex}`}
                                                           defaultChecked={(localStorage.getItem(`front-customer-audit-form-q-score-${qindex}`) === option.score)?true:false}
                                                       />
                                                       <label htmlFor={`option-radio-${qindex}_${opindex}`}>{option.name}</label>
                                                   </div>
                                                )
                                            })
                                           :''
                                           }
                                           {
                                             (qtype==="Checkbox" && qoption.length>0)?
                                               qoption.map((option1,opindex)=>{
                                                 return(
                                                   <>
                                                   <div className="custom-checkbox" key={`option_${qindex}_${opindex}`} style={{marginLeft:'20px',lineHeight:1,height:'35px'}}> 
                                                       <input type="checkbox" 
                                                       name={`checkbox${qindex}`} 
                                                       value={option1.score}
                                                       onChange={(e) =>handleCheckboxScore(e,opindex,option1.name,option1.score)}
                                                       defaultChecked={(localStorage.getItem(`front-customer-audit-form-q-checkbox-${qindex}-score-${opindex}`)===option1.score)?true:false}
                                                       className="form-check-input" 
                                                       id={`option-checkbox-${qindex}_${opindex}`} 
                                                       style={{transform:'scale(1.3)',borderRadius:0.5}}
                                                       />
                                                       <label className="form-check-label" style={{fontSize:'20px',fontWeight:500,paddingLeft:'5px'}} htmlFor={`option-checkbox-${qindex}_${opindex}`} >
                                                           {option1.name}
                                                       </label>
                                                   </div> 
                                                   </>
                                                 )
                                               }) 
                                             :'' 
                                           }
                                           {
                                             (qtype==='Textbox')?
                                               <input 
                                                   type="text" 
                                                   id={`answer_${qindex}`} 
                                                   name={`answer_${qindex}`} 
                                                   value={answer} 
                                                   onChange={(e)=>handleAnswer(e)} 
                                                   className="form-control" 
                                                   required={(qrequired===1)?'required':''} 
                                                   minLength={qminimum_characters} 
                                                   placeholder="Enter Answer"
                                               />
                                             :''
                                           }
                                           
                                        
                                           {
                                             (qtype==='File')?
                                             <DropzoneArea 
                                               id={`file_answer_${qindex}`} 
                                               name={`file_answer_${qindex}`}
                                               acceptedFiles={['image/*']}
                                               onChange={(files)=>handleUploadImage(files)}
                                               filesLimit={5}
                                               maxFileSize={5000000}
                                              />
                                             :''
                                           }
                                          {
                                           (qrequired===1)?
                                               <div className="row question-error-customer-audit" >
                                                   <div className="col-sm-12">
                                                       <p className="error-customer-audit" style={{color:'red'}}>Please answer above question.</p>
                                                   </div>
                                               </div> 
                                           :''  
                                        }
                                        </div>
                                         
                                        {/*note block  */}
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <button onClick={(e)=>showhideCommentBox(e)} className="notes-label" style={{fontSize:'12px'}}>Add Comments</button>
                                                {
                                                   (localStorage.getItem(`isShowCommentField-${qindex}`)==='true')?
                                                   <textarea className="form-control sm notes-text-area" placeholder="Add Comments.." name={`note_${qindex}`} id={`note_${qindex}`} 
                                                       value={notes} 
                                                       onChange={(e)=>handleNotes(e)} 
                                                   />
                                                   :''
                                                }
                                            </div>
                                        </div>
                                        {/*note block  */}
                                         {/*comment file upload block  */}
                                         <div className="row">
                                            <div className="col-sm-12">
                                                <button onClick={(e)=>showhideCommentFileUpload(e)} className="notes-label" style={{fontSize:'12px'}}>Add Upload Files</button>
                                                {
                                                   (localStorage.getItem(`isShowCommentFieldFileUpload-${qindex}`)==='true')?
                                                        <DropzoneArea 
                                                                id={`file_answer_${qindex}`} 
                                                                name={`file_answer_${qindex}`}
                                                                acceptedFiles={['image/*']}
                                                                onChange={(files)=>handleNotesUploadFiles(files)}
                                                                filesLimit={5}
                                                                maxFileSize={5000000}
                                                        />
                                                   :''
                                                }
                                            </div>
                                        </div>
                                        {/*comment file upload block  */}                
                                    </div>
      
        )
     }else{
         return (<></>);
     } 
     
 }