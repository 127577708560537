import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useStyles } from "../SurveyThankYou/style";
import {
  clearAnswersAndTags,
  clearContactDetails,
  clearGoogleRatingsDetails,
  clearSurvey,
} from "../../../redux/survey/actions";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from "react-router-dom";
import { IconButton } from "rsuite";
import { FaCopy } from "react-icons/fa";
import { toast } from "react-toastify";
import { useMediaQuery, useTheme } from "@material-ui/core";
import GoogleLogo from "../../../assets/images/google-logo.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ThankYou(props) {
  // Destructing props
  const {
    clearContactDetails_,
    clearAnswersAndTags_,
    clearSurveyWithoutLogin,
    survey_without_login,
    google_rating_details,
    clearGoogleRatingsDetails_,
  } = props;
  const backgroundClasses = useStyles();
  // State variables to hold the text and button visibility
  const [acknowledgmentText, setAcknowledgmentText] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [surveyCompleteIcon, setSurveyCompleteIcon] = useState("");
  const [surveyCompleteFont, setSurveyCompleteFont] = useState("");
  const [showGoogleRatings, setShowGoogleRatings] = useState(false);
  const [showGoogleRatingsUrl, setShowGoogleRatingsUrl] = useState("");
  const [googleRatingImage, setGoogleRatingImage] = useState("");

  const location = useLocation();
  const history = useHistory();
  const theme = useTheme(); // Get current theme
  const isMob = useMediaQuery(theme.breakpoints.down("sm")); // Check if device is mobile
  // Extracting and setting states from location
  const uniqueshortcode =
    location.state !== undefined ? location.state.uniqueshortcode : "";

  useEffect(() => {
    // If there is acknowledgment text in the survey without login data, set it
    if (survey_without_login?.acknowledgment_text) {
      setAcknowledgmentText(survey_without_login?.acknowledgment_text);
    }
    // If there is a background image in the survey without login data, set it
    if (survey_without_login?.background_image) {
      setBackgroundImage(survey_without_login?.background_image);
    }
    // If there is a font for survey completion in the survey without login data, set it
    if (survey_without_login?.surveyCompleteFont) {
      setSurveyCompleteFont(survey_without_login?.surveyCompleteFont);
    }
    // If there is an icon for survey completion in the survey without login data, set it
    if (survey_without_login?.surveyCompleteIcon) {
      setSurveyCompleteIcon(survey_without_login?.surveyCompleteIcon);
    }
    // If the option to show Google ratings is enabled in the survey without login data, set it
    if (survey_without_login?.showGoogleRatings) {
      setShowGoogleRatings(survey_without_login?.showGoogleRatings);
    }
    // If there is a URL for Google reviews in the Google rating details, set it
    if (google_rating_details?.locationGoogleReviewUrl) {
      setShowGoogleRatingsUrl(google_rating_details?.locationGoogleReviewUrl);
    }
    // If there is a QR image for Google ratings in the Google rating details, set it
    if (google_rating_details?.locationGoogleQrImage) {
      setGoogleRatingImage(google_rating_details?.locationGoogleQrImage);
    }
    // Clear state data
    // Clear various data and settings
    clearAnswersAndTags_();
    clearContactDetails_();
    clearSurveyWithoutLogin();
    clearGoogleRatingsDetails_();
    const companyLogin = localStorage.getItem("user_id");
    if (companyLogin) {
      localStorage.removeItem("showAllLocation");
      localStorage.removeItem("location");
      localStorage.removeItem("showGoogle");
      localStorage.removeItem("survey");
      localStorage.removeItem("employees");
      // Redirect to the survey location page
      const timer = setTimeout(() => {
        history.push("/survey/location");
      }, 2500);
      return () => clearTimeout(timer);
    } else {
      localStorage.clear(); // Clear local storage
    }
  }, []); // Run only once on component mount

  // Function to copy the Ticket No to clipboard
  const copyTicketNumber = async (ticketNo) => {
    try {
      await navigator.clipboard.writeText(`${ticketNo}`);
      toast.success("Ticket number copied to clipboard");
    } catch (e) {
      toast.error("Error occured while copying");
    }
  };

  return (
    <div style={{ minHeight: "100%" }}>
      <section
        className={`login-wrapper ${
          !backgroundImage ? backgroundClasses.backgroundImg : ""
        }`}
        style={
          backgroundImage
            ? {
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundImage: `url(${backgroundImage})`,
              }
            : {}
        }
      >
        <div className={backgroundClasses.container}>
          <img
            src={
              surveyCompleteIcon
                ? surveyCompleteIcon
                : "/images/webRating/check.png"
            }
            style={{ width: isMob ? "10vw" : "7vw" }}
            alt="checkicon"
          />

          {acknowledgmentText ? (
            <h2
              className={
                isMob
                  ? backgroundClasses.thankyouMobileText
                  : backgroundClasses.thankyouText
              }
              style={{
                color: surveyCompleteFont ? surveyCompleteFont : "white",
              }}
            >
              {ReactHtmlParser(acknowledgmentText)}
            </h2>
          ) : (
            <h2
              className={
                isMob
                  ? backgroundClasses.thankyouMobileText
                  : backgroundClasses.thankyouText
              }
              style={{
                color: surveyCompleteFont ? surveyCompleteFont : "white",
              }}
            >
              Thank you for your time and your answers.
            </h2>
          )}
          {uniqueshortcode ? (
            <div
              className={
                isMob
                  ? backgroundClasses.copyTextMobile
                  : backgroundClasses.copyText
              }
            >
              Your feedback has been submitted. Your ticket number is #
              {uniqueshortcode}
              <IconButton
                icon={<FaCopy sx={{ mr: 1 }} />}
                color="blue"
                onClick={() => copyTicketNumber(uniqueshortcode)}
                className={backgroundClasses.copyButton}
              />
              . A member of the team will be in touch shortly.
            </div>
          ) : (
            ""
          )}
          {/* Show Google Ratings */}
          {showGoogleRatings && showGoogleRatingsUrl ? (
            <>
              <h2
                className={
                  isMob
                    ? backgroundClasses.googleMobileText
                    : backgroundClasses.googleText
                }
              >
                Would you also like to rate us on Google?
              </h2>

              <div
                className={backgroundClasses.googleReviewContainer}
                style={{ flexDirection: "column" }}
              >
                {googleRatingImage ? (
                  <img
                    src={googleRatingImage}
                    style={{ width: isMob ? "15vw" : "7vw" }}
                    alt="QRCode"
                  />
                ) : null}
                <a
                  href={showGoogleRatingsUrl}
                  target="_blank"
                  rel="noreferrer"
                  className={backgroundClasses.googleReviewButton}
                >
                  <img
                    src={GoogleLogo}
                    alt="google-logo"
                    width="50px"
                    style={{ marginRight: "5px" }}
                  />
                  Rate us now
                </a>
              </div>
            </>
          ) : null}
        </div>
        <img
          src="/images/webRating/powerbywhite.png"
          alt="powerby"
          style={{ position: "absolute", bottom: 9 }}
        />
      </section>
    </div>
  );
}
// Function that extracts the necessary state from the Redux store
const mapStateToProps = (state) => {
  return {
    survey_without_login: state.survey.survey_without_login,
    google_rating_details: state.survey.google_rating_details,
  };
};
// Function that binds the dispatch to action creators
const mapDispatchToProps = (dispatch) => {
  return {
    clearAnswersAndTags_: () => dispatch(clearAnswersAndTags()),
    clearSurveyWithoutLogin: () => dispatch(clearSurvey()),
    clearContactDetails_: () => dispatch(clearContactDetails()),
    clearGoogleRatingsDetails_: () => dispatch(clearGoogleRatingsDetails()),
  };
};
// Connect ThankYou Component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
