import React, { useRef } from "react";
import { connect } from "react-redux";
import {
  addAnswer,
  addNote,
  addWriteIn,
} from "../../../../redux/survey/actions";
import { toast } from "react-toastify";
import "rsuite/dist/styles/rsuite-default.css";
import SignaturePad from "react-signature-canvas";
import { FaPaperPlane, FaTrashAlt } from "react-icons/fa";

function SignatureType(props) {
  const { question, addAnswer_, survey_theme, onSend } = props;
  const signatureRef = useRef();

  const submitAnswer = () => {
    if (question?.required && !signatureRef?.current?.toDataURL()) {
      toast.error("This Question is Required!");
      return;
    } else {
      onSend(signatureRef?.current?.getTrimmedCanvas()?.toDataURL());
      addAnswer_({
        id: question._id,
        value: signatureRef?.current?.getTrimmedCanvas()?.toDataURL(),
        isFile: true,
      });
    }
  };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  return (
    <div className="px-0">
      <div className="space-x-3">
        <SignaturePad
          ref={signatureRef}
          canvasProps={{
            height: 450,
            className:
              "signature-canvas-block block w-full px-3 py-2 text-base text-black transition-all duration-200 bg-white border border-transparent shadow-lg",
          }}
        />
      </div>
      <div className="d-flex align-items-center justify-content-end mt-2">
        <button
          onClick={clearSignature}
          type="submit"
          className="p-2 text-white transition-all duration-200 shadow-lg hover:bg-black focus:bg-black mr-1 d-flex align-items-center"
          style={{
            backgroundColor: survey_theme?.secondaryColor,
            borderRadius: 10,
          }}
        >
          <FaTrashAlt className="mr-1" />
          Clear
        </button>
        <button
          onClick={submitAnswer}
          type="submit"
          className="p-2 text-white transition-all duration-200 shadow-lg hover:bg-black focus:bg-black d-flex align-items-center"
          style={{
            backgroundColor: survey_theme?.primaryColor,
            borderRadius: 10,
          }}
        >
          <FaPaperPlane className="mr-1" />
          Save
        </button>
      </div>
    </div>
  );
}
// Redux mapping function to map state and dispatch to props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Redux mapping function to map dispatch to props
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    addNote_: (data) => dispatch(addNote(data)),
    addWriteIn_: (data) => dispatch(addWriteIn(data)),
  };
};
// Connect SignatureType component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(SignatureType);
