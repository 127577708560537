/* eslint-disable array-callback-return */
import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DocumentImage from "../../../../../assets/images/client-connect/document.jpg";
import ExcelImage from "../../../../../assets/images/client-connect/excel.png";
import LinkImage from "../../../../../assets/images/client-connect/link-image.jpg";
import PdfImage from "../../../../../assets/images/client-connect/pdf.png";
import PowerpointImage from "../../../../../assets/images/client-connect/powerpoint.png";
import TextImage from "../../../../../assets/images/client-connect/text.png";
import WordImage from "../../../../../assets/images/client-connect/word.png";
import ClientFileModalContent from "./ClientFileModalContent";
import CustomModal from "./CustomModal";

const fileTypes = [
  { extensions: ["txt"], image: TextImage },
  { extensions: ["abw", "doc", "docx", "odt"], image: WordImage },
  { extensions: ["odp", "ppt", "pptx"], image: PowerpointImage },
  { extensions: ["ods", "xls", "xlsx", "csv"], image: ExcelImage },
  { extensions: ["jpeg", "jpg", "png", "svg"], image: null },
  { extensions: ["pdf"], image: PdfImage },
];

const DisplayContent = ({ displayContent, onClick }) => {
  return (
    <>
      {displayContent.length
        ? displayContent.map((content) => (
            <button
              key={content._id}
              className="content-file shadow-sm"
              onClick={() => onClick(content)}
            >
              <img
                className="content-image"
                src={content?.displayImage}
                alt={content.name}
              />

              <div className="w-100 d-flex flex-column justify-content-between align-items-center">
                <div className="content-name">{content.name}</div>
                <button
                  type="button"
                  className="mt-3 content-button"
                  onClick={() => onClick(content)}
                >
                  View
                </button>
              </div>
            </button>
          ))
        : null}
    </>
  );
};

export default function ClientContent({ survey }) {
  const { clientConnect } = survey;
  const clientContent = clientConnect.client_content;

  const theme = useTheme();
  const isMob = useMediaQuery(theme.breakpoints.down("sm"));
  const perPage = isMob ? 1 : 3;
  const totalPages = Math.ceil(clientContent.length / perPage);

  const [page, setPage] = useState(1);
  const [allContent, setAllContent] = useState([]);
  const [displayContent, setDisplayContent] = useState([]);
  const [fileModal, setFileModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);

  const generateDisplayContent = () => {
    const start = page * perPage - perPage;
    const end = page * perPage;
    const newContent = allContent.slice(start, end);
    setDisplayContent(newContent);
  };

  useEffect(() => {
    generateDisplayContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, allContent]);

  useEffect(() => {
    const processedContent = clientConnect?.client_content?.length
      ? clientConnect.client_content.map((c) => {
          if (c?.link) {
            c.displayImage = LinkImage;
            return c;
          } else if (c?.extension) {
            const fileType = fileTypes.find((f) =>
              f.extensions.includes(c.extension.replace(".", ""))
            );
            if (fileType) {
              if (fileType.image === null) {
                c.displayImage = c.file;
              } else {
                c.displayImage = fileType.image;
              }
              return c;
            }
          }
          c.displayImage = DocumentImage;
          return c;
        })
      : [];

    setPage(1);
    setAllContent(processedContent);
  }, [clientContent]);

  const handlePrevious = () => {
    if (page !== 1) {
      setPage((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      setPage((prev) => prev + 1);
    }
  };

  const handleContentSelect = (content) => {
    setFileModal(true);
    setSelectedContent(content);
  };

  const handleModalClose = () => {
    setSelectedContent(null);
    setFileModal(false);
  };

  return (
    <div className="question-card">
      <CustomModal isOpen={fileModal}>
        {fileModal ? (
          <ClientFileModalContent
            key={Math.round(Math.random() * 1e5)}
            selectedContent={selectedContent}
            onCloseClick={handleModalClose}
            user={clientConnect?.user}
          />
        ) : null}
      </CustomModal>

      <div className="d-lg-none d-flex flex-wrap justify-content-center align-items-center">
        <DisplayContent
          displayContent={allContent}
          onClick={handleContentSelect}
        />
      </div>

      <div className="content-wrapper h-100 d-none d-lg-flex justify-content-between align-items-center">
        <button
          type="button"
          disabled={page === 1}
          className="arrow-button"
          onClick={handlePrevious}
        >
          {page === 1 ? (
            <div>&nbsp;</div>
          ) : (
            <i className="far fa-arrow-alt-circle-left" />
          )}
        </button>

        <DisplayContent
          displayContent={displayContent}
          onClick={handleContentSelect}
        />

        <button
          type="button"
          disabled={page >= totalPages}
          onClick={handleNext}
          className="arrow-button"
        >
          {page >= totalPages ? (
            <div>&nbsp;</div>
          ) : (
            <i className="far fa-arrow-alt-circle-right" />
          )}
        </button>
      </div>
    </div>
  );
}
