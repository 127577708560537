import React from "react";
import { Route, Switch } from "react-router-dom";
import Question from "./pages/Questions";
// Mobile Survey 
function MobileSurvey(props) {
  const { match } = props;

  return (
    <Switch>
      <Route path={`${match.url}/questions`} component={Question} />
    </Switch>
  );
}

export default MobileSurvey;
