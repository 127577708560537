import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./clientModal.css";

export default function ClientFileModalContent({
  selectedContent,
  onCloseClick,
  user,
}) {
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);

  const fetchFile = async () => {
    let objectUrl = null;

    if (
      selectedContent?.file &&
      selectedContent?.name &&
      selectedContent?.extension
    ) {
      try {
        setLoading(true);
        const response = await fetch(selectedContent.file, {
          method: "GET",
          headers: {
            "Content-Type": "application/octet-stream",
          },
        });

        if (!response.ok) {
          toast.error("Failed to load the file!");
        }

        const result = await response.blob();
        objectUrl = window.URL.createObjectURL(result);
      } catch (e) {
        toast.error(e.message);
      }
    }

    setFileUrl(objectUrl);
    setLoading(false);

    return objectUrl;
  };

  useEffect(async () => {
    const objectUrl = fetchFile();

    return () => {
      if (objectUrl) {
        window.URL.revokeObjectURL(objectUrl);
        setFileUrl(null);
      }
    };
  }, []);

  const downloadFile = () => {
    if (!fileUrl) {
      return;
    }
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = `${selectedContent.name}${selectedContent.extension}`;
    a.click();
    a.remove();
  };

  return (
    <div className="c-modal-wrapper">
      <div className="c-modal-left">
        <div className="c-left-top">
          {fileUrl ? (
            <DocViewer
              prefetchMethod="GET"
              pluginRenderers={DocViewerRenderers}
              documents={[
                { uri: selectedContent?.file, fileName: selectedContent?.name },
              ]}
            />
          ) : (
            <img
              src={selectedContent?.displayImage}
              alt={selectedContent?.name}
              className="c-modal-image"
            />
          )}
        </div>

        <div className="pt-3 c-left-bottom">
          {selectedContent?.link ? (
            <a
              href={selectedContent.link}
              target="_blank"
              rel="noreferrer"
              className="mt-1 mt-md-2 content-button"
            >
              Open Link
            </a>
          ) : (
            <button
              type="button"
              disabled={loading || !fileUrl}
              className="mt-3 mt-md-2 content-button"
              onClick={() => downloadFile()}
            >
              {loading ? "Loading..." : "Download"}
            </button>
          )}
        </div>
      </div>

      <div className="c-modal-right">
        <button
          className="c-modal-action"
          disabled={loading}
          onClick={onCloseClick}
        >
          <div className="c-modal-close">
            <i className="fas fa-times" />
          </div>
        </button>

        <div className="c-modal-right-content">
          <div className="c-modal-right-file">
            {fileUrl ? (
              <DocViewer
                prefetchMethod="GET"
                pluginRenderers={DocViewerRenderers}
                documents={[
                  {
                    uri: selectedContent?.file,
                    fileName: selectedContent?.name,
                  },
                ]}
              />
            ) : (
              <img
                src={selectedContent?.displayImage}
                alt={selectedContent?.name}
                className="c-modal-image"
              />
            )}

            {selectedContent?.link ? (
              <a
                href={selectedContent.link}
                target="_blank"
                rel="noreferrer"
                className="mt-1 mt-md-2 content-button"
              >
                Open Link
              </a>
            ) : (
              <button
                type="button"
                disabled={loading || !fileUrl}
                className="mt-4 mt-md-2 content-button"
                onClick={() => downloadFile()}
              >
                {loading ? "Loading..." : "Download"}
              </button>
            )}
          </div>

          <div className="c-modal-content-head">
            <i className="fas fa-info-circle text-dark mr-2" />
            <span>Details</span>
          </div>

          <div className="c-modal-content-title mt-4">Title</div>
          <div className="c-modal-content-name">{selectedContent?.name}</div>

          <div className="c-modal-content-title mt-4">Description</div>
          <div className="mt-1">{selectedContent?.description}</div>

          <div className="c-modal-content-title mt-4">General Info</div>
          <table className="mt-1 table table-sm table-borderless">
            {selectedContent?.link ? (
              <>
                <tr>
                  <td>Link</td>
                  <td>
                    <a
                      href={selectedContent.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {selectedContent?.link}
                    </a>
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td>Type</td>
                  <td>{selectedContent?.fileType}</td>
                </tr>
                <tr>
                  <td>Size</td>
                  <td>{selectedContent?.size}</td>
                </tr>
                <tr>
                  <td>Uploaded by</td>
                  <td>{user?.name}</td>
                </tr>
              </>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}
