import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
    },
    width: "100%",
  },
  stepConnector: (props) => ({
    height: "3px",
    flex: "1 1 auto",
  }),
  stepperDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    flex: "0 0 auto",
  },
}));
function Progress(props) {
  const { answered = 2, total = 5 } = props;
  const classes = useStyles(props);
  const theme = useTheme();

  const steps = new Array(total).fill(0);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {steps.map((item, i) => {
        return (
          <React.Fragment key={i}>
            <div
              className={classes.stepConnector}
              style={{
                backgroundColor: answered >= i + 1 ? "#404040" : "silver",
              }}
            ></div>
            <div
              className={classes.stepperDot}
              style={{
                backgroundColor: answered >= i + 1 ? "#404040" : "silver",
              }}
            ></div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default Progress;
