import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button as Btn } from "@material-ui/core";
import { useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  margin: {
    textTransform: "capitalize",
    fontSize: "11px",
    color: theme.palette.primary.light,
    padding: "5px 10px",

    [theme.breakpoints.up("md")]: {
      padding: "6px 16px",
      fontSize: '"0.8rem"',
    },
  },
}));
function Button(props) {
  const classes = useStyles(props);
  const theme = useTheme(props);
  const {
    leftIcon,
    rightIcon,
    onClick,
    title = "",
    variant = "contained",
    isDisable,
  } = props;

  return (
    <Btn
      disabled={isDisable}
      variant={variant}
      className={classes.margin}
      style={{
        backgroundColor: isDisable ? "grey" : "#404040",
        // : variant === "contained"
        // ? theme.palette.primary.main
        // : null,
        color:
          variant === "contained"
            ? theme.palette.secondary.main
            : theme.palette.primary.light,
        padding: !title && "3px 0px",
        minWidth: !title && "30px",
        ...props.style,
      }}
      onClick={onClick}
    >
      {leftIcon && leftIcon}
      {title}
      {rightIcon && rightIcon}
    </Btn>
  );
}

export default Button;
