import React from "react";
// import * as reportStyles from "../Reportstyle.module.css";
import reportStyles from "../Reportstyle.module.css";

const StatsCard = (props) => {
  const { icon: Icon, title = "", percent = "", para = "", color } = props;

  return (
    <div class={reportStyles.divbackground1}>
      <div style={{ width: "20%" }}>
        <Icon fill={color} style={{ width: "100%" }} />
      </div>

      <div style={{ paddingLeft: "13.5px", width: "80%" }}>
        <h3 class={reportStyles.heading1}>{title}</h3>
        <p class={reportStyles.numberpercent}>{percent}</p>
        <p class={reportStyles.para1}>{para}</p>
      </div>
    </div>
  );
};

export default StatsCard;
