import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../components/question.css";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  addAnswer,
  addWriteIn,
  clearAnsweres,
} from "../../../../redux/survey/actions";
import { TextField } from "@material-ui/core";

const RadioType = (props) => {
  // Destructuring props
  const {
    finishSurvey,
    headTonext,
    question,
    answeres,
    addAnswer_,
    clearAnsweres_,
    survey_theme,
    onSend,
    addWriteIn_,
    allQuestionsOriginal,
    setAllQuestions,
    surveyType,
    currentQuestionIndex,
    hideQuestion,
  } = props;

  // State variables
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState("");
  const [checkedText, setCheckedText] = useState("");
  const [comment, setComment] = useState("");
  const [isFinishSurvey, setIsFinishSurvey] = useState(false);
  const [routeToIndex, setRouteToIndex] = useState("");

  // Function to get answer object
  let answer = () => {
    if (!(question._id in answeres)) {
      return { value: "" };
    } else {
      if (!("value" in answeres[question._id])) {
        return { value: "" };
      } else {
        return answeres[question._id];
      }
    }
  };

  // Filter options that allow write-in
  let allowWritein = question.options.filter(
    (item) => item.write_in && item._id === answer().value
  );
  // Function to handle radio button change
  const handleChange = (
    ratingId,
    value,
    text,
    routeToIndex,
    hideIndex,
    score_exclude,
    finishSurvey
  ) => {
    setComment("");
    setChecked(ratingId);
    setCheckedText(text);

    // Check if the survey type is "tablet" and it's the first question
    if (surveyType === "tablet" && currentQuestionIndex === 0) {
      // Clear answers if it's the first question on a tablet survey
      clearAnsweres_();
      // Calculate the percentage based on the current question's value and max score
      const percentage = (Number(value) / Number(question?.maxScore)) * 100;

      // Filter questions based on percentage for positive or neutral journey type
      if (percentage >= 70) {
        // Filter questions excluding the current question and those with negative journey type
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "positive"
          );

        setAllQuestions([question, ...filteredQuestion]);
      } else {
        // Filter questions excluding the current question and those with positive journey type
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "negative"
          );

        setAllQuestions([question, ...filteredQuestion]);
      }
    }

    // call hideQuestion if not finishing the survey and hide index is provided
    if (!finishSurvey && hideIndex?.length) {
      hideQuestion(hideIndex);
    }
    addAnswer_({
      id: question._id,
      value: ratingId,
      type: "id",
      score_exclude: score_exclude,
    });
    setIsFinishSurvey(finishSurvey);
    // Route to the specified index if not included in the hidden index
    if (!hideIndex?.includes(routeToIndex)) {
      setRouteToIndex(routeToIndex);
    }
  };

  const onSubmitAnswer = () => {
    if (question?.required && !checked) {
      toast.error("This Question is Required!");
      return;
    } else {
      const selectedOption = question?.options?.find(
        (opt) => opt?._id === checked
      );
      if (selectedOption?.write_in) {
        if (!comment?.trim()) {
          toast.error("Comment is Required!");
          return;
        }
      }
      if (comment) {
        addWriteIn_({
          id: question._id,
          write_in: comment,
          type: "text",
        });
      }
      if (!isFinishSurvey && routeToIndex) {
        headTonext(routeToIndex, question._id);
      }
      if (isFinishSurvey) {
        finishSurvey(isFinishSurvey, question);
      }
      onSend(checkedText, comment);
      setOpen(false);
    }
  };

  const onSkipQuestion = () => {
    onSend(checkedText);
    setOpen(false);
  };

  return (
    <>
      <div className="px-4 bg-white border-t border-gray-200">
        <div className="flex items-center space-x-3 h-18">
          <button
            onClick={() => setOpen(true)}
            type="button"
            className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
            style={{ color: survey_theme?.primaryColor }}
            data-toggle="modal"
            data-target="#selectSkills"
          >
            <svg
              className="w-5 height-1 mr-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
              <path
                fillRule="evenodd"
                d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                clipRule="evenodd"
              />
            </svg>
            Select
          </button>
          {!question?.required ? (
            <button
              onClick={() => onSkipQuestion()}
              type="button"
              className="inline-flex items-center justify-center flex-1 px-3 py-2.5 text-sm font-medium leading-4 transition-all duration-200 bg-transparent border border-purple-600 rounded-full shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
            >
              Skip
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      {open ? (
        <div>
          <Dialog
            fullScreen
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="mobile-view-modal"
            id="mobile-view-modal"
          >
            <DialogTitle id="mobile-view-modal-title">
              <div className="modal-header">
                <h5 className="mr-12 text-lg font-bold truncate">Select</h5>
                {/* close button */}
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setOpen(false)}
                  aria-label="Close"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </DialogTitle>

            <DialogContent id="mobile-view-modal-body">
              <div className="divide-y divide-gray-200">
                {question?.options?.map((option, k) => {
                  return (
                    <div
                      key={k}
                      className="flex items-center justify-between px-3 py-3 bg-white"
                    >
                      <label
                        htmlFor={`skill-${k}`}
                        className="flex-1 block text-base font-medium"
                      >
                        {option.text}
                        {option?.optionText ? (
                          <p
                            style={{
                              color: survey_theme?.primaryColor,
                              fontSize: "12px",
                            }}
                          >
                            {option?.optionText}
                          </p>
                        ) : (
                          ""
                        )}
                      </label>
                      <input
                        type="radio"
                        id={`skill-${k}`}
                        name="radioType"
                        value={option._id}
                        className="w-5 h-5 border-gray-300 rounded-full"
                        onChange={() => {
                          handleChange(
                            option._id,
                            option.value,
                            option.text,
                            option?.route_to_index,
                            option?.hide_questions,
                            option?.score_exclusion_selection,
                            option?.finish_survey === true
                          );
                        }}
                        checked={checked === option._id}
                      />
                    </div>
                  );
                })}
                {allowWritein?.length ? (
                  <TextField
                    id="outlined-required"
                    label="Comment"
                    variant="outlined"
                    className="p-2"
                    value={comment}
                    onChange={(e) => setComment(e?.target?.value)}
                    autoComplete="off"
                  />
                ) : null}
              </div>
            </DialogContent>
            <DialogActions id="mobile-view-modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  style={{
                    paddingTop: "0.75rem",
                    paddingBottom: "0.75rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                  type="button"
                  onClick={() => setOpen(false)}
                  className="flex items-center justify-center flex-1 w-full  text-base font-semibold text-gray-600 transition-all duration-200 bg-gray-300 rounded-full shadow-lg hover:text-white focus:text-white hover:bg-gray-600 focus:bg-gray-600"
                >
                  Discard
                </button>
                <button
                  style={{
                    backgroundColor: survey_theme?.primaryColor,
                    paddingTop: "0.75rem",
                    paddingBottom: "0.75rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                  type="button"
                  onClick={() => onSubmitAnswer()}
                  className="flex items-center justify-center flex-1 w-full text-base font-semibold text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
                >
                  Save
                </button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
// Redux mapping function to map state to props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Redux mapping function to map dispatch to props
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    addWriteIn_: (data) => dispatch(addWriteIn(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
  };
};
// Connects the RadioType component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(RadioType);
