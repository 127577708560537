import { makeStyles } from "@material-ui/core/styles";

export const ratingStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    flexWrap: "wrap",

    [theme.breakpoints.up("md")]: {
      marginRight: "30%",
    },
  },
  container2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,

    [theme.breakpoints.up("md")]: {
      marginRight: "30%",
    },
  },
  helpText: {
    fontSize: "0.8rem",
  },
}));
