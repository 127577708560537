import { toast } from "react-toastify";
var axios = require("axios");

const BaseURL = process.env.REACT_APP_BASE_URL;

const postData = async (url, body) => {
  var response = await fetch(`${BaseURL}/${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  });
  if (response.status === 500) {
    toast.error("Internal Server Error !");
  }
  var result = await response.json();

  return result;
};

const postData2 = (url, form_data) => {
  var result = axios
    .post(`${BaseURL}/${url}`, form_data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => console.log(err));

  return result;
};

const postData1 = async (url, body) => {
  let rec = JSON.parse(localStorage.getItem("LOGIN"));
  console.log(rec);
  let token = rec.data.token;

  var response = await fetch(`${BaseURL}/${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(body),
  });
  var result = await response.json();
  return result;
};

const postDataAndImage = async (url, formData, config) => {
  try {
    var response = await axios.post(`${BaseURL}/${url}`, formData, config);
    var result = response.data.RESULT;
    return result;
  } catch (e) {
    console.log();
  }
};

const getData = async (url) => {
  var jws_token = localStorage.getItem("jws_token");
  var response = await fetch(`${BaseURL}/${url}`, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: jws_token,
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  var result = await response.json();
  return result;
};

export { BaseURL, getData, postData, postData1, postData2, postDataAndImage };
