import { makeStyles } from "@material-ui/core/styles";

// Define common styles using Material-UI's makeStyles
export const commonPageMakeStyles = makeStyles((theme) => ({
  // Main container style
  container: {
    minHeight: "100vh",
    width: "100%",
    backgroundColor: theme.palette.background.main,
    alignItems: "center",
    display: "grid",
    overflow: "hidden",
  },

  // Inner container within main container
  innerContainer: {
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      minHeight: "90%",
    },
  },

  // Background color class
  bgPrimary: {
    backgroundColor: theme.palette.primary.light,
  },

  // Alternative main container style
  BgSecondcontainer: {
    minHeight: "90vh",
    width: "100%",
    backgroundColor: theme.palette.background.main,
    display: "grid",
    overflow: "hidden",
  },

  // Alternative inner container
  BgSecondinnerContainer: {
    display: "flex",
    paddingBottom: "118px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      minHeight: "90%",
    },
  },

  // Paper element style
  paperStyle: {
    width: "100vw",
    minHeight: "100%",
    padding: 40,
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      width: "70%",
      borderRadius: 20,
      minHeight: "90%",
      marginBottom: "2%",
    },
  },

  // Alternative paper element style
  paperStyleA: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      width: "70%",
      borderRadius: 20,
      minHeight: "90%",
      marginBottom: "2%",
    },
  },

  // Logo styles
  logo: {
    margin: "2% 0",
    height: "70px",
  },

  // Logo container
  logoContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },

  // Various text styles
  nearestTitle: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
    fontFamily: "Inter",
    textAlign: "center",
  },
  nearestSubTitle: {
    color: "black",
    fontFamily: "Inter",
    marginTop: "2%",
    marginBottom: "2%",
    textAlign: "center",
  },
  pageTitle: {
    fontWeight: 700,
    fontSize: "40px",
    color: theme.palette.primary.main,
    fontFamily: "Inter",
  },
  pageDesc: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#000000",
    fontFamily: "Inter",
    marginBottom: "6%",
  },

  // New styles for survey header and other elements
  topHeaderSurvery: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  topLogo: {
    width: "150px",
    margin: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
    },
  },
  oneQuestionCardNarrow: {
    borderRadius: "20px",
    width: "50%",
    alignSelf: "center",
    padding: "25px 45px",
    marginBottom: "15px",
    marginTop: "30px",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "25px",
    },
  },
  oneQuestionCard: {
    borderRadius: "20px",
    width: "70%",
    alignSelf: "center",
    padding: "25px 45px",
    marginBottom: "15px",
    marginTop: "30px",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "25px",
    },
  },

  // Fixed bottom progress bar
  BgBottomProgressBar: {
    background: "white",
    width: "100%",
    overflow: "hidden",
    padding: "35px 0px",
    position: "fixed",
    bottom: "0",
    left: "0%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0",
    },
  },

  // Inner progress bar
  BottomProgress: {
    width: "70%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },

  // Text styles for contact information
  contactParagraph: {
    textAlign: "center",
    color: "black",
    fontSize: "1rem",
  },
}));
