import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "70px 58px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  mainheading: {
    fontFamily: "Inter",
    fontSize: "35px",
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    width: "80%",
    textAlign: "center",
    margin: "auto",
  },
  nameBox: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
  },
}));
