import React from "react";
import { Box } from "@material-ui/core";
import { commonPageMakeStyles } from "../styles/commonPageStyles";
function Background(props) {
  const commonPageCss = commonPageMakeStyles();

  return (
    <Box className={commonPageCss.container}>
      <Box className={commonPageCss.innerContainer}>{props.children}</Box>
    </Box>
  );
}

export default Background;
