import React from "react";
import MdCard from "../common/MdCard";
import Line from "../../components/charts/Line";

function TrendedNps(props) {
  const { data: npsdataList = [] } = props;
  let chartData;
  if (npsdataList.length) {
    let score = npsdataList.map((item) => item?.score?.toFixed(2) ?? 0);
    let date = npsdataList.map((item) => item.date);
    chartData = {
      datasets: [
        {
          data: score,
        },
      ],
      labels: date,
    };
  }

  //   let data = {
  //     datasets: [
  //       {
  //         data: [20, 10],
  //       },
  //     ],
  //     labels: ["a", "b"],
  //   };

  // let data = {
  //   datasets: [
  //     {
  //       data: count,
  //     },
  //   ],
  //   labels: date,
  // };
  return (
    <MdCard title="Trended Net Promoter Score">
      <div
        style={{
          height: "90%",
          width: "90%",
          margin: "auto",
        }}
      >
        {npsdataList.length ? <Line data={chartData} /> : null}
      </div>
    </MdCard>
  );
}

export default TrendedNps;
