import React from "react";

const ClientHeader = ({ props }) => {
  const logo = localStorage.getItem("logo");

  return (
    <div className={`page-header ${props?.className}`}>
      <img src={logo} alt="brand-logo" className="brand-logo" />
    </div>
  );
};

export default ClientHeader;
