import { postData } from "../../components/FetchServices";

export const getDetails = async (location, forEmp) => {
  const body = {
    location: location,
    main_location:
      forEmp === "forEmp" ? location : localStorage.getItem("location_id"),
    api_version: "v2",
    source: "web",
  };
  const result = await postData("api/getAllData", body);
  if (result?.status === 200) {
    localStorage.setItem("showGoogle", result?.data?.showGoogleQr || false);
    localStorage.setItem("googleLink", result?.data?.googleReviewUrl || "");
    return result?.data;
  } else {
    localStorage.setItem("location_id", "");
  }
};
