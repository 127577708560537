// Importing required components and hooks from libraries
import { Button, Paper, TextField, useMediaQuery } from "@material-ui/core";
import { DoubleArrow } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { AnimatePresence } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ProgressBar from "react-scroll-progress-bar";
import { config, useSpring } from "react-spring";
import { toast } from "react-toastify";
import { ThemeUpdateContext } from "../../../App";
import {
  // Redux actions for manipulating survey data
  addAnswer,
  addContactDetails,
  addDirectorMessage,
  clearAnsweres,
  removeAnswer,
  setSurveyTheme,
} from "../../../redux/survey/actions";
import { getDetails } from "../../../webRatingNew/apiHelpers/frontRating";

// Commonly used components for the survey
import BackgroundSecond from "../../common/components/BackgroundSecond";
import QuestionWrapper from "../../common/components/QuestionWrapper";
import Rating from "../../common/components/Rating";
import { commonPageMakeStyles } from "../../common/styles/commonPageStyles";

// Helper functions for making API calls
import { axiosPostData, postData } from "../../helpers/fetch_services";

// Specific components for various types of survey questions
import { isMobile } from "react-device-detect";
import store from "../../../redux/store";
import ClientConnectDirector from "../ClientConnectDirector";
import Animate from "./Animate";
import BottomProgress from "./BottomProgress";
import Emoji from "./Emoji/Emoji";
import EmployeeSelect from "./EmployeeSelect";
import MultipleSelect from "./MultipleSelect";
import NotesAndImage from "./NotesAndImage";
import Radio from "./Radio";
import Sequence from "./Sequence/Sequence";
import StarRating from "./StarRating";
import TextArea from "./TextArea";
import Upload from "./Upload";
import NumberType from "./NumberType";
import SignatureInput from "./SignatureInput";
import "./questions.css";
import { isCompanyLogin } from "../../helpers/Utils";
import moment from "moment";

// Main React component for rendering survey questions
function Question(props) {
  // Destructuring props to extract relevant data
  const {
    survey,
    answeres,
    locations,
    directorMessage,
    survey_without_login,
    setSurveyTheme_,
    clearAnsweres_,
    addAnswer_,
    removeAnswer_,
    addContactDetails_,
    addDirectorMessage_,
    contactDetails,
  } = props;
  // Utilizing Context, Router, and Theme hooks
  const { changeTheme } = useContext(ThemeUpdateContext);
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const surveyCount = localStorage.getItem("total_location_survey")
    ? parseInt(localStorage.getItem("total_location_survey"))
    : 0;

  // Extracting and setting states from location and theme
  const surveyWithoutLogin =
    location.state !== undefined ? location.state.surveyWithoutLogin : "";
  const hasClientConnect =
    location.state !== undefined ? location.state.clientConnect : null;
  const visitDate =
    location.state !== undefined ? location.state.visitDate : null;
  const visitTimeIn =
    location.state !== undefined ? location.state.visitTimeIn : null;
  const guest_info =
    location.state !== undefined ? location.state.guest_info : null;

  const isMob = useMediaQuery(theme.breakpoints.down("sm"));
  // Custom styles for the survey page
  const commonPageCss = commonPageMakeStyles();

  // State hooks for local state management
  const [loading, setLoading] = useState(true);
  const [empLoading, setEmpLoading] = useState(true);

  // Various states for managing survey questions, types, and navigation
  const [qPerPage, setQPerPage] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [allQuestionsOriginal, setAllQuestionsOriginal] = useState([]);
  const [currentSurvey, setCurrentSurvey] = useState({});
  const [allQuestions, setAllQuestions] = useState([]);
  const [surveyType, setSurveyType] = useState("");
  const [page, setPage] = React.useState(1);
  const [direction, setDirection] = React.useState(1);
  const [logo, setLogo] = useState("/images/webRating/serve-first.png");
  const [greetingStatus, setGs] = useState(false);
  // State and hook for managing cookies
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(["survey"]);
  // const [pageBreaks, setPageBreaks] = useState([]);
  const [trimStart, setTrimStart] = useState(0);
  const [trimEnd, setTrimEnd] = useState(0);

  // Visit Time Out state
  const [selectedTimeOut, setSelectedTimeOut] = useState(null);

  // Calculating question indexes for pagination
  // var trimStart = (page - 1) * qPerPage;
  // var trimEnd = trimStart + qPerPage;

  const getPageBreaks = (questions, type = null) => {
    let pageBreaks = [];
    if (questions?.length) {
      const isNotPageBreak = questions
        ?.filter((q) => q.questionType !== "director_message")
        ?.every((que) => !que?.page_break);
      if (questions?.length && isNotPageBreak) {
        let quePerPage = survey_without_login?.questions_per_page;
        if (survey_without_login?.surveyType === "tablet") {
          setQPerPage(1);

          // if (survey_without_login?.leadershipUser) {
          //   setTotalPage(questions?.length + 1);
          // } else {
          setTotalPage(questions?.length);
          // }
          quePerPage = 1;
        } else {
          setQPerPage(quePerPage);
          const totalPages = Math.ceil(questions?.length / quePerPage);
          // if (survey_without_login?.leadershipUser) {
          //   setTotalPage(totalPages + 1);
          // } else {
          setTotalPage(totalPages);
          // }
        }
        const trimStart = (page - 1) * quePerPage;
        setTrimStart(trimStart);
        const trimEnd = trimStart + quePerPage - 1;

        setTrimEnd(trimEnd);
      } else {
        const filteredQues = questions?.filter(
          (q) => q?.questionType !== "skip"
        );
        filteredQues?.forEach((question, index) => {
          // Check if the current question is the last one
          const isLastQuestion = index === filteredQues?.length - 1;
          // Check if the question is of type "director_message"
          const isDirectorMessage =
            question.questionType === "director_message";
          // Check conditions for page breaks
          if (question.page_break && type !== "tablet") {
            // If last question and director_message, add page break
            if (isLastQuestion && isDirectorMessage) {
              pageBreaks.push(index - 1);
            } else if (!isLastQuestion) {
              // If not last question, add page break
              pageBreaks.push(index);
            }
          } else if (type === "tablet" && !isLastQuestion) {
            // If tablet type and not last question, add page break
            pageBreaks.push(index);
          }
        });
        pageBreaks = [...new Set(pageBreaks)];

        // Calculate trimStart based on page number
        const trimStart = page - 1 === 0 ? 0 : pageBreaks[page - 2] + 1;
        setTrimStart(trimStart);
        // Set total number of pages
        const totalPages = pageBreaks.length + 1;
        setTotalPage(totalPages);

        // Calculate trimEnd based on page number
        const trimEnd =
          page === totalPages ? questions.length - 1 : pageBreaks[page - 1];
        setTrimEnd(trimEnd);

        // Calculate number of questions per page
        const questionsPerPage = trimEnd - trimStart + 1;

        setQPerPage(questionsPerPage);
      }
    }

    // Return array of page breaks
    return pageBreaks;
  };

  useEffect(() => {
    getPageBreaks(allQuestions, surveyType);
  }, [page]);

  // States for managing contact details and their validation
  const [isHelpDesk, setIsHelpDesk] = useState(
    Object.keys(survey).includes("contactDetails") &&
      (survey.contactDetails.email !== null ||
        survey.contactDetails.phone !== null)
  );
  const [contactDetail, setContactDetail] = useState({
    name: null,
    email: null,
    phone: "",
  });
  const defaultContactErrors = {
    name: { error: false, message: "" },
    email: { error: false, message: "" },
    phone: { error: false, message: "" },
    emailOrPhone: { error: false, message: "" },
    emailValid: { error: false, message: "" },
  };
  const [contactDetailErrors, setContactDetailErrors] =
    useState(defaultContactErrors);

  // Function to update contact details
  const updateContactDetails = (e, key) => {
    let { value } = e.target;
    if (key) {
      if (key === "phone") {
        // Automatically add "+44" as a prefix
        // value = "+44" + value.slice(3);

        // Limit the input to a maximum of 10 digits excluding the "+44" prefix
        value = value.slice(0, 10);
      }
      if (key === "email") {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
          updateContactDetailError("emailValid", true, "");
        } else {
          updateContactDetailError("emailValid", false, "");
        }
      }
      setContactDetail((prev) => ({ ...prev, [key]: value }));
    }
  };

  // Function to update contact detail errors
  const updateContactDetailError = (key, value, message = "") => {
    if (key) {
      setContactDetailErrors((prev) => ({
        ...prev,
        [key]: { error: value, message },
      }));
    }
  };

  // Function to save contact details and validate them
  const saveContactDetails = () => {
    let hasErrors = false;

    if (!(contactDetail?.email || contactDetail?.phone)) {
      updateContactDetailError(
        "emailOrPhone",
        true,
        "Please enter your email address or phone number."
      );
      return;
    }

    // Validate email
    if (
      contactDetail?.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(contactDetail.email)
    ) {
      updateContactDetailError("email", true, "Invalid email address!");
      updateContactDetailError("emailValid", false, "");
      hasErrors = true;
    } else {
      updateContactDetailError("email", false, "");
    }

    // Validate phone
    if (contactDetail?.phone) {
      // const UK_PHONE_PATTERN = /^(?:\+44|0)\d{10}$|^\d{10}$/;
      const UK_PHONE_PATTERN = /^\d{10}$/;
      if (!UK_PHONE_PATTERN.test(contactDetail.phone)) {
        updateContactDetailError(
          "phone",
          true,
          "Invalid phone number format. Allowed formats are: +44 followed by 10 digits."
        );
        hasErrors = true;
      }
    } else {
      updateContactDetailError("phone", false, "");
    }

    if (hasErrors) {
      return;
    }
    // Add contact details to Redux
    if (contactDetail?.phone) {
      contactDetail.phone = `+44${contactDetail.phone}`;
    }
    addContactDetails_(contactDetail);
    setIsHelpDesk(false);
    if (isMobile && survey?.survey_without_login?.allow_conversational_view) {
      history.push({
        pathname: "/mobile-survey/questions",
        state: {
          surveyWithoutLogin: surveyWithoutLogin,
          visitDate: visitDate || null,
          visitTimeIn: visitTimeIn ? visitTimeIn : null,
          guest_info: guest_info || null,
        },
      });
    }
  };

  // Function to set brand-specific details
  const setBrandDetails = async (survey_id) => {
    const result = await postData("api/getBrandDetail", {
      survey_id,
    });
    if (result?.status === 200) {
      setLogo(
        result?.data?.logo
          ? result?.data?.logo
          : "/images/webRating/serve-first.png"
      );
      localStorage.setItem("logo", result?.data?.logo);
      setSurveyTheme_({
        primaryColor:
          result?.data?.primaryColor !== "undefined"
            ? result?.data?.primaryColor
            : "#36473F",
        secondaryColor:
          result?.data?.secondaryColor !== "undefined"
            ? result?.data?.secondaryColor
            : "#8FA280",
        backgroundColor:
          result?.data?.backgroundColor !== "undefined"
            ? result?.data?.backgroundColor
            : "#36473F",
        highlightColor:
          result?.data?.highightColor !== "undefined"
            ? result?.data?.highightColor
            : "#8FA280",
        fontColor:
          result?.data?.fontColor !== "undefined"
            ? result?.data?.fontColor
            : "#3a3a3a",
      });
      changeTheme({
        primaryColor:
          result?.data?.primaryColor !== "undefined"
            ? result?.data?.primaryColor
            : "#36473F",
        secondaryColor:
          result?.data?.secondaryColor !== "undefined"
            ? result?.data?.secondaryColor
            : "#8FA280",
        backgroundColor:
          result?.data?.backgroundColor !== "undefined"
            ? result?.data?.backgroundColor
            : "#36473F",
        highlightColor:
          result?.data?.highightColor !== "undefined"
            ? result?.data?.highightColor
            : "#8FA280",
        fontColor:
          result?.data?.fontColor !== "undefined"
            ? result?.data?.fontColor
            : "#3a3a3a",
      });
    }
  };

  useEffect(() => {
    changeTheme({
      primaryColor: props.survey_theme.primaryColor,
      secondaryColor: props.survey_theme.secondaryColor,
      backgroundColor: props.survey_theme.backgroundColor,
      highlightColor: props.survey_theme.highlightColor,
      fontColor: props.survey_theme.fontColor,
    });

    const locationId = localStorage.getItem("location");
    const surveyId = localStorage.getItem("survey");

    //Set Logo and Brand detail
    setBrandDetails(surveyId);

    if (surveyWithoutLogin) {
      const results = JSON.parse(JSON.stringify(survey_without_login));
      if (!locationId) {
        results.questions = results.questions.filter(
          (item) => item.questionType !== "employees"
        );
      }

      setCurrentSurvey(results);
      if (results.surveyType === "tablet") {
        // setQPerPage(1);
        setSurveyType(results.surveyType);
        const pageBreaks = getPageBreaks(
          survey_without_login?.leadershipUser ||
            survey_without_login?.visit_information
            ? [
                ...results.questions,
                { questionType: "director_message", page_break: true },
              ]
            : [...results.questions],
          "tablet"
        );
        const totalPages = pageBreaks?.length + 1;

        setAllQuestionsOriginal(results.questions);
        if (results?.leadershipUser || results?.visit_information) {
          // setTotalPage(totalPages);
          setAllQuestions([
            ...results.questions,
            { questionType: "director_message", page_break: true },
          ]);
        } else {
          // setTotalPage(totalPages);
          setAllQuestions(results.questions);
        }
      } else {
        setSurveyType(results.surveyType);
        // setQPerPage(results.questions_per_page);
        // const totalPages = Math.ceil(
        //   results.questions.length / results.questions_per_page
        // );

        const pageBreaks = getPageBreaks(
          survey_without_login?.leadershipUser ||
            survey_without_login?.visit_information
            ? [
                ...results.questions,
                { questionType: "director_message", page_break: true },
              ]
            : [...results.questions]
        );
        const totalPages = pageBreaks?.length + 1;

        if (results?.leadershipUser || results?.visit_information) {
          const offset =
            (totalPages * results.questions_per_page) %
            results.questions.length;
          // setTotalPage(totalPages);
          setAllQuestions([
            ...results.questions,
            // ...Array.from({ length: offset }, (_, i) => i).map(() => ({
            //   questionType: "skip",
            // })),
            { questionType: "director_message", page_break: true },
          ]);
        } else {
          // setTotalPage(
          //   Math.ceil(results.questions.length / results.questions_per_page)
          // );
          // setTotalPage(totalPages);
          setAllQuestions(results.questions);
        }
        setAllQuestionsOriginal(survey_without_login.questions);
      }

      if (results?.help_desk === true) {
        setIsHelpDesk(true);
      }

      if (
        isMobile &&
        survey?.survey_without_login?.allow_conversational_view &&
        !isHelpDesk &&
        !results?.help_desk
      ) {
        history.push({
          pathname: "/mobile-survey/questions",
          state: {
            surveyWithoutLogin: survey || currentSurvey,
            visitDate: visitDate || null,
            visitTimeIn: visitTimeIn ? visitTimeIn : null,
            guest_info: guest_info || null,
          },
        });
      }
    } else if (locations.length && locationId) {
      if (surveyId) {
        getQuestions();
      } else {
        toast("Please Select Survey !");
        history.push("/survey/audition");
      }
    } else {
      toast("Please Select Location!");
      history.push("/survey/location");
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!greetingStatus) setTimeout(() => setGs(true), 500);
  }, [page]);
  const slideProps = useSpring({
    from: { transform: "translateX(-100%)", opacity: 0 },
    to: [{ transform: "translateX(0)", opacity: 1 }],
    // reset: true,
    config: { duration: 400, ...config.molasses },
  });
  const fadeProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    // reset: true,
    config: { duration: 100, ...config.molasses },
  });
  const [Oldquestions, setOldquestions] = useState();

  const getQuestions = async () => {
    // Retrieve location and survey IDs from local storage
    const locationId = localStorage.getItem("location");
    const surveyId = localStorage.getItem("survey");
    let selectedSurvey;

    // If location ID exists, filter locations array to find selected location
    if (locationId) {
      const result = await postData("frontEmployeeFeedback/getSurvey", {
        survey_id: surveyId,
      });
      if (result?.success) {
        selectedSurvey = result?.data;
      }
    }
    // If a selected survey is found
    if (selectedSurvey) {
      const results = selectedSurvey;
      setCurrentSurvey(results);

      // Check survey type (tablet or not)
      if (results.surveyType === "tablet") {
        // Process tablet survey
        setSurveyType(results.surveyType);
        setAllQuestionsOriginal(results.questions);
        // Calculate page breaks for tablet survey
        const pageBreaks = getPageBreaks(
          // Append director message question if survey has leadership user
          survey_without_login?.leadershipUser ||
            survey_without_login?.visit_information
            ? [
                ...results.questions,
                { questionType: "director_message", page_break: true },
              ]
            : [...results.questions],
          "tablet"
        );
        const totalPages = pageBreaks?.length + 1;
        // Set all questions for tablet survey, including director message question if needed
        if (results?.leadershipUser || results?.visit_information) {
          setAllQuestions([
            ...results.questions,
            { questionType: "director_message", page_break: true },
          ]);
        } else {
          setAllQuestions(results.questions);
        }
      } else {
        // Process non-tablet survey
        setSurveyType(results.surveyType);
        setOldquestions(results.questions);
        // Calculate page breaks for non-tablet survey
        const pageBreaks = getPageBreaks(
          // Append director message question if survey has leadership user
          survey_without_login?.leadershipUser ||
            survey_without_login?.visit_information
            ? [
                ...results.questions,
                { questionType: "director_message", page_break: true },
              ]
            : [...results.questions]
        );

        const totalPages = pageBreaks?.length + 1;
        if (results?.leadershipUser || results?.visit_information) {
          const offset =
            (totalPages * results.questions_per_page) %
            results.questions.length;
          setAllQuestions([
            ...results.questions,
            // ...Array.from({ length: offset }, (_, i) => i).map(() => ({
            //   questionType: "skip",
            // })),
            { questionType: "director_message", page_break: true },
          ]);
        } else {
          setAllQuestions(results.questions);
        }
      }
      // Set help desk flag if help desk is enabled for the survey
      if (results?.help_desk === true) {
        setIsHelpDesk(true);
      }
    }
  };

  const submitAnswers = async () => {
    setLoading(true); // Sets loading state to true at the start of the function
    let filesArray = [];

    // Destructuring survey data including answers and contact details
    let answeresCopy = await { ...answeres };

    // Loop through answers to find and process file type questions
    for (let item in answeresCopy) {
      const question = allQuestions.find((question) => question._id === item);
      if (question !== undefined) {
        if ("isFile" in answeresCopy[item]) {
          answeresCopy[item].questionType =
            question?.questionType === "handwrittenInput"
              ? "base64"
              : question?.questionType;
          filesArray.push({ [item]: answeresCopy[item] });
        }
        // Format phone numbers if necessary
        if (
          question.questionType === "text" &&
          question.textType === "phone" &&
          answeresCopy[item].value !== ""
        ) {
          answeresCopy[
            item
          ].value = `+${answeresCopy[item].code}${answeresCopy[item].value}`;
          delete answeresCopy[item].code;
        }
        // Remove empty or null values from the answersCopy object
        if (
          !answeresCopy[item]?.value ||
          (Array.isArray(answeresCopy[item].value) &&
            answeresCopy[item].value.length === 0)
        ) {
          delete answeresCopy[item];
        }
      }
    }
    // Check if there are no answers provided
    if (!Object.keys(answeresCopy)?.length) {
      setLoading(false);
      toast.error(
        "You must provide at least one answer before submitting the survey."
      );
      return;
    }
    let surveyTotal = 0;
    for (let index = 0; index < allQuestions.length; index++) {
      const question = allQuestions[index];
      if (!allQuestions[index]?.skip) {
        surveyTotal += question?.maxScore ? question?.maxScore : 0;
      }
    }

    // Preparing metadata for the survey response
    const metaData = {
      survey_id: localStorage.getItem("survey") || "",
      location_id: localStorage.getItem("location") || "",
      response_user_id: localStorage.getItem("user_id") || "",
      tag_id: localStorage.getItem("tag_id")
        ? localStorage.getItem("tag_id")
        : localStorage.getItem("tag"),
      guest_info,
    };
    if (survey_without_login?.visit_information) {
      metaData.visitDate = visitDate;
      metaData.visitTimeIn = visitTimeIn;
      metaData.visitTimeOut = selectedTimeOut
        ? moment(selectedTimeOut).format("HH:mm")
        : null;
    }
    if (survey_without_login?.guest_info) {
      metaData.guest_info = guest_info;
    }

    // Handling audit and email data if available
    const audit = localStorage.getItem("audit") ?? null;
    const email = localStorage.getItem("email") ?? null;
    if (audit) {
      metaData.audit = audit;
      metaData.email = email;
    }

    // Add Login user detail
    const userEmail = localStorage.getItem("user_email") ?? null;
    if (userEmail) {
      metaData.email = userEmail;
    }

    // Consolidating response data from the contact details
    const responseData = {
      customer_name: contactDetails?.name,
      customer_email: contactDetails?.email,
      customer_phone: contactDetails?.phone,
    };

    // Add creator_id if available in localstorage
    const ticketCreatorId = localStorage.getItem("creator_id") ?? null;

    // Add source if available in localstorage
    const source = localStorage.getItem("source") ?? null;

    // Creating a FormData object for sending the survey response
    const form_data = new FormData();
    form_data.append("metaData", JSON.stringify(metaData));
    form_data.append("jsonData", JSON.stringify(answeresCopy));
    form_data.append("responseData", JSON.stringify(responseData));
    form_data.append("director_message", directorMessage);
    form_data.append("ticketCreatorId", ticketCreatorId);
    form_data.append("surveyTotal", surveyTotal);
    form_data.append("source", source);

    // Add company id to the FormaData
    const companyId = localStorage.getItem("company_id");
    form_data.append("company_id", companyId);

    // Adding files to the FormData if any exist
    if (filesArray.length) {
      filesArray.forEach((item) => {
        const key = Object.keys(item)[0];
        const { questionType } = item[key];
        let images = [];

        // Processing image files based on question type
        if (questionType === "file" || questionType === "handwrittenInput") {
          if (item[key]?.value?.length) {
            item[key].value.forEach((img) => images.push(img));
          }
          if (item[key]?.image?.length) {
            item[key].image.forEach((img) => images.push(img));
          }
        } else {
          images = item[key]?.image || [];
        }

        // Appending images to formData
        if (images.length) {
          images.forEach((image) => {
            form_data.append(key, image);
          });
        }
      });
    }

    try {
      /**
       *  DONOT DELETE
       *  This code is to add validation to allow one survey per browser
       */
      // const surveyCookie = cookies.survey;
      // if (surveyCookie?.survey_id !== localStorage.getItem("survey")) {
      // Making an API call to submit the survey answers
      const result = await axiosPostData("survey/answerSurvey", form_data);

      // On successful submission, redirect to thank you page
      if (result) {
        setCookie(
          "survey",
          {
            survey: "submited",
            survey_id: localStorage.getItem("survey") || "",
          },
          { path: "/", maxAge: 86400 }
        );

        if (surveyWithoutLogin) {
          localStorage.removeItem("location");
          history.push({
            pathname: "/surveyThankYou",
            state: {
              surveyWithoutLogin: true,
              uniqueshortcode: result?.data?.results?.uniqueshortcode || "",
            },
          });
        } else {
          history.push({
            pathname: "/surveyThankYou",
            state: {
              uniqueshortcode: result?.data?.results?.uniqueshortcode || "",
            },
          });
        }

        // setQuestionPerPage()
      }
      /**
       *  DONOT DELETE
       *  This code is to add validation to allow one survey per browser
       */
      // } else {
      //   history.push("/sameDaySurvey");
      // }
    } catch (e) {
      // Handling errors during submission
      console.log(e);
    }
    setLoading(false); // Sets loading state to false after function execution
  };

  const notAnsweredWriteInOption = () => {
    // eslint-disable-next-line array-callback-return
    const answeresCopy = store.getState().survey.answeres;
    return allQuestions.slice(trimStart, trimEnd + 1).find((e) => {
      if (e._id in answeresCopy) {
        const answerArr = Array.isArray(answeresCopy[e._id].value)
          ? answeresCopy[e._id].value
          : [answeresCopy[e._id].value];
        if (answeresCopy[e._id].value) {
          const selectedOptions =
            allQuestions
              ?.find((que) => que?._id === e._id)
              ?.options?.filter((opt) => answerArr?.includes(opt?._id)) || [];
          if (selectedOptions?.some((opt) => opt?.write_in)) {
            const writeIn = answeresCopy[e._id]?.write_in?.trim();
            if (!writeIn) {
              return true;
            }
          }
        }
      }
    });
  };

  const notAnsweredQ = () => {
    // eslint-disable-next-line array-callback-return
    const answeresCopy = store.getState().survey.answeres;
    return allQuestions.slice(trimStart, trimEnd + 1).find((e) => {
      if (e.required && !e?.skip) {
        if (e._id in answeresCopy) {
          if (!answeresCopy[e._id].value?.toString()?.length) {
            return true;
          }
        } else {
          return true;
        }
      }
    });
  };

  const minOptionChecked = () => {
    return allQuestions.slice(trimStart, trimEnd + 1).find((item) => {
      let bool; // Variable to store whether the condition is true
      if (item.questionType === "sequence") {
        // Check if the answered options are less than the minimum required
        if (answeres[item._id]?.value?.length < item?.min_options) {
          bool = true; // If the condition is true, set bool to true
        }
      }
      return bool;
    });
  };

  const minCharacterChecked = () => {
    return allQuestions.slice(trimStart, trimEnd + 1).find((item) => {
      let bool;
      // Check if the question type is 'text' and textType is 'textbox'
      if (item.questionType === "text" && item?.textType === "textbox") {
        // Check if the question has an answer
        if (item._id in answeres) {
          // Check if the length of the trimmed answer is less than the minimum required characters
          if (
            answeres[item._id].value &&
            answeres[item._id].value?.trim()?.length < item?.minimum_character
          ) {
            bool = true; // If the condition is true, set bool to true
          }
        }
      }
      return bool;
    });
  };

  const emailTypeChecked = () => {
    // Regular expression pattern to check for valid email addresses
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return allQuestions.slice(trimStart, trimEnd + 1).find((item) => {
      let bool; // Variable to hold the boolean result of validation
      // Check if the question is of type 'text' and subtype 'email'
      if (item.questionType === "text" && item?.textType === "email") {
        // Check if the question has an answer in the 'answeres' object
        if (item._id in answeres) {
          // Check if the answer value exists and does not match the email pattern
          if (
            answeres[item._id].value &&
            !pattern.test(answeres[item._id].value)
          ) {
            bool = true; // Set bool to true if validation fails
          }
        }
      }
      return bool;
    });
  };

  const phoneTypeChecked = () => {
    return allQuestions.slice(trimStart, trimEnd + 1).find((item) => {
      let bool;
      // Check if the question is of type 'text' and subtype 'phone'
      if (item.questionType === "text" && item?.textType === "phone") {
        // Check if the question has an answer in the 'answeres' object
        if (item._id in answeres) {
          // Check if the answer value exists and its length is not equal to 10
          if (
            answeres[item._id].value &&
            answeres[item._id].value?.length !== 10
          ) {
            bool = true; // Set bool to true if validation fails
          }
        }
      }
      return bool;
    });
  };

  const numberTypeChecked = () => {
    return allQuestions.slice(trimStart, trimEnd + 1).find((item) => {
      let bool = false;
      // Check if the question is of type 'number'
      if (item.questionType === "number") {
        // Check if the question has an answer in the 'answeres' object
        if (item._id in answeres) {
          // Check if the answer value exists and is greater than the maximum allowed number value
          if (
            answeres[item._id].value &&
            Number(item.max_number_value) &&
            Number(item.max_number_value) > 0 &&
            Number(answeres[item._id]?.value) > Number(item.max_number_value)
          ) {
            bool = true; // Set bool to true if validation fails
          }
        }
      }

      return bool;
    });
  };

  // Initialize state variables to store error messages
  const [error, setError] = useState();
  const [minError, setMinError] = useState();
  const [minCharError, setMinCharError] = useState();
  const [emailError, setEmailError] = useState();
  const [phoneError, setPhoneError] = useState();
  const [numberError, setNumberError] = useState();
  const [writeInError, setWriteInError] = useState();
  const [visitTimeError, setVisitTimeError] = useState(false);

  // Effect hook to handle validation of various question types when answers change
  useEffect(() => {
    // Retrieve questions that need validation
    const notAnswered = notAnsweredQ();
    const minOptionCheck = minOptionChecked();
    const minCharCheck = minCharacterChecked();
    const emailChecked = emailTypeChecked();
    const phoneChecked = phoneTypeChecked();
    const numberChecked = numberTypeChecked();

    // Clear error states if validation passes for corresponding questions
    if (minOptionCheck?._id !== minError?._id) {
      setMinError(null);
    }

    if (notAnswered?._id !== error?._id) {
      setError(null);
    }
    if (minCharCheck?._id !== minCharError?._id) {
      setMinCharError(null);
    }
    if (emailChecked?._id !== emailError?._id) {
      setEmailError(null);
    }
    if (phoneChecked?._id !== phoneError?._id) {
      setPhoneError(null);
    }
    // Set number error state if validation fails, otherwise clear it
    if (numberChecked) {
      setNumberError(numberChecked);
    } else {
      setNumberError(null);
    }
    setWriteInError(null);
  }, [answeres]); // Run the effect whenever 'answeres' state changes

  const myRef = useRef(null);
  useEffect(() => {
    myRef?.current?.scrollIntoView();
  }, [error]);

  useEffect(async () => {
    clearAnsweres_();
    const location = localStorage.getItem("location");
    const logoLocal = localStorage.getItem("logo");
    if (logoLocal) {
      setLogo(logoLocal);
    }
    if (location && !isMobile) {
      setEmpLoading(true);
      const detail = await getDetails(location, "forEmp");
      if (detail) {
        localStorage.setItem("employees", JSON.stringify(detail?.employee));
      }
      setEmpLoading(false);
    }
  }, []);

  const contidionalAutoNext = async (
    routeToIndex,
    question,
    indexToHide,
    selectedId,
    questionID
  ) => {
    let currentQuestionIndex = "";
    // Finding the index of the current question in the array of all questions
    for (let index = 0; index < allQuestions.length; index++) {
      const element = allQuestions[index]._id === questionID;
      if (element) {
        currentQuestionIndex = index;
      }
    }

    // Create a new array of questions with the "skip" property set to true for certain questions
    let lastIndex = allQuestions.length - 1;
    const greyOutQestion = allQuestions.map((item, index) => {
      if (index < routeToIndex && index > currentQuestionIndex) {
        // Add empty answer and mark the question as skipped
        addAnswer_({
          id: item._id,
          value: "",
          type: "id",
        });
        removeAnswer_({ id: item._id });
        return { ...item, skip: true };
      } else {
        if (index > currentQuestionIndex) {
          return { ...item, skip: false };
        } else {
          return { ...item, skip: item.skip ?? false };
        }
      }
    });
    // Set state with the updated greyed-out questions
    setAllQuestions(greyOutQestion);

    // Create a new array of original questions with the "skip" property set to true for certain questions
    const allGreyOutQestion = allQuestionsOriginal.map((item, index) => {
      if (index < routeToIndex && index > currentQuestionIndex) {
        addAnswer_({
          id: item._id,
          value: "",
          type: "id",
        });

        return { ...item, skip: true };
      } else {
        if (index > currentQuestionIndex) {
          return { ...item, skip: false };
        } else {
          return { ...item, skip: item.skip ?? false };
        }
      }
    });

    // Set state with the updated greyed-out questions in the original question set
    setAllQuestionsOriginal(allGreyOutQestion);

    const hideQues = []; // Initialize an empty array to store the questions to be hidden

    // Logic to determine questions to be hidden based on selected options
    if (indexToHide !== undefined) {
      const locationId = localStorage.getItem("location");
      // Create a copy of the answeres state
      const answeresCopy = await answeres;

      // Loop through the questions and find the ones that need to be hidden
      for (let index = 0; index < allQuestions.length; index++) {
        // if (allQuestions[index]._id in answeresCopy) {
        let prevAnswer = answeresCopy[allQuestions[index]._id];

        // Check if the question is a radio, rating, or emoji question and has a value
        if (
          ["radio", "rating", "emoji"]?.includes(
            allQuestions[index]?.questionType
          ) &&
          prevAnswer?.value
        ) {
          const ans =
            allQuestions[index]._id === questionID
              ? selectedId
              : prevAnswer?.value;
          const selectedOption = allQuestions[index].options?.find(
            (opt) => opt?._id === ans
          );
          if (selectedOption) {
            hideQues?.push(...selectedOption?.hide_questions);
          }
        }
        if (
          ["multiselect", "sequence"]?.includes(
            allQuestions[index]?.questionType
          ) &&
          prevAnswer?.value
        ) {
          const ans =
            allQuestions[index]._id === questionID
              ? selectedId
              : prevAnswer?.value;

          const selectedOption = allQuestions[index].options?.filter((opt) =>
            ans?.includes(opt?._id)
          );

          if (selectedOption?.length) {
            hideQues?.push(
              ...selectedOption.flatMap((opt) => opt.hide_questions)
            );
          }
        }
        // }
      }
      // Combine indices to be hidden
      const allHiddenIndex = [...hideQues, ...indexToHide];
      // Filter out questions to be hidden based on their indices
      let filteredQuestions = allGreyOutQestion?.filter(
        (_, i) => !allHiddenIndex?.includes(i)
      );

      // Remove answers for the hidden questions
      let hiddenQuestions = allGreyOutQestion?.filter((que, i) =>
        indexToHide?.includes(i)
      );
      hiddenQuestions?.forEach((hiddenQue) =>
        removeAnswer_({ id: hiddenQue?._id })
      );

      // If no location ID is available, filter out questions related to employees
      if (!locationId) {
        filteredQuestions = filteredQuestions.filter(
          (item) => item.questionType !== "employees"
        );
      }

      // Append additional questions based on conditions
      const allQues =
        survey_without_login?.leadershipUser ||
        survey_without_login?.visit_information
          ? [
              ...filteredQuestions,
              { questionType: "director_message", page_break: true },
            ]
          : [...filteredQuestions];
      getPageBreaks(allQues, surveyType);
      // Update the state with the filtered questions
      setAllQuestions(allQues);
      lastIndex = allQues.length - 1;
    }
    // Conditional logic for auto-navigation
    if (qPerPage === 1) {
      let notAnswered = notAnsweredQ();
      let minOptionCheck = minOptionChecked();
      let minCharCheck = minCharacterChecked();
      let emailChecked = emailTypeChecked();
      let phoneChecked = phoneTypeChecked();
      let numberChecked = numberTypeChecked();
      let notWriteIN = notAnsweredWriteInOption();

      if (
        notAnswered === undefined &&
        minOptionCheck === undefined &&
        minCharCheck === undefined &&
        emailChecked === undefined &&
        phoneChecked === undefined &&
        numberChecked === undefined &&
        notWriteIN === undefined
      ) {
        if (routeToIndex !== null && routeToIndex !== undefined) {
          setPage(routeToIndex + 1);
          setDirection(1);
        }
        // else if (totalPage !== page) {
        //   setGs(false);
        //   setPage(page + 1);
        //   setDirection(1);
        // }
      } else if (notAnswered) {
        setError(notAnswered);
        myRef?.current?.scrollIntoView();
      } else if (minOptionCheck) {
        setMinError(minOptionCheck);
        myRef?.current?.scrollIntoView();
      } else if (minCharCheck) {
        setMinCharError(minCharCheck);
        myRef?.current?.scrollIntoView();
      } else if (emailChecked) {
        setEmailError(emailChecked);
        myRef?.current?.scrollIntoView();
      } else if (phoneChecked) {
        setPhoneError(phoneChecked);
        myRef?.current?.scrollIntoView();
      } else if (numberChecked) {
        setNumberError(numberChecked);
        myRef?.current?.scrollIntoView();
      } else if (notWriteIN) {
        setWriteInError(notWriteIN);
        myRef?.current?.scrollIntoView();
      }
    } else if (qPerPage !== 1) {
      if (routeToIndex !== null && routeToIndex !== undefined) {
        const RouteToQuestionIndex = routeToIndex + 1;
        if (RouteToQuestionIndex === lastIndex) {
          setPage(page + 1);
          setDirection(1);
        } else {
          setPage(page);
          setDirection(1);
        }
      }
    }
  };

  const [submitSurvey, setSubmitSurvey] = useState(false);
  useEffect(() => {
    if (submitSurvey) {
      submitAnswers();
    }
  }, [submitSurvey]);

  const finishSurvey = (shouldFinish, question) => {
    if (!shouldFinish) {
      return;
    }
    let currentQuestionIndex = "";
    // Finding the index of the current question in the array of all questions
    for (let index = 0; index < allQuestions.length; index++) {
      const element = allQuestions[index]._id === question._id;
      if (element) {
        currentQuestionIndex = index;
      }
    }
    for (
      let index = currentQuestionIndex + 1;
      index < allQuestions.length;
      index++
    ) {
      const questionId = allQuestions[index]._id;
      removeAnswer_({ id: questionId });
    }
    // Set submitSurvey state to true to indicate survey completion
    setSubmitSurvey(true);
  };

  return (
    <>
      {isHelpDesk ? (
        <div
          className={`${commonPageCss.bgPrimary} pb-5`}
          style={{ minHeight: "100vh" }}
        >
          <div className={commonPageCss.topHeaderSurvery}>
            <img className={commonPageCss.topLogo} src={logo} alt="logo" />
          </div>
          <Paper
            className={`${commonPageCss.oneQuestionCardNarrow} pt-4 mt-5`}
            style={{ minHeight: "80vh !important" }}
          >
            <h2 className="text-center my-3" style={{ fontSize: "2rem" }}>
              Contact Information
            </h2>

            <div
              style={{ fontSize: "1rem", color: "black !important" }}
              className={commonPageCss.contactParagraph}
            >
              To provide enhanced assistance, we kindly ask for your details,
              including your name, email address, and contact number. This
              information will enable us to respond promptly and contact you.
            </div>

            <div className="mt-5">
              <h5 className="font-weight-normal">Name</h5>
              <div className="pl-1 mt-1">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter your name"
                  value={contactDetail.name}
                  onChange={(e) => updateContactDetails(e, "name")}
                />
              </div>
              {contactDetailErrors.name.error ? (
                <p className="small text-danger pt-1">
                  {contactDetailErrors.name.message}
                </p>
              ) : null}
            </div>

            <div className="mt-4">
              <h5 className="font-weight-normal">
                Email <span style={{ color: "red" }}>*</span>
              </h5>
              <div className="pl-1 mt-1">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter your email"
                  value={contactDetail.email}
                  onChange={(e) => updateContactDetails(e, "email")}
                />
              </div>
              {contactDetailErrors.email.error ? (
                <p className="small text-danger pt-1">
                  {contactDetailErrors.email.message}
                </p>
              ) : null}
            </div>

            <div className="mt-4">
              <h5 className="font-weight-normal">
                Contact No
                {!contactDetailErrors?.emailValid?.error ? (
                  <span style={{ color: "red" }}>*</span>
                ) : (
                  ""
                )}
              </h5>
              <div className="pl-1 mt-1 input-box">
                <span class="prefix">+44</span>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={contactDetail.phone}
                  type="number"
                  onChange={(e) => updateContactDetails(e, "phone")}
                  className="contact-number pl-2"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </div>
              {contactDetailErrors.phone.error ? (
                <p className="small text-danger pt-1">
                  {contactDetailErrors.phone.message}
                </p>
              ) : null}
            </div>

            <div className="mt-4">
              {contactDetailErrors.emailOrPhone.error ? (
                <p className="small text-danger pt-1">
                  {contactDetailErrors.emailOrPhone.message}
                </p>
              ) : null}
            </div>

            <div className="d-flex justify-content-end align-items-center mt-5 mb-3">
              {/* <Button */}
              {/*   size="large" */}
              {/*   variant="contained" */}
              {/*   color="dark" */}
              {/*   onClick={() => setIsHelpDesk(false)} */}
              {/*   style={{ width: "125px" }} */}
              {/* > */}
              {/*   Skip */}
              {/* </Button> */}

              <Button
                size="large"
                variant="contained"
                color="primary"
                endIcon={<DoubleArrow />}
                onClick={() => saveContactDetails()}
              >
                Continue
              </Button>
            </div>
          </Paper>
        </div>
      ) : (
        <LoadingOverlay active={loading} spinner>
          <ProgressBar bgcolor={theme.palette.primary.main} />
          <div className={commonPageCss.topHeaderSurvery}>
            <img className={commonPageCss.topLogo} src={logo} alt="logo" />
          </div>
          <BackgroundSecond>
            {greetingStatus && (
              <div style={{ width: "100%" }}>
                <AnimatePresence custom={direction}>
                  {allQuestions.length
                    ? allQuestions
                        .slice(trimStart, trimEnd + 1)
                        .map((question, i) => (
                          <Animate direction={direction} key={i}>
                            {question.questionType === "skip" ? null : (
                              <Paper
                                className={commonPageCss.oneQuestionCard}
                                style={{ opacity: question.skip && "0.50" }}
                              >
                                {/* {page === 1 && i === 0 ? ( */}
                                {/*   <> */}
                                {/*     {currentSurvey?.description ? ( */}
                                {/*       <div className="text-center text-dark pb-4"> */}
                                {/*         {ReactHtmlParser( */}
                                {/*           currentSurvey.description */}
                                {/*         )} */}
                                {/*       </div> */}
                                {/*     ) : ( */}
                                {/*       <div */}
                                {/*         className="h3 text-center pb-4" */}
                                {/*         style={{ */}
                                {/*           color: */}
                                {/*             survey.survey_theme.primaryColor, */}
                                {/*         }} */}
                                {/*       > */}
                                {/*         Please give us your feedback */}
                                {/*       </div> */}
                                {/*     )} */}
                                {/*   </> */}
                                {/* ) : null} */}
                                <QuestionWrapper
                                  question={question}
                                  questionType={question.questionType}
                                  isGreyOut={question.skip ?? null}
                                  questionNumber={trimStart + i + 1}
                                  required={question.required}
                                  questionTitle={question.text}
                                  questionSubTitle={question.subText}
                                  showCategory={currentSurvey.show_category}
                                  questionCategory={question.category}
                                  myRef={
                                    error?._id === question._id ||
                                    minError?._id === question._id
                                      ? myRef
                                      : null
                                  }
                                  hideQuestionNumber={
                                    currentSurvey.hide_question_number
                                  }
                                >
                                  {question.questionType === "rating" ? (
                                    <Rating
                                      isGreyOut={question.skip ?? null}
                                      finishSurvey={finishSurvey}
                                      headTonext={(
                                        routeToIndex,
                                        hideIndex,
                                        selectedId
                                      ) =>
                                        contidionalAutoNext(
                                          routeToIndex,
                                          question,
                                          hideIndex,
                                          selectedId,
                                          question._id
                                        )
                                      }
                                      question={question}
                                      pageNo={page}
                                      surveyType={surveyType}
                                      allQuestionsOriginal={
                                        allQuestionsOriginal
                                      }
                                      setAllQuestions={setAllQuestions}
                                      setTotalPage={setTotalPage}
                                    />
                                  ) : question.questionType === "radio" ? (
                                    <Radio
                                      isGreyOut={question.skip ?? null}
                                      finishSurvey={finishSurvey}
                                      headTonext={(
                                        routeToIndex,
                                        hideIndex,
                                        selectedId
                                      ) =>
                                        contidionalAutoNext(
                                          routeToIndex,
                                          question,
                                          hideIndex,
                                          selectedId,
                                          question._id
                                        )
                                      }
                                      question={question}
                                      pageNo={page}
                                      surveyType={surveyType}
                                      allQuestionsOriginal={
                                        allQuestionsOriginal
                                      }
                                      setAllQuestions={setAllQuestions}
                                      setTotalPage={setTotalPage}
                                    />
                                  ) : question.questionType === "file" ? (
                                    <Upload
                                      isGreyOut={question.skip ?? null}
                                      question={question}
                                    />
                                  ) : question.questionType === "text" ? (
                                    <TextArea
                                      isGreyOut={question.skip ?? null}
                                      question={question}
                                    />
                                  ) : ["multiselect", "skills"].includes(
                                      question.questionType
                                    ) ? (
                                    <MultipleSelect
                                      isGreyOut={question.skip ?? null}
                                      question={question}
                                      pageNo={page}
                                      surveyType={surveyType}
                                      allQuestionsOriginal={
                                        allQuestionsOriginal
                                      }
                                      setAllQuestions={setAllQuestions}
                                      setTotalPage={setTotalPage}
                                      headTonext={(
                                        routeToIndex,
                                        hideIndex,
                                        selectedId
                                      ) =>
                                        contidionalAutoNext(
                                          routeToIndex,
                                          question,
                                          hideIndex,
                                          selectedId,
                                          question._id
                                        )
                                      }
                                    />
                                  ) : question.questionType === "sequence" ? (
                                    <Sequence
                                      isGreyOut={question.skip ?? null}
                                      question={question}
                                      surveyType={surveyType}
                                      pageNo={page}
                                      allQuestionsOriginal={
                                        allQuestionsOriginal
                                      }
                                      setAllQuestions={setAllQuestions}
                                      setTotalPage={setTotalPage}
                                      headTonext={(
                                        routeToIndex,
                                        hideIndex,
                                        selectedId
                                      ) =>
                                        contidionalAutoNext(
                                          routeToIndex,
                                          question,
                                          hideIndex,
                                          selectedId,
                                          question._id
                                        )
                                      }
                                    />
                                  ) : //   : question.questionType === "skills" ? (
                                  //   <Skills
                                  //     isGreyOut={question.skip ?? null}
                                  //     question={question}
                                  //     pageNo={page}
                                  //     surveyType={surveyType}
                                  //     allQuestionsOriginal={allQuestionsOriginal}
                                  //     setAllQuestions={setAllQuestions}
                                  //     setTotalPage={setTotalPage}
                                  //     SkillsData={SkillsData}
                                  //   />
                                  // )
                                  question.questionType === "emoji" ? (
                                    <Emoji
                                      isGreyOut={question.skip ?? null}
                                      headTonext={(
                                        routeToIndex,
                                        hideIndex,
                                        selectedId
                                      ) =>
                                        contidionalAutoNext(
                                          routeToIndex,
                                          question,
                                          hideIndex,
                                          selectedId,
                                          question._id
                                        )
                                      }
                                      question={question}
                                      pageNo={page}
                                      surveyType={surveyType}
                                      allQuestionsOriginal={
                                        allQuestionsOriginal
                                      }
                                      setAllQuestions={setAllQuestions}
                                      setTotalPage={setTotalPage}
                                    />
                                  ) : question.questionType === "employees" ? (
                                    <EmployeeSelect
                                      // headTonext={(
                                      //   routeToIndex,
                                      //   hideIndex,
                                      //   selectedId
                                      // ) =>
                                      //   contidionalAutoNext(
                                      //     routeToIndex,
                                      //     question,
                                      //     hideIndex,
                                      //     selectedId,
                                      //     question._id
                                      //   )
                                      // }
                                      question={question}
                                      loading={empLoading}
                                    />
                                  ) : question.questionType ===
                                    "director_message" ? (
                                    <ClientConnectDirector
                                      clientConnect={
                                        currentSurvey.clientConnect
                                      }
                                      leadershipUser={
                                        currentSurvey.leadershipUser
                                      }
                                      value={directorMessage}
                                      onChange={(e) =>
                                        addDirectorMessage_(e.target.value)
                                      }
                                      isVisitInformation={
                                        currentSurvey?.visit_information
                                      }
                                      selectedTimeOut={selectedTimeOut}
                                      setSelectedTimeOut={(time) => {
                                        setSelectedTimeOut(time);
                                        setVisitTimeError(false);
                                      }}
                                    />
                                  ) : question.questionType ===
                                    "star_rating" ? (
                                    <StarRating
                                      isGreyOut={question.skip ?? null}
                                      question={question}
                                    />
                                  ) : question.questionType === "number" ? (
                                    <NumberType
                                      isGreyOut={question.skip ?? null}
                                      question={question}
                                    />
                                  ) : question.questionType ===
                                    "handwrittenInput" ? (
                                    <SignatureInput
                                      isGreyOut={question.skip ?? null}
                                      question={question}
                                    />
                                  ) : (
                                    ""
                                  )}

                                  {question.questionType !==
                                  "director_message" ? (
                                    <>
                                      {question?.allow_comment ||
                                      question?.allow_file_upload ? (
                                        <NotesAndImage
                                          isGreyOut={question.skip ?? null}
                                          question={question}
                                        />
                                      ) : null}
                                      {error?._id === question._id && (
                                        <p
                                          style={{
                                            color: "red",
                                            marginTop: "8px",
                                            fontSize: isMob ? "14px" : "16px",
                                          }}
                                        >
                                          This Question is Required!
                                        </p>
                                      )}
                                      {writeInError?._id === question._id && (
                                        <p
                                          style={{
                                            color: "red",
                                            marginTop: "8px",
                                            fontSize: isMob ? "14px" : "16px",
                                          }}
                                        >
                                          Comment is Required!
                                        </p>
                                      )}
                                      {minError?._id === question._id && (
                                        <p>
                                          minimum selection is{" "}
                                          {question.min_options}
                                        </p>
                                      )}
                                      {minCharError?._id === question._id && (
                                        <p
                                          style={{
                                            color: "red",
                                            marginTop: "8px",
                                            fontSize: isMob ? "14px" : "16px",
                                          }}
                                        >
                                          Please enter at least{" "}
                                          {question?.minimum_character}{" "}
                                          characters.
                                        </p>
                                      )}
                                      {emailError?._id === question._id && (
                                        <p
                                          style={{
                                            color: "red",
                                            marginTop: "8px",
                                            fontSize: isMob ? "14px" : "16px",
                                          }}
                                        >
                                          Please enter valid email address.
                                        </p>
                                      )}
                                      {phoneError?._id === question._id && (
                                        <p
                                          style={{
                                            color: "red",
                                            marginTop: "8px",
                                            fontSize: isMob ? "14px" : "16px",
                                          }}
                                        >
                                          Phone number should be 10 digit.
                                        </p>
                                      )}
                                      {numberError?._id === question._id && (
                                        <p
                                          style={{
                                            color: "red",
                                            marginTop: "8px",
                                            fontSize: isMob ? "14px" : "16px",
                                          }}
                                        >
                                          Please enter a number less than or
                                          equal to {question?.max_number_value}{" "}
                                        </p>
                                      )}
                                    </>
                                  ) : question.questionType ===
                                      "director_message" && visitTimeError ? (
                                    <p
                                      style={{
                                        color: "red",
                                        marginTop: "8px",
                                        fontSize: isMob ? "14px" : "16px",
                                      }}
                                    >
                                      Please Select Time
                                    </p>
                                  ) : null}
                                </QuestionWrapper>
                              </Paper>
                            )}
                          </Animate>
                        ))
                    : null}
                </AnimatePresence>
              </div>
            )}
            <div className={commonPageCss.BgBottomProgressBar}>
              <div className={commonPageCss.BottomProgress}>
                <BottomProgress
                  answered={hasClientConnect ? page + 2 : page}
                  total={hasClientConnect ? totalPage + 2 : totalPage}
                  submitDisabled={loading}
                  PrevisDisable={
                    loading ||
                    (page === 1 &&
                      !hasClientConnect &&
                      (isCompanyLogin() ? surveyCount === 1 : true))
                  }
                  handlePrev={() => {
                    if (loading) {
                      return;
                    }
                    if (hasClientConnect && page === 1) {
                      history.push({
                        pathname: "/survey/client_connect",
                        state: { surveyWithoutLogin: true, fromQuestion: true },
                      });
                    }
                    if (page !== 1) {
                      setGs(false);
                      setPage(page - 1);
                      setDirection(-1);
                    }
                    if (isCompanyLogin() && page === 1 && surveyCount > 1) {
                      history.push({
                        pathname: "/survey/audition",
                        state: { surveyWithoutLogin: survey },
                      });
                    }
                  }}
                  nextTitle={!(totalPage !== page) ? "Submit" : "Next Question"}
                  handleNext={() => {
                    if (loading) {
                      return;
                    }
                    let notAnswered = notAnsweredQ();
                    let minOptionCheck = minOptionChecked();
                    let minCharCheck = minCharacterChecked();
                    let emailChecked = emailTypeChecked();
                    let phoneChecked = phoneTypeChecked();
                    let numberChecked = numberTypeChecked();
                    let notWriteIN = notAnsweredWriteInOption();

                    if (
                      notAnswered === undefined &&
                      minOptionCheck === undefined &&
                      minCharCheck === undefined &&
                      emailChecked === undefined &&
                      phoneChecked === undefined &&
                      numberChecked === undefined &&
                      notWriteIN === undefined
                    ) {
                      if (totalPage !== page) {
                        setGs(false);
                        setPage(page + 1);
                        setDirection(1);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth", // for smooth scrolling
                        });
                      } else {
                        if (
                          currentSurvey?.visit_information &&
                          selectedTimeOut === null
                        ) {
                          setVisitTimeError(true);
                        } else {
                          submitAnswers();
                        }
                      }
                    } else if (notAnswered) {
                      setError(notAnswered);
                      myRef?.current?.scrollIntoView();
                    } else if (minOptionCheck) {
                      setMinError(minOptionCheck);
                      myRef?.current?.scrollIntoView();
                    } else if (minCharCheck) {
                      setMinCharError(minCharCheck);
                      myRef?.current?.scrollIntoView();
                    } else if (emailChecked) {
                      setEmailError(emailChecked);
                      myRef?.current?.scrollIntoView();
                    } else if (phoneChecked) {
                      setPhoneError(phoneChecked);
                      myRef?.current?.scrollIntoView();
                    } else if (numberChecked) {
                      setNumberError(numberChecked);
                      myRef?.current?.scrollIntoView();
                    } else if (notWriteIN) {
                      setWriteInError(notWriteIN);
                      myRef?.current?.scrollIntoView();
                    }
                  }}
                />
              </div>
            </div>
          </BackgroundSecond>
        </LoadingOverlay>
      )}
    </>
  );
}
// Function that extracts the necessary state from the Redux store
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
    locations: state.survey.locations,
    survey_without_login: state.survey.survey_without_login,
    survey_theme: state.survey.survey_theme,
    survey: state.survey,
    directorMessage: state.survey.directorMessage,
    contactDetails: state.survey.contactDetails,
  };
};
// Function that binds the dispatch to action creators
const mapDispatchToProps = (dispatch) => {
  return {
    addContactDetails_: (data) => dispatch(addContactDetails(data)),
    addDirectorMessage_: (data) => dispatch(addDirectorMessage(data)),
    addAnswer_: (data) => dispatch(addAnswer(data)),
    removeAnswer_: (data) => dispatch(removeAnswer(data)),
    setSurveyTheme_: (data) => dispatch(setSurveyTheme(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
  };
};
// Connect Question Component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Question);
