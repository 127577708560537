import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.light,
  },
  checked: {
    "&$checked": {
      color: theme.palette.secondary.main,
    },
  },
  label: {
    color: theme.palette.primary.main,
  },
  labelActive: {
    color: theme.palette.secondary.main,
  },
  mainBox: {
    height: "8rem",
    width: "8rem",
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: 5,
    marginBottom: "1rem",
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      width: "10rem",
      height: "10rem",
    },
  },
  logoBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "85%",
  },
  checkbox: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 5,
    paddingTop: 5,
    alignItems: "center",
    textAlign: "center",
    height: "15%",
  },
  sequence: {
    position: "absolute",
    margin: 5,
    width: 18,
    height: 18,
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.dark,
  },
}));
