import React, { useEffect, useRef, useState } from "react";
import { Fab } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { FaPaperclip } from "react-icons/fa";
import { connect } from "react-redux";
import { addAnswer, addImage } from "../../../../redux/survey/actions";
import { toast } from "react-toastify";
import "./question.css";
import { allowedMimeTypes } from "../../../../Survey/helpers/mimeTypes";
import { compressFiles } from "../../../../utils/compressFiles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function FileUploadType(props) {
  const { question, addAnswer_, addImage_, type, survey_theme, onSend } = props;
  const fileInputRef = useRef(null);
  const [file, setFile] = useState([]);
  const handleFabClick = () => {
    // Trigger a click on the hidden input when the Fab is clicked
    fileInputRef.current.click();
  };

  const handleImageUpload = (e) => {
    const selectedFiles = e?.target?.files;
    if (selectedFiles && selectedFiles.length > 0) {
      // Check if all selected files are images
      const allFilesAreAllowed = Array.from(selectedFiles).every((file) =>
        allowedMimeTypes.includes(file.type)
      );

      if (allFilesAreAllowed) {
        // compress large image file
        compressFiles(selectedFiles).then((compressedFiles) => {
          setFile([...compressedFiles]);
        });
      } else {
        toast.error("Please select valid image or video files.");
      }
    }
  };

  const onSubmitAnswer = () => {
    if (question?.required && !file) {
      toast.error("This Question is Required!");
      return;
    } else {
      if (type === "note") {
        addImage_({
          id: question._id,
          image: file,
          isFile: true,
        });
      } else {
        addAnswer_({
          id: question._id,
          value: file,
          isFile: true,
        });
      }
      const slectedImages = file
        ? file?.map((f) => URL.createObjectURL(f))
        : [];
      onSend(file?.length ? slectedImages : "");
    }
  };

  return (
    <div className="px-2 bg-white border-t border-gray-200">
      <div className="flex items-center justify-end space-x-3 h-18">
        {file?.length ? (
          <>
            <div className="progress-bar-container">
              <div
                className="progress-bar"
                style={{ backgroundColor: survey_theme?.primaryColor }}
              ></div>
            </div>
          </>
        ) : null}
        <Fab
          aria-label="add"
          onClick={handleFabClick}
          sx={{
            "& .MuiTouchRipple-root .MuiTouchRipple-child": {
              borderRadius: "8px",
            },
          }}
        >
          <FaPaperclip />
          <VisuallyHiddenInput
            type="file"
            ref={fileInputRef}
            onChange={(e) => handleImageUpload(e)}
            accept={allowedMimeTypes?.join(",")}
            multiple
          />
        </Fab>

        <button
          onClick={onSubmitAnswer}
          type="submit"
          className="flex items-end justify-center flex-shrink-0 p-3 text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
          style={{
            backgroundColor: survey_theme?.primaryColor,
          }}
        >
          <svg
            className="w-6 h-6 rotate-90"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
          </svg>
        </button>
      </div>
    </div>
  );
}
// This is the mapStateToProps function that extracts the necessary state from the Redux store
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// This is the mapDispatchToProps function that binds the dispatch function to action creators
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    addImage_: (data) => dispatch(addImage(data)),
  };
};
// Connects the FileUploadType component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(FileUploadType);
