import React from "react";
import { connect } from "react-redux";
import { addAnswer, clearAnsweres } from "../../../../redux/survey/actions";
import emoji1 from "../../../../assets/images/web_rating/emoji_1.gif";
import emoji2 from "../../../../assets/images/web_rating/emoji_2.gif";
import emoji3 from "../../../../assets/images/web_rating/emoji_3.gif";
import emoji4 from "../../../../assets/images/web_rating/emoji_4.gif";
import emoji5 from "../../../../assets/images/web_rating/emoji_5.gif";
import "../NewRadioButton.css";
const emojiImage = [emoji1, emoji2, emoji3, emoji4, emoji5];

const Emoji = (props) => {
  // Destructuring props
  const {
    headTonext,
    question,
    addAnswer_,
    pageNo,
    surveyType,
    allQuestionsOriginal,
    setAllQuestions,
    setTotalPage,
    clearAnsweres_,
    isGreyOut,
    answeres,
  } = props;
  // Function to handle Emoji selection
  const handleChange = (event, value, routeToIndex, hideIndex) => {
    if (isGreyOut) return;
    if (surveyType === "tablet" && pageNo === 1) {
      clearAnsweres_();
      // Calculate percentage based on value and maximum score of the question
      const percentage = (Number(value) / Number(question?.maxScore)) * 100;
      // Filter questions based on percentage and journey type
      if (percentage >= 70) {
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "positive"
          );

        setTotalPage(filteredQuestion.length + 1);

        setAllQuestions([question, ...filteredQuestion]);
      } else {
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "negative"
          );

        setTotalPage(filteredQuestion.length + 1);

        setAllQuestions([question, ...filteredQuestion]);
      }
    }
    // Add answer to Redux state
    addAnswer_({
      id: question._id,
      value: value,
      type: "id",
    });
    // Navigate to next question
    headTonext(routeToIndex, hideIndex, value);
  };

  return (
    <div className="w-100 py-2 py-md-4 d-flex align-items-baseline justify-content-start">
      {question?.options.map((item) => {
        return (
          <>
            <div
              key={item._id}
              className="px-2 px-md-4 px-lg-5"
              style={{
                width: "20%",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  borderRadius: "50%",
                  padding: "2px",
                  border:
                    answeres[question._id]?.value === item._id
                      ? "2px solid gainsboro"
                      : "2px solid white",
                }}
              >
                <img
                  alt="emoji"
                  src={emojiImage[item.value - 1]}
                  handleChange={(event) =>
                    handleChange(
                      event,
                      item._id,
                      item?.route_to_index,
                      item?.hide_questions
                    )
                  }
                  onClick={(event) =>
                    handleChange(
                      event,
                      item._id,
                      item?.route_to_index,
                      item?.hide_questions
                    )
                  }
                />
              </div>
              {item?.optionText && (
                <p className="text-center pt-3" style={{ fontSize: "13px" }}>
                  {item?.optionText}
                </p>
              )}
            </div>
          </>
        );
      })}
    </div>
  );
};
// This is the mapStateToProps function that extracts the necessary state from the Redux store
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// This is the mapDispatchToProps function that binds the dispatch function to action creators
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
  };
};
// Connect component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Emoji);
