import React from "react";
import ClientContent from "../content";
import ClientDirector from "../director";
import ClientIntro from "../intro/";
import ClientSurvey from "../survey";

const ClientStep = ({ step, survey, theme, surveyErrors, onSurveyFinish }) => {
  switch (step) {
    case 1:
      return <ClientIntro theme={theme} survey={survey} />;
    case 2:
      return <ClientContent survey={survey} />;
    case 3:
      return (
        <ClientSurvey
          survey={survey}
          surveyErrors={surveyErrors}
          onSurveyFinish={onSurveyFinish}
        />
      );
    case 4:
      return <ClientDirector survey={survey} />;
    default:
      return null;
  }
};

function ClientBody({
  survey,
  currentStep,
  theme,
  surveyErrors,
  onSurveyFinish,
}) {
  const currentSurvey = survey.survey_without_login;

  return (
    <div className="page-body mx-auto">
      <ClientStep
        theme={theme}
        step={currentStep}
        survey={currentSurvey}
        surveyErrors={surveyErrors}
        onSurveyFinish={onSurveyFinish}
      />
    </div>
  );
}

export default ClientBody;
