import React, { useState } from "react";
import { connect } from "react-redux";
import { addAnswer, clearAnsweres } from "../../../../redux/survey/actions";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/styles";
import { Tooltip } from "@material-ui/core";

const RatingType = (props) => {
  // Destructuring props
  const {
    finishSurvey,
    headTonext,
    question,
    answeres,
    addAnswer_,
    survey_theme,
    onSend,
    clearAnsweres_,
    allQuestionsOriginal,
    setAllQuestions,
    surveyType,
    currentQuestionIndex,
    hideQuestion,
  } = props;

  // State variables
  const [checked, setChecked] = useState("");
  const [checkedText, setCheckedText] = useState("");
  const [isFinishSurvey, setIsFinishSurvey] = useState(false);
  const [routeToIndex, setRouteToIndex] = useState(null);
  // State to control the tooltip
  const [openTooltip, setOpenTooltip] = useState(null);
  let answer = answeres[question._id] ?? "";

  const handleRating = (
    ratingId,
    value,
    text,
    routeToIndex,
    hideIndex,
    score_exclude,
    finishSurvey
  ) => {
    setChecked(ratingId);
    setCheckedText(text);
    // Check if the survey type is "tablet" and it's the first question
    if (surveyType === "tablet" && currentQuestionIndex === 0) {
      // Clear answers if it's the first question on a tablet survey
      clearAnsweres_();
      // Calculate the percentage based on the current question's value and max score
      const percentage = (Number(value) / Number(question?.maxScore)) * 100;
      // Filter questions based on percentage for positive or neutral journey type
      if (percentage >= 70) {
        // Filter questions excluding the current question and those with negative journey type
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "positive"
          );

        setAllQuestions([question, ...filteredQuestion]);
      } else {
        // Filter questions excluding the current question and those with positive journey type
        const filteredQuestion = allQuestionsOriginal
          .filter((item) => item._id !== question._id)
          .filter(
            (item) =>
              item.journey_type === "neutral" ||
              item.journey_type === "negative"
          );

        setAllQuestions([question, ...filteredQuestion]);
      }
    }
    // call hideQuestion if not finishing the survey and hide index is provided
    if (!finishSurvey && hideIndex?.length) {
      hideQuestion(hideIndex);
    }
    addAnswer_({
      id: question._id,
      value: ratingId,
      type: "id",
      score_exclude: score_exclude,
    });
    setIsFinishSurvey(finishSurvey);
    // Route to the specified index if not included in the hidden index
    if (!hideIndex?.includes(routeToIndex)) {
      setRouteToIndex(routeToIndex);
    }
    // Open the tooltip for the clicked emoji
    setOpenTooltip(ratingId);
  };

  const onSubmitAnswer = () => {
    if (question?.required && !checked) {
      toast.error("This Question is Required!");
      return;
    } else {
      if (!isFinishSurvey && routeToIndex) {
        headTonext(routeToIndex, question._id);
      }
      if (isFinishSurvey) {
        finishSurvey(isFinishSurvey, question);
      }
      onSend(checkedText);
    }
  };

  const handleTooltipClose = () => {
    setOpenTooltip(null);
  };

  const CustomTooltip = withStyles({
    tooltip: {
      color: "white",
      backgroundColor: survey_theme?.primaryColor,
      fontSize: "0.8rem",
      top: "0.7rem",
    },
    arrow: {
      color: survey_theme?.primaryColor,
    },
  })(Tooltip);

  return (
    <>
      <div className="px-2 bg-white border-t border-gray-200">
        <div className="row pt-2 px-2">
          <div className="rate-btn p-1">
            <div className="row col-12 pr-0" style={{ gap: "3px" }}>
              {question?.options.map((item, index) => (
                <CustomTooltip
                  title={item?.optionText || ""}
                  placement="top"
                  open={openTooltip === item._id}
                  onClose={() => handleTooltipClose()}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                >
                  <div
                    key={index}
                    className="col-1"
                    style={{
                      border: "1px solid #404040",
                      borderRadius: "5px",
                      minHeight: "35px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      backgroundColor:
                        answer.value === item._id ? "#e8e8e8" : "white",
                    }}
                    onClick={() => {
                      handleRating(
                        item._id,
                        item.value,
                        item.text,
                        item?.route_to_index,
                        item?.hide_questions,
                        item?.score_exclusion_selection,
                        item?.finish_survey === true
                      );
                    }}
                  >
                    {item.text}
                  </div>
                </CustomTooltip>
              ))}
            </div>
          </div>
          <div className="submit-btn p-0 pb-1">
            <button
              onClick={onSubmitAnswer}
              type="submit"
              className="flex items-end justify-center p-3 flex-shrink-0 text-white transition-all duration-200 rounded-full shadow-lg hover:bg-black focus:bg-black"
              style={{
                backgroundColor: survey_theme?.primaryColor,
                marginLeft: "auto",
              }}
            >
              <svg
                className="w-6 h-6 rotate-90"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
// Redux mapping function to map state to props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Redux mapping function to map dispatch to props
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    clearAnsweres_: () => dispatch(clearAnsweres()),
  };
};
// Connects the RatingType component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(RatingType);
