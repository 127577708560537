import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  questionNumber: {
    fontFamily: "Inter",
    color: "#475569",
    fontWeight: 500,
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontWeight: 600,
      fontSize: "15px",
    },
  },
  questionTitle: {
    fontFamily: "Inter",
    color: "#475569",
    fontSize: "18px",
    wrap: "auto",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
      fontWeight: 600,
    },
  },
  questionSubTitle: {
    fontFamily: "Inter",
    fontWeight: "Regular",
    color: "#94a3b8",
    fontSize: "15px",
    marginBottom: "10px",
    wrap: "auto",
    fontStyle: "italic",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      marginBottom: "10px",
    },
  },
}));

function QuestionWrapper(props) {
  const {
    question,
    questionNumber,
    questionTitle,
    questionSubTitle,
    myRef,
    required,
    isGreyOut,
    showCategory,
    questionCategory,
    questionType,
    hideQuestionNumber,
  } = props;
  const classes = useStyles(props);

  return (
    <Box
      style={{ width: "100%", paddingLeft: "5px", marginBottom: "3%" }}
      ref={myRef}
    >
      {questionType !== "director_message" ? (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          {!hideQuestionNumber ? (
            <Typography className={classes.questionNumber}>
              {questionNumber && questionNumber}.&nbsp;
            </Typography>
          ) : null}
          <Box>
            {showCategory ? (
              <Typography className={classes.questionTitle}>
                {questionCategory}
              </Typography>
            ) : null}
            <Typography className={classes.questionTitle}>
              {questionTitle && questionTitle}
              {required && <span>*</span>}
              {question?.unit ? (
                <span className="ml-1">
                  {question?.unit ? `(${question.unit})` : null}
                </span>
              ) : null}
            </Typography>
            <Typography className={classes.questionSubTitle}>
              {questionSubTitle ?? ""}
            </Typography>
          </Box>
        </Box>
      ) : null}

      {question?.images?.length ? (
        <div className="ml-2 mt-1 mb-3 d-flex align-items-center">
          {question.images.map((image) => (
            <div
              style={{
                marginRight: "1rem",
                border: "1px solid gainsboro",
                padding: "3px",
                borderRadius: "4px",
              }}
            >
              <img
                src={image}
                alt="question"
                width="125px"
                height="125px"
                style={{ objectFit: "contain" }}
              />
            </div>
          ))}
        </div>
      ) : null}

      {props.children}
    </Box>
  );
}

export default QuestionWrapper;
