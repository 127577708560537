import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThemeUpdateContext } from "../../App";
import {
  resetFrontratingAnsweres,
  setFrontratingAnsweres,
} from "../../redux/frontWebRating/actions";
import BottomBar from "../CommonComponents/BottomBar";
import Alphiga from "../PositiveJourny/components/Alphiga";
import EmployeExperience from "./components/EmployeExperience";
import EmployePersonalDetail from "./components/EmployePersonalDetail";

function NegativeJourny(props) {
  const { changeTheme } = useContext(ThemeUpdateContext);
  const { setFrontratingAnsweres_ } = props;
  const pageOptions = ["EmployeExperience", "EmployePersonalDetail"];
  // if (props.webRatingData?.googleQrImage && props.webRatingData?.showGoogleQr) {
  //   pageOptions.push("GoogleQrPage");
  // }
  props.webRatingData.is_alphiga && pageOptions.unshift("Alphiga");
  const [page, setPage] = React.useState(pageOptions[0]);
  const [showAlphigaQ, setShowAlphigaQ] = React.useState(false);

  const handleYes = () => {
    setShowAlphigaQ("Yes");
  };
  const handleNo = () => {
    setShowAlphigaQ("No");
    setFrontratingAnsweres_({
      key: "medicine_for",
      value: "",
    });
    setFrontratingAnsweres_({
      key: "symptoms",
      value: "",
    });
    setFrontratingAnsweres_({
      key: "symptoms_duration",
      value: "",
    });
    setFrontratingAnsweres_({
      key: "action_taken",
      value: "",
    });
    setFrontratingAnsweres_({
      key: "other_medication",
      value: "",
    });
    setPage(pageOptions[1]);
  };

  const history = useHistory();
  const isLastPage = () => {
    const activePageIndex = pageOptions.indexOf(page);
    if (activePageIndex === pageOptions.length - 1) {
      return true;
    }
    return false;
  };

  const isFirstPage = () => {
    const activePageIndex = pageOptions.indexOf(page);
    if (activePageIndex === 0) {
      return true;
    }
    return false;
  };
  const onNextClick = () => {
    const activePageIndex = pageOptions.indexOf(page);
    !isLastPage() && setPage(pageOptions[activePageIndex + 1]);
  };

  const onPrevClick = () => {
    const activePageIndex = pageOptions.indexOf(page);
    if (!isFirstPage()) {
      setPage(pageOptions[activePageIndex - 1]);
    } else {
      props.resetFrontratingAnsweres_();
    }
  };

  if (
    !props.webRatingAnsweres.journey ||
    props.webRatingAnsweres.journey === "pos"
  ) {
    // props.resetFrontratingAnsweres_();
    history.push("/frontRating");
  }
  useEffect(() => {
    changeTheme({
      primaryColor: props.webRatingData.app_color,
      secondaryColor: "#ffa6c9",
    });
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div
        style={{
          flex: "1 1 auto",
          overflow: "auto",
        }}
      >
        {page === "Alphiga" ? (
          <Alphiga
            showAlphigaQ={showAlphigaQ}
            handleYes={handleYes}
            handleNo={handleNo}
          />
        ) : page === "EmployeExperience" ? (
          <EmployeExperience />
        ) : page === "EmployePersonalDetail" ? (
          <EmployePersonalDetail />
        ) : // ) : page === "GoogleQrPage" && props?.webRatingData?.showGoogleQr ? (
        //   <GoogleQrPage />
        null}
      </div>
      <BottomBar
        page={page}
        pageOptions={pageOptions}
        isLastPage={isLastPage()}
        isFirstPage={isFirstPage()}
        onNext={onNextClick}
        onPrev={onPrevClick}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    webRatingAnsweres: state.frontWebRatingDataReducer.webRatingAnsweres,
    webRatingData: state.frontWebRatingDataReducer.webRatingData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetFrontratingAnsweres_: () => dispatch(resetFrontratingAnsweres()),
    setFrontratingAnsweres_: (data) => dispatch(setFrontratingAnsweres(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NegativeJourny);
