export const LOCATION_STATUS = {
  UNKNOWN: "unknown",
  ALLOWED: "allowed",
  BLOCKED: "blocked",
};

export const getLatLong = (
  setlatLong,
  setLocationStatus,
  setShowAllLocation
) => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setlatLong({
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
        setLocationStatus(LOCATION_STATUS.ALLOWED);
      },
      (error) => {
        console.log(error);
        setShowAllLocation(true);
        setLocationStatus(LOCATION_STATUS.BLOCKED);
      }
    );
  }
};
