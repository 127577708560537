import imageCompression from "browser-image-compression";

export const allowedImageMimeTypes = ["image/png", "image/jpg", "image/jpeg"];

// Function to compress files
export const compressFiles = (files) => {
  const fileSize = 1 * 1024 * 1024; // 1 MB threshold for compression

  // Process each file asynchronously using Promise.all
  return Promise.all(
    Array.from(files).map(async (file) => {
      // Check if the file size is greater than or equal to the threshold
      if (file.size >= fileSize && allowedImageMimeTypes.includes(file.type)) {
        try {
          const options = {
            initialQuality: getCompressionQuality(file.size), // Set the initial quality for compression
            alwaysKeepResolution: true, // Keep the original resolution of the image
            fileType: "image/jpeg", // Set the output file type to JPEG
          };

          // Compress the file using the specified options
          const compressedFile = await imageCompression(file, options);

          // Create a new File object with the compressed file data
          const compressedFileObject = new File([compressedFile], file.name, {
            type: compressedFile.type, // Set the file type of the new File object
            lastModified: Date.now(), // Set the last modified date to the current date
          });

          // Return the compressed file object
          return compressedFileObject;
        } catch (error) {
          console.error("Compression error:", error);
          return file; // If compression fails, return the original file
        }
      } else {
        return file; // If the file size is less than or equal to the threshold, return the original file
      }
    })
  );
};

export const getCompressionQuality = (imageSize) => {
  if (imageSize > 5000000) {
    // > 5MB
    return 0.3;
  } else if (imageSize > 2000000) {
    // 2MB - 5MB
    return 0.5;
  } else if (imageSize > 1000000) {
    // 1MB - 2MB
    return 0.7;
  } else {
    return 0.9;
  }
};
