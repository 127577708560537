import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
// import BottomBar from "./components/BottomBar";
import { useContext } from "react";
import { connect } from "react-redux";
import { ThemeUpdateContext } from "../../App";
import {
  resetFrontratingAnsweres,
  setFrontratingAnsweres,
} from "../../redux/frontWebRating/actions";
import BottomBar from "../CommonComponents/BottomBar";
import GoogleQrPage from "../CommonComponents/GoogleQrPage";
import Alphiga from "./components/Alphiga";
import EmployeDetail from "./components/EmployeDetail";
import EmployeService from "./components/EmployeService";
import EmployeeSelection from "./components/EmployeeSelection";

function PositiveJourny(props) {
  const { changeTheme } = useContext(ThemeUpdateContext);
  const { setFrontratingAnsweres_ } = props;
  let pageOptions = ["EmployeeSelection", "EmployeDetail", "EmployeService"];
  // if (
  //   props?.webRatingData?.googleQrImage &&
  //   props?.webRatingData?.showGoogleQr
  // ) {
  //   pageOptions.push("GoogleQrPage");
  // }
  props.webRatingData.hide_team && pageOptions.shift();
  props.webRatingData.is_alphiga && pageOptions.unshift("Alphiga");
  const [page, setPage] = React.useState(pageOptions[0]);
  const [showAlphigaQ, setShowAlphigaQ] = React.useState(false);

  const handleYes = () => {
    setShowAlphigaQ("Yes");
  };
  const handleNo = () => {
    setShowAlphigaQ("No");
    setFrontratingAnsweres_({
      key: "medicine_for",
      value: "",
    });
    setFrontratingAnsweres_({
      key: "symptoms",
      value: "",
    });
    setFrontratingAnsweres_({
      key: "symptoms_duration",
      value: "",
    });
    setFrontratingAnsweres_({
      key: "action_taken",
      value: "",
    });
    setFrontratingAnsweres_({
      key: "other_medication",
      value: "",
    });
    setPage(pageOptions[1]);
  };

  const isLastPage = () => {
    const activePageIndex = pageOptions.indexOf(page);
    if (activePageIndex === pageOptions.length - 1) {
      return true;
    }
    return false;
  };

  const isFirstPage = () => {
    const activePageIndex = pageOptions.indexOf(page);
    if (activePageIndex === 0) {
      return true;
    }
    return false;
  };

  const history = useHistory();

  const onNextClick = () => {
    const activePageIndex = pageOptions.indexOf(page);

    !isLastPage() && setPage(pageOptions[activePageIndex + 1]);
  };

  const onPrevClick = () => {
    const activePageIndex = pageOptions.indexOf(page);

    if (!isFirstPage()) {
      setPage(pageOptions[activePageIndex - 1]);
    } else {
      props.resetFrontratingAnsweres_();
    }
  };

  if (
    !props.webRatingAnsweres.journey ||
    props.webRatingAnsweres.journey === "neg"
  ) {
    // props.resetFrontratingAnsweres_();
    history.push("/frontRating");
  }

  useEffect(() => {
    changeTheme({
      primaryColor: props.webRatingData.app_color,
      secondaryColor: props.webRatingData.app_color,
    });
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div
        style={{
          flex: "1 1 auto",
          overflow: "auto",
        }}
      >
        {page === "Alphiga" ? (
          <Alphiga
            showAlphigaQ={showAlphigaQ}
            handleYes={handleYes}
            handleNo={handleNo}
          />
        ) : page === "EmployeeSelection" ? (
          <EmployeeSelection onClick={onNextClick} />
        ) : page === "EmployeDetail" ? (
          <EmployeDetail />
        ) : page === "EmployeService" ? (
          <EmployeService />
        ) : page === "GoogleQrPage" && props?.webRatingData?.showGoogleQr ? (
          <GoogleQrPage />
        ) : null}
      </div>
      <BottomBar
        page={page}
        pageOptions={pageOptions}
        isLastPage={isLastPage()}
        isFirstPage={isFirstPage()}
        onNext={onNextClick}
        onPrev={onPrevClick}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    webRatingAnsweres: state.frontWebRatingDataReducer.webRatingAnsweres,
    webRatingData: state.frontWebRatingDataReducer.webRatingData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetFrontratingAnsweres_: () => dispatch(resetFrontratingAnsweres()),
    setFrontratingAnsweres_: (data) => dispatch(setFrontratingAnsweres(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PositiveJourny);
