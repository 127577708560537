import React, { useEffect } from "react";

import { connect } from "react-redux";
import { useStyles } from "../SurveyThankYou/style";
import {
  clearAnswersAndTags,
  clearSurvey,
} from "../../../redux/survey/actions";

function SameDaySurvey(props) {
  // Destructing props
  const { clearAnswersAndTags_, clearSurveyWithoutLogin } = props;
  const backgroundClasses = useStyles();

  useEffect(() => {
    clearAnswersAndTags_();
    clearSurveyWithoutLogin();
  }, []);
  return (
    <div style={{ minHeight: "100%" }}>
      <section className={`login-wrapper ${backgroundClasses.backgroundImg}`}>
        <div className={backgroundClasses.container}>
          <img
            src="/images/webRating/cil_x-circle.png"
            style={{ width: "12vw" }}
            alt="checkicon"
          />
          <h2 className={backgroundClasses.thankyouText}>
            You have already performed this survey !
          </h2>
        </div>
        <img
          src="/images/webRating/powerbywhite.png"
          alt="powerby"
          style={{ position: "absolute", bottom: 9 }}
        />
      </section>
    </div>
  );
}

// Map Redux dispatch functions to component props
const mapDispatchToProps = (dispatch) => {
  return {
    clearAnswersAndTags_: () => dispatch(clearAnswersAndTags()),
    clearSurveyWithoutLogin: () => dispatch(clearSurvey()),
  };
};
// Connecting the component to the Redux store
export default connect(() => {}, mapDispatchToProps)(SameDaySurvey);
