import {
  ADD_ANSWER,
  ADD_NOTE,
  ADD_IMAGE,
  SET_TAGS,
  SET_LOCATIONS,
  ADD_WRITEIN,
  CLEAR_ANSWERES,
  CLEAR_ANSWERESANDTAGS,
  CLEAR_ANSWERES_AND_LOCATIONS,
  SET_SUREY_THEME,
  SET_SURVEY,
  CLEAR_SURVEY,
  SET_GLOBAL_SURVEY,
  SET_IS_SURVEY_WITHOUT_LOGIN,
  ADD_CONTACT_DETAILS,
  CLEAR_CONTACT_DETAILS,
  REMOVE_ANSWER,
  ADD_DIRECTOR_MESSAGE,
  SET_GOOGLE_RATINGS_DETAILS,
  CLEAR_GOOGLE_RATINGS_DETAILS,
} from "./constants";

export const addContactDetails = (payload) => {
  return {
    type: ADD_CONTACT_DETAILS,
    payload,
  };
};

export const addDirectorMessage = (payload) => {
  return {
    type: ADD_DIRECTOR_MESSAGE,
    payload,
  };
};

export const addAnswer = (payload) => {
  return {
    type: ADD_ANSWER,
    payload,
  };
};

export const removeAnswer = (payload) => {
  return {
    type: REMOVE_ANSWER,
    payload,
  };
};

export const addNote = (payload) => {
  return {
    type: ADD_NOTE,
    payload,
  };
};

export const addWriteIn = (payload) => {
  return {
    type: ADD_WRITEIN,
    payload,
  };
};

export const addImage = (payload) => {
  return {
    type: ADD_IMAGE,
    payload,
  };
};
export const setTags = (payload) => {
  return {
    type: SET_TAGS,
    payload,
  };
};

export const setLocations = (payload) => {
  return {
    type: SET_LOCATIONS,
    payload,
  };
};

export const clearAnsweres = () => {
  return {
    type: CLEAR_ANSWERES,
  };
};

export const clearAnswersAndTags = () => {
  return {
    type: CLEAR_ANSWERESANDTAGS,
  };
};

export const clearContactDetails = () => {
  return {
    type: CLEAR_CONTACT_DETAILS,
  };
};

export const clearAnswersAndLocations = () => {
  return {
    type: CLEAR_ANSWERES_AND_LOCATIONS,
  };
};

export const setSurveyTheme = (payload) => {
  return {
    type: SET_SUREY_THEME,
    payload,
  };
};

export const setSurvey = (payload) => {
  return {
    type: SET_SURVEY,
    payload,
  };
};
export const clearSurvey = () => {
  return {
    type: CLEAR_SURVEY,
  };
};

export const setGlobalSurveys = (payload) => {
  return {
    type: SET_GLOBAL_SURVEY,
    payload,
  };
};

export const setIsSurveyWithoutLogin = (payload) => {
  return {
    type: SET_IS_SURVEY_WITHOUT_LOGIN,
    payload,
  };
};
export const setGoogleRatingsDetails = (payload) => {
  return {
    type: SET_GOOGLE_RATINGS_DETAILS,
    payload,
  };
};
export const clearGoogleRatingsDetails = () => {
  return {
    type: CLEAR_GOOGLE_RATINGS_DETAILS,
  };
};
