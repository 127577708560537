import React from "react";
import { useTheme } from "@material-ui/core";
import user_image from "../../assets/images/mobile_emoji/lady.jpg";
import { connect } from "react-redux";

function SingleQuestion({
  text,
  onClickYes,
  onClickNo,
  onClickSkip,
  canAnswer = true,
  ...props
}) {
  const theme = useTheme();
  const { alphegaActive } = props;

  return (
    <>
      <div className="flex items-end justify-start pr-8 transition-all duration-200 m-t-30">
        <div className="flex-shrink-0 w-8 h-8 overflow-hidden rounded-full">
          <img
            className="object-cover w-full h-full"
            src={user_image}
            alt="user_demo"
          />
        </div>
        <div
          className="ml-3 text-white rounded-bl-none shadow rounded-2xl"
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          <div
            style={{
              paddingTop: " 0.75rem",
              paddingBottom: " 0.75rem",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <span className="block leading-tight">{text}</span>
          </div>
        </div>
      </div>

      {canAnswer && alphegaActive ? (
        <div className="ajax-div-question-two-positive">
          <div className="fixed bottom-0 z-10 w-full max-w-lg mx-auto -translate-x-1/2 left-1/2">
            <div className="px-4 bg-white border-t border-gray-200">
              <div className="flex items-center justify-content-around space-x-3 h-18">
                <button
                  type="button"
                  className="text-center px-3 py-3 text-sm font-medium leading-4 text-gray-700 transition-all duration-200 bg-white border border-gray-300 rounded-full shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
                  style={{ width: "100px" }}
                  onClick={() => onClickYes()}
                >
                  Yes
                </button>

                <button
                  type="button"
                  className="text-center px-3 py-3 text-sm font-medium leading-4 text-gray-700 transition-all duration-200 bg-white border border-gray-300 rounded-full shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
                  style={{ width: "100px" }}
                  onClick={() => onClickNo()}
                >
                  No
                </button>

                <button
                  type="button"
                  className="text-center px-3 py-3 text-sm font-medium leading-4 text-gray-700 transition-all duration-200 bg-white border border-gray-300 rounded-full shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
                  style={{ color: theme.palette.primary.main, width: "100px" }}
                  onClick={() => onClickSkip()}
                >
                  Skip
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    alphegaActive: state.frontWebRatingDataReducer.alphegaActive,
  };
};

export default connect(mapStateToProps)(SingleQuestion);
