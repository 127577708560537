import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import React from "react";
import { useStyles } from "./styles";

function LocationCard(props) {
  const { title, address1, distance, isFirstLocation = false } = props;
  const classes = useStyles(props);
  const theme = useTheme();

  return (
    <Box
      className={`${isFirstLocation ? classes.firstLocation : classes.card}`}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <LocationOnIcon
          style={{
            fontSize: "50px",
            borderRight: `2px solid ${theme.palette.primary.light}`,
          }}
        />
        <Box
          className="locationName"
          style={{
            textAlign: "start",
          }}
          ml={2}
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontWeight: 700,
              fontSize: 18,
            }}
          >
            {title}
          </Typography>
          <Typography
            style={{
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: 12,
            }}
          >
            {distance > 0 &&
              // eslint-disable-next-line no-useless-concat
              distance.toFixed(2).replace(/[.,]00$/, "") + " " + "miles"}
            {distance && address1 ? ", " : ""}
            {address1}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default LocationCard;
