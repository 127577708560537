import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button as Btn } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: "2px",
    textTransform: "capitalize",
    fontSize: "12px",
    color: "#475569",
    padding: "5px 12px 4px 12px",

    [theme.breakpoints.up("md")]: {
      padding: "5px 16px 4px 16px",
      fontSize: "13px",
      marginTop: "5px",
    },
  },
}));
function FormButton(props) {
  const classes = useStyles(props);
  const {
    leftIcon,
    rightIcon,
    onClick,
    title = "",
    variant = "contained",
    isDisable,
  } = props;

  return (
    <Btn
      variant={variant}
      className={classes.margin}
      style={{
        color: isDisable ? "" : "#475569",
        ...props.style,
      }}
      onClick={onClick}
    >
      {leftIcon && leftIcon}
      {title}
      {rightIcon && rightIcon}
    </Btn>
  );
}

export default FormButton;
