import React from "react";
import {
  addAnswer,
  addNote,
  addWriteIn,
} from "../../../../redux/survey/actions";
import { makeStyles } from "@material-ui/core/styles";
import { Box, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import { connect } from "react-redux";
import "rsuite/dist/styles/rsuite-default.css";
import "rsuite/dist/styles/rsuite-default.css";
//  style for the text field component
const useStyles = makeStyles((theme) => ({
  textfield: {
    width: "100%",
    borderBlockColor: theme.palette.primary.light,
  },
  input1: {
    borderColor: theme.palette.primary.light,
    color: "black",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));

function NumberType(props) {
  // Destructing  props for easy access to properties
  const { question, answeres, addAnswer_, isGreyOut, styles } = props;
  const theme = useTheme(); // Accessing the current theme
  const isMob = useMediaQuery(theme.breakpoints.down("sm")); // Check if device is mobile or not
  const classes = useStyles();

  // Function that handles onChange event of the TextField component
  const handleText = (text) => {
    // Regular expression to validate a number (with or without decimal)
    const numberRegex = /^[+-]?\d*\.?\d*$/;

    if (question?.allow_decimal && numberRegex.test(text.target.value)) {
      // Allow decimal point
      addAnswer_({
        id: question._id,
        value: text.target.value ? Number(text.target.value) : null,
        type: "text",
      });
    } else if (
      !question?.allow_decimal &&
      text.target.value &&
      Number.isInteger(parseFloat(text.target.value))
    ) {
      // Allow only integer if allow_decimal is false
      addAnswer_({
        id: question._id,
        value: text.target.value ? Number(text.target.value) : null,
        type: "text",
      });
    } else {
      // Add answer to Redux state
      addAnswer_({
        id: question._id,
        value: text.target.value ? Number(text.target.value) : null,
        type: "text",
      });
    }
  };

  let answer = answeres[question._id] ?? { value: "" }; // Extract the answer from answers by its ID

  return (
    <>
      <Box style={{ cursor: isGreyOut && "not-allowed" }}>
        <TextField
          disabled={isGreyOut}
          style={styles}
          className={classes.textfield}
          id="outlined-basic"
          value={answer?.value}
          onChange={handleText}
          size={isMob ? "small" : "medium"}
          label="Enter digit"
          placeholder="Enter digit"
          variant="outlined"
          InputProps={{ classes: { input: classes.input1 } }}
          InputLabelProps={{
            style: {
              color: "darkgray",
              fontSize: isMob && "14px",
            },
          }}
          type="number"
        />
      </Box>
    </>
  );
}
// Function that extracts the necessary state from the Redux store
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
  };
};
// Function that binds the dispatch to action creators
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswer_: (data) => dispatch(addAnswer(data)),
    addNote_: (data) => dispatch(addNote(data)),
    addWriteIn_: (data) => dispatch(addWriteIn(data)),
  };
};
// Connect Redux Store with this component
export default connect(mapStateToProps, mapDispatchToProps)(NumberType);
