import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosPostData } from "../../helpers/fetch_services";
import "./clientConnect.css";
import ClientBody from "./components/layout/ClientBody";
import ClientFooter from "./components/layout/ClientFooter";
import ClientHeader from "./components/layout/ClientHeader";
import { QUESTION_TYPES } from "./components/survey";

function ClientConnect({ survey, theme }) {
  const history = useHistory();
  const location = useLocation();
  const fromQuestion = location?.state?.fromQuestion === true;

  const [loading, setLoading] = useState(false);
  const [maxSteps, setMaxSteps] = useState(3);
  const [currentStep, setCurrentStep] = useState(fromQuestion ? 2 : 1);
  const [totalSurveySteps, setTotalSurveySteps] = useState(0);
  const [surveyErrors, setSurveyErrors] = useState({});

  const setSurveyError = (questionId, message) => {
    setSurveyErrors((prev) => ({ ...prev, [questionId]: { message } }));
  };

  const checkSurveyHasErrors = () => {
    const { answeres: answers } = survey;
    const { questions } = survey.survey_without_login;

    let hasErrors = false;

    questions
      .filter((question) => question.questionType !== QUESTION_TYPES.EMPLOYEES)
      .forEach((question) => {
        const { required, min_options: minOptions = null, max_options: maxOptions = null } = question;

        const answer = answers[question._id];

        if (required && !answer?.value) {
          setSurveyError(question._id, "This question is required!");
          hasErrors = true;
          return;
        }

        if ((minOptions || maxOptions) && (!answer?.value || !Array.isArray(answer?.value))) {
          setSurveyError(question._id, "This question is required!");
          hasErrors = true;
          return;
        }

        if (minOptions && minOptions > 0 && answer.value.length < minOptions) {
          setSurveyError(question._id, `Select atleast ${minOptions} options!`);
          hasErrors = true;
          return;
        }

        if (maxOptions && maxOptions > 0 && answer.value.length > maxOptions) {
          setSurveyError(question._id, `Maximum ${maxOptions} options allowed!`);
          hasErrors = true;
          return;
        }

        if (surveyErrors[question._id]) {
          setSurveyErrors(question._id, null);
        }
      });

    return hasErrors;
  };

  useEffect(() => {
    const currentSurvey = survey.survey_without_login;
    if (currentSurvey) {
      const questions = currentSurvey.questions
        .filter((question) => question.questionType !== "employees")
        .map((question, i) => {
          question.displayIndex = i + 1;
          return question;
        });
      const perPage = currentSurvey?.questions_per_page || questions.length;
      const totalSurveyPages = Math.ceil(questions.length / perPage);
      setTotalSurveySteps(totalSurveyPages);
    }

    if (currentSurvey?.leadershipUser) {
      setMaxSteps(4);
    }
  }, [survey]);

  const submitAnswers = async () => {
    setLoading(true); // Sets loading state to true at the start of the function

    // Destructuring survey data including answers and contact details
    const { answeres: answers, contactDetails, directorMessage } = survey;
    const { questions } = survey.survey_without_login;

    let filesArray = []; // Initialize an array to store file data

    // Loop through answers to find and process file type questions
    for (let item in answers) {
      const question = questions.find((question) => question._id === item);
      if ("isFile" in answers[item]) {
        answers[item].questionType = question.questionType;
        filesArray.push({ [item]: answers[item] });
      }
    }

    // Preparing metadata for the survey response
    const metaData = {
      survey_id: localStorage.getItem("survey") || "",
      location_id: localStorage.getItem("location") || "",
      response_user_id: localStorage.getItem("user_id") || "",
      tag_id: localStorage.getItem("tag_id") ? localStorage.getItem("tag_id") : localStorage.getItem("tag"),
    };

    // Handling audit and email data if available
    const audit = localStorage.getItem("audit") ?? null;
    const email = localStorage.getItem("email") ?? null;
    if (audit) {
      metaData.audit = audit;
      metaData.email = email;
    }

    // Consolidating response data from the contact details
    const responseData = {
      customer_name: contactDetails.name,
      customer_email: contactDetails.email,
      customer_phone: contactDetails.phone,
    };

    // Add creator_id if available in localstorage
    const ticketCreatorId = localStorage.getItem("creator_id") ?? null;

    // Creating a FormData object for sending the survey response
    const formData = new FormData();
    formData.append("metaData", JSON.stringify(metaData));
    formData.append("jsonData", JSON.stringify(answers));
    formData.append("responseData", JSON.stringify(responseData));
    formData.append("director_message", directorMessage);
    formData.append("ticketCreatorId", ticketCreatorId);

    // Adding files to the FormData if any exist
    if (filesArray.length) {
      filesArray.forEach((item) => {
        const key = Object.keys(item)[0];
        const { questionType } = item[key];
        let images = [];

        // Processing image files based on question type
        if (questionType === "file") {
          if (item[key]?.value?.length) {
            item[key].value.forEach((img) => images.push(img));
          }
          if (item[key]?.image?.length) {
            item[key].image.forEach((img) => images.push(img));
          }
        } else {
          images = item[key]?.image || [];
        }

        // Appending images to formData
        if (images.length) {
          images.forEach((image) => {
            formData.append(key, image);
          });
        }
      });
    }

    try {
      // Making an API call to submit the survey answers
      const result = await axiosPostData("survey/answerSurvey", formData);

      // On successful submission, redirect to thank you page
      if (result) {
        localStorage.removeItem("location");
        history.push({
          pathname: "/surveyThankYou",
          state: { surveyWithoutLogin: true },
        });
      }
    } catch (e) {
      // Handling errors during submission
      toast.error("Error saving your response!");
    }

    setLoading(false); // Sets loading state to false after function execution
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => {
      if (prev > 1) {
        return prev - 1;
      }
      return prev;
    });
  };

  const handleNext = async () => {
    if (currentStep === 3) {
      const isInvalid = checkSurveyHasErrors();
      if (isInvalid) {
        return;
      }
    }

    if (currentStep === maxSteps) {
      await submitAnswers();
      return;
    }

    if (currentStep < maxSteps) {
      setCurrentStep((prev) => prev + 1);
    }

    // history.push({
    //   pathname: "/survey/questions",
    //   state: { surveyWithoutLogin: true, clientConnect: true },
    // });
  };

  return (
    <LoadingOverlay active={loading} spinner>
      <div className="page-container" style={{ backgroundColor: theme.secondaryColor }}>
        <ClientHeader />

        <ClientBody
          theme={theme}
          survey={survey}
          currentStep={currentStep}
          surveyErrors={surveyErrors}
          onSurveyFinish={submitAnswers}
        />

        <ClientFooter
          maxSteps={maxSteps}
          totalSurveySteps={totalSurveySteps}
          currentStep={currentStep}
          onClickPrev={handlePrevious}
          onClickNext={handleNext}
        />
      </div>
    </LoadingOverlay>
  );
}

const mapStateToProps = (state) => {
  return {
    theme: state.survey.survey_theme,
    survey: state.survey,
  };
};

export default connect(mapStateToProps)(ClientConnect);
