import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  card: (props) => ({
    backgroundColor: theme.palette.secondary.main,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    alignItems: "center",
    color: theme.palette.text.primary,
    borderRadius: 6,
    border: `2px solid ${theme.palette.primary.light}`,
    borderLeft: `8px solid ${theme.palette.primary.main}`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "&:hover": {
      border: "none",
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.primary.light,
      borderLeft: `8px solid ${theme.palette.primary.main}`,
    },
    cursor: "pointer",
  }),
  cardFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  cardText: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 25,
    alignItems: "center",
    paddingLeft: "15px",
  },
}));
