import { Box, Checkbox, FormControlLabel, useTheme } from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles/checkBoxCardStyle";

const Icons = (props) => {
  const { iconName, size, color } = props;
  let icon = require(`react-icons/fa`);
  let Icon2 = icon[iconName];
  return (
    <div style={{ fontSize: size, color: color }}>
      <Icon2 />
    </div>
  );
};

function CheckBoxCard(props) {
  const classes = useStyles(props);
  const { checked, item, onClick, type } = props;
  const theme = useTheme();

  const isActive = (id) => {
    if (typeof checked === "string") {
      return checked === id;
    } else {
      return checked.includes(id);
    }
  };

  return (
    <Box
      className={classes.mainBox}
      style={{
        backgroundColor: isActive(item._id)
          ? theme.palette.primary.main
          : theme.palette.secondary.main,
      }}
      onClick={onClick}
    >
      {type === "sequence" && (
        <span
          className={`${classes.sequence} ${
            isActive(item._id) ? classes.labelActive : classes.label
          }`}
        >
          {isActive(item._id) && checked.indexOf(item._id) + 1}
        </span>
      )}
      <Box className={classes.logoBox}>
        {item.iconClass && (
          <Icons
            iconName={item.iconClass}
            size={60}
            color={
              isActive(item._id)
                ? theme.palette.secondary.main
                : theme.palette.primary.main
            }
          />
        )}
        <i
          className={`${item.iconClass} ${
            isActive(item._id) ? classes.labelActive : classes.label
          }`}
        />
      </Box>
      <Box className={classes.checkbox}>
        <FormControlLabel
          label={item.text}
          className={isActive(item._id) ? classes.labelActive : classes.label}
          control={
            <Checkbox
              checked={checked.includes(item._id)}
              classes={{
                root: classes.root,
                checked: classes.checked,
              }}
            />
          }
        ></FormControlLabel>
      </Box>
    </Box>
  );
}

export default CheckBoxCard;
