import React from "react";
import image__powerby_serve from '../assets/images/web_rating/powered-by-serve.png';

function Footer(props) {
  return (
    <footer className="secondary-footer hide-sm">
      <div className="logo">
        <img src={image__powerby_serve} alt="footer" />
      </div>
    </footer>
  );
}
export default Footer;
