import React from "react";
import "./customModal.css";

export default function CustomModal({ isOpen = false, children }) {
  return (
    <div className={`modal-background ${isOpen ? "" : "d-none"}`}>
      <div className="modal-box">{children}</div>
    </div>
  );
}
