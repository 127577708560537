import React from "react";
import { connect } from "react-redux";
import { useStyles } from "./styles";
import {
  setFrontratingAnsweres,
  resetFrontratingAnsweres,
} from "../../../../redux/frontWebRating/actions";
import { useIdleTimer } from "react-idle-timer";
import { saveDetails } from "../../../apiHelpers/saveDetails";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

function EmployeDetail(props) {
  const classes = useStyles();
  const history = useHistory();
  const [cookies, setCookie] = useCookies(["rating"]);

  const { webRatingData, webRatingAnsweres, setFrontratingAnsweres_ } = props;
  const skillsPositive = webRatingData?.skill_positive || [];
  const selectedEmployee = webRatingData?.employee.find(
    (item) => item.id === webRatingAnsweres.employee_id
  );

  const handleClick = (id) => {
    if (webRatingAnsweres.skill_id.includes(id)) {
      const eliminatedArray = webRatingAnsweres.skill_id.filter(
        (item) => item !== id
      );
      setFrontratingAnsweres_({ key: "skill_id", value: eliminatedArray });
    } else {
      setFrontratingAnsweres_({
        key: "skill_id",
        value: [...webRatingAnsweres.skill_id, id],
      });
    }
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 20,
    onIdle: async () => {
      getRemainingTime();

      let ratingCookie = cookies.rating;
      if (
        ratingCookie?.location !== props.webRatingAnsweres.location_id ||
        localStorage.getItem("is_frequent_rating")
      ) {
        const response = await saveDetails({
          ...props.webRatingAnsweres,
          page: 2,
          dropout_page: "p2",
        });
        if (response && response?.data?.metaData) {
          setCookie(
            "rating",
            {
              rating: "submited",
              location: response.data.metaData.location_id,
            },
            { path: "/", maxAge: 86400 }
          );
          props.resetFrontratingAnsweres_();
          history.push("/thankYou");
        } else {
          toast.error(response?.message);
          history.push("/frontRating");
        }
      } else {
        toast.error("You have already submitted rating");
      }
    },

    debounce: 500,
  });

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headingBox}>
          <h1 className={classes.heading}>
            {selectedEmployee
              ? `What made ${selectedEmployee.name} stand out to you ?`
              : "What made the team stand out to you ?"}
          </h1>
          <p className={classes.subHeading}>Click as many as you want</p>
        </div>
        <div className={classes.employeContainer}>
          {selectedEmployee ? (
            <div className={classes.imageBox}>
              <img
                objectFit="contain"
                src={selectedEmployee?.image}
                style={{
                  width: "187px",
                  height: "187px",
                  borderRadius: "5%",

                  objectFit: "cover ",
                }}
                alt="employee"
              />
              <div className={classes.nameBox}>
                <p className={classes.nameText}>{selectedEmployee?.name}</p>
              </div>
            </div>
          ) : null}
          <div className={classes.tagsContainer}>
            {skillsPositive.map((skill) => (
              <p
                className={
                  webRatingAnsweres.skill_id.includes(skill._id)
                    ? classes.tagTextActive
                    : classes.tagText
                }
                onClick={() => {
                  handleClick(skill._id);
                }}
              >
                {skill.name}
              </p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    webRatingData: state.frontWebRatingDataReducer.webRatingData,
    webRatingAnsweres: state.frontWebRatingDataReducer.webRatingAnsweres,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFrontratingAnsweres_: (data) => dispatch(setFrontratingAnsweres(data)),
    resetFrontratingAnsweres_: () => dispatch(resetFrontratingAnsweres()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeDetail);
