import React from "react";

export default function SingleAnswer({ answer }) {
  return (
    <>
      {answer ? (
        <div className="flex flex-row-reverse items-end justify-start pl-20 transition-all duration-200 mt-4">
          <div className="text-black bg-white rounded-br-none shadow rounded-2xl ">
            <div className="px-4 py-3">{answer}</div>
          </div>
        </div>
      ) : null}
    </>
  );
}
