import React from "react";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

const RadioOptions = ({ options, onClick, answer, error }) => {
  const isActive = (optionId) => {
    return answer?.value === optionId;
  };

  return (
    <div className="d-flex flex-wrap align-items-center justify-content-center">
      {options?.length
        ? options.map((option) => (
            <div key={option._id} className="mr-2 mb-1">
              <button
                onClick={() => onClick(option._id, option.finish_survey)}
                className={`option-button radio-button d-flex align-items-center ${
                  isActive(option._id) ? "selected" : ""
                }`}
              >
                <div>
                  {isActive(option._id) ? (
                    <RadioButtonCheckedIcon color="action" />
                  ) : (
                    <RadioButtonUncheckedIcon color="action" />
                  )}
                </div>

                <div className="px-2">{option.text}</div>
              </button>
            </div>
          ))
        : null}

      {error ? <div className="mt-2 small font-weight-bold text-danger">{error.message}</div> : null}
    </div>
  );
};

export default RadioOptions;
