import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  card: (props) => ({
    backgroundColor: theme.palette.secondary.main,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary,
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: 6,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.highlight.main,
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      border: "none",
      borderRadius: 6,
      filter: `drop-shadow(0px 4px 4px ${theme.palette.primary.dark})`,
    },
  }),
  firstLocation: (props) => ({
    backgroundColor: theme.palette.secondary.main,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary,
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: 6,
  }),
}));
